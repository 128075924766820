<template>
 <div class="assetslist">
   <div class="BSSummary_topsection">
   
    <div class="summaryline">
          <div class="divsummaryleft"> 
                 <p class="p_sumleft" > Your Age : </p>   
          </div>
          <div class="divlabelammount"> 
              <p class="p_amount" > {{Age}} years </p>   
          </div>
    </div>
  
      <div class="summaryline">
          <div class="divsummaryleft"> 
                <p class="p_sumleft" > Projected Retirement Age :   </p>
         </div>
          <div class="divlabelammount"> 
              <p class="p_amount" > {{RetireAge}} years</p>   
          </div>
    </div>

      <div class="summaryline" >
          <div class="divsummaryleft"> 
                 <p class="p_sumleft" > Your Life expectancy :   </p>
          </div>
          <div class="divlabelammount"> 
              <p class="p_amount" > {{(Age+Lifeexpect).toFixed(2)}} years</p>   
          </div>
    </div>

    <div class="summaryline" >
          <div class="divsummaryleft"> 
                 <p class="p_sumleft" > Duration of retirement period :   </p>
          </div>
          <div class="divlabelammount"> 
              <p class="p_amount" > {{(Age + Lifeexpect - RetireAge).toFixed(2)}} years</p>   
          </div>
    </div>


         
   </div>

 </div>

</template>

<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'
import axios from "axios";

const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets');
 const auth = createNamespacedHelpers('auth');

export default {
 name: 'Summaryuserinfo',
 
 props:{Age:{type:Number, default: 10}, RetireAge:{type:Number, default: 10},Lifeexpect:{type:Number, default: 10},DurationRetire:{type:Number, default: 10},},

   data() { 
      return {
        //   lifeexpectance:'', tempresults:''
           
           }
   },
     
     computed: {

      /*
      ...auth.mapState(['regionalpreferences', 'session','user','cors_host']),
      ...auth.mapGetters(['getAge','Getresultproj']),
      ...auth.mapMutations(['setresultproj']),
  */
  ...auth.mapState(['webaddress1']),
     },

     methods: {
   

        
       async lifeexpectancy() {
            
        
            const mdata = {"country": this.user.Country, "gender": this.user.Gender, "age": this.getAge};
            console.log('mdata');   
            console.log(mdata);
            console.log(this.cors_host);

            try {
              // await   axios.post('http://localhost:8080/include/lifeexpectancy.inc.php', mdata).then((res) => {
                //  await   axios.post('http://localhost:'+this.cors_host+'/one/include/lifeexpectancy.inc.php', mdata).then((res) => {
                    await   axios.post(this.webaddress1+'include/lifeexpectancy.inc.php', mdata).then((res) => {

                  this.lifeexpectance=res.data.Ex;
                // this.Proba=res.data.Surv;
                console.log('this.lifeexpectance');
                console.log(this.lifeexpectance);

                })
                      
                this.showError = false;
              

                
            } catch (error) {
              this.showError = true
            }
            return "completed";
        },



       

     },


     created(){

   console.log("age");
   console.log(this.Age);
   console.log("Lifeexpect");
   console.log(this.Lifeexpect);
   console.log("AgeRetire");
   console.log(this.RetireAge);
   

 




     }
     
     
}

 
</script>

<style scoped>
div .assetslist {
display:flex;
flex-direction: column;
width: 420px;
margin:5px auto;
background: white;
text-align: left;
padding: 1px;
border-radius: 3px;
border: 1px solid rgb(188, 188, 192);
}


div .BSSummary_topsection {
display:flex;
/* justify-content: flex-end; 
*/
flex-direction: column;
padding: 10px;;
border: 1px solid rgb(243, 237, 237);
}

div .summaryline {
display:flex;
justify-content: space-between;
align-items: flex-start;
width:100%;

margin:2px 0 2px 0 ;
    background-color: rgba(198, 194, 239, 0.111);
padding: 0px;
border-radius: 3px;
border-bottom: 1px solid rgb(214, 214, 219);
}



div .chartdiv {
display:flex;
justify-content: center;
align-items:center;


margin:10px ;


}

div .divsummaryleft {
display:flex;
}



.p_sumleft{
font-size: 1em; colour: green; font-weight: 400; flex:1; margin:10px 0 2px 10px;
}

.p_amount{ 
  font-size: 0.9em; colour: rgb(73, 78, 73); font-weight: 500; flex:1; margin:10px 10px 2px 10px; text-align: right;
 }



div .divlabelammount {
  display:flex;
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;

  height: 45px;
  max-width: 300px;
  border: none;
  align-self: right;

}





p{
  margin: 10px;
}





</style>