<template>

  <div class="lifeinsurance">
  

  


  <title> Life insurance premium  </title>



<div class="lifeinsurancecontent">
     

<div class="lifeex-form-form">    

     <div class="divtitle">
        <p class="title"> Life insurance premium </p>
      </div>

  <div class="lifeex-form-form1">   

  <hr class="new1"> 

           <div class="newclass"> 
                        <label class="form-label2" for="customRange3">Amount to insure : </label>  
                                 <div class="child-params1"> 

                                  <input class="form-control form-input3" type="text"  v-model="currencyformat.left" disabled > 
                                           <input class="form-control form-input2" type="text"  v-model="amount" @blur="inputblurr"  @focus="inputfocus" v-on:keyup.enter="inputblurr, $event.target.blur()" >                     
                                       <input class="form-control form-input3" type="text"  v-model="currencyformat.right" disabled > 
                   
                                    </div>

                                <div class="div1input">
                     <!----              
                                <Inputnumber   :value="amount"  :symbol_left="currformat().left" :symbol_right="currformat().right" @returnevent="amount=parseFloat($event)" @click="currformat()"  :digits=0   />                          
 
     -->
 <!----
                            <comma-formatted-number 
                              @input="handleInput()" 
                              class="input amount-input" 
                              :value="formattedValue" 
                              :formatOptions="{thousand: ',', precision: 2}" 
                            />
                  -->                            
                          
                              </div> 
            </div> 



  <hr class="new1"> 
        
    <div class="newclass"> 
       <label class="form-label2" for="customRange3">Duration : </label>  
          <div class="container-params">
                            <div class="child-params1"> 
                                     <div class="child-params1"> 
                                           <input class="form-control form-input2" type="number"   v-model="nbyearsprotection" min="1" max="60">   
                                         <input class="form-control form-input3" type="text"  placeholder="years" disabled > 
                                    </div>                            
                            </div>
                                       <div class="child-params2">    
                                        <input type="range" class="customrange" min="1" max="80" step="1" v-model="nbyearsprotection" > 
                                </div>       
           </div> 
      <hr class="new1"> 
      </div> 



     <div class="newclass"> 
            <label class="form-label2" for="customRange3">Country : </label>  
                <select class="lecountryselect" name="country" v-model="country"  id="l_e_country">
                    <option selected="" disabled="" value="">--Select Country--</option>
                    <option value="US">USA</option>
                    <option value="GB">UK</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>   
                </select>
                
                <hr class="new1"> 
       </div>


    <div class="radiobutton">
      <div class="radiobutton2">
      <label for="m">Male</label>
       <div class="radiobuttondiv">  
         <input type="radio" name="gender" value="M" checked="" class="radiogender"  v-model="gender" >   
       </div>
      </div>
      <div class="radiobutton2">
      <label for="f">Female</label>
      <div class="radiobuttondiv"> 
      <input type="radio" name="gender" value="F" class="radiogender"  v-model="gender"  >  
      </div>
      </div>
   <hr class="new1"> 
    </div>



   <div class="radiobutton">
      <div class="radiobutton2">
      <label for="s">Non-Smoker</label>
       <div class="radiobuttondiv">  
         <input type="radio" name="smoker" value="NS" checked="" class="radiogender"  v-model="smoker" >   
       </div>
      </div>
      <div class="radiobutton2">
      <label for="ns">Smoker</label>
      <div class="radiobuttondiv"> 
      <input type="radio" name="smoker" value="S" class="radiogender"  v-model="smoker"  >  
      </div>
      </div>
   <hr class="new1"> 
    </div>




      <div class="newclass"> 
       <label class="form-label2" for="customRange3">Age : </label>  
          <div class="container-params">
                            <div class="child-params1"> 
                                     <div class="child-params1"> 
                                           <input class="form-control form-input2" type="number"   v-model="age" min="1" max="80">   
                                         <input class="form-control form-input3" type="text"  placeholder="years" disabled > 
                                    </div>                            
                            </div>
                                       <div class="child-params2">    
                                        <input type="range" class="customrange" min="1" max="80" step="1" v-model="age" > 
                                </div>       
           </div> 
      <hr class="new1"> 
      </div> 


       <div class="newclass"> 
               <label class="form-label2" for="customRange3">Premium payment frequency : : </label>  
                <select class="lecountryselect" name="country" v-model="premiumfreq"  >
                    <option selected="" disabled="" value="Monthly">--Select premium frequency--</option>
                    <option value="Single">Single</option>
                    <option value="Annual">Annual</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Monthly">Monthly</option>   
                </select>
                
                <hr class="new1"> 
       </div>



<!--
     <input type="text" name="age"  value="20" placeholder="20...", id='l_e_age'>


<button  type="submit" name="submit" onclick="Lifeinsurance();"> calculate </button>
-->
    
    </div>   


    
 

  
  
  <div class="container-params">
      <button class="button" name="submit2" @click="submit"> Calculate</button>    
  </div>

  <div class="contentresult">
                 <hr class="new1"> 
               <p style=" text-align:left; margin:0px; padding:0px;"> 
                 
                   Your life insurance premium is :
              </p>
                 <div class="divresultyears">
                      <b> {{currencyformat.left + ' '+ insurancepremium + ' ' + currencyformat.right}} </b> 
                 </div>

   
    </div>

</div>



 

</div>





  </div>
</template>

<script>


import {createNamespacedHelpers} from 'vuex'


import axios from "axios";
import Inputnumber from '@/components/Portfolio/components/Inputnumber.vue'
import CommaFormattedNumber from '@/components/Portfolio/components/commaformatednumber.vue'


const auth = createNamespacedHelpers('auth')

// src/components/Portfolio/components/Inputnumber.vue
 // src/views/Lifeinsurance.vue





//const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('auth');
const parameters = createNamespacedHelpers('parameters');

export default {
  name: "Lifeinsurance",
  components: {Inputnumber, CommaFormattedNumber},
  data() {
    return {
      age:20,
      country:"US",
      gender:"M",
      nbyearsprotection:10,
      premiumfreq:"Quarterly",
      amount:"1,000",
      currency:"EUR",
      currparam:  {code:'USD', logo: '$' , designation:'United States Dollar', position: 0  }, 
      formattedValue: "100",

      form: {
        username: "",
        password: "",
      },
      showError: false,

      insurancepremium:0

    };
  },


 computed: {
  

     ...auth.mapState(['regionalpreferences', 'session','cors_host','webaddress1']),
     ...auth.mapGetters(['currencyformat']),
     ...parameters.mapState(['listdefaultcountryparameters']),
       ...parameters.mapGetters(['filtercurr']),

  
     
   },



methods: {
  

  numberWithCommas(x) {
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },

        Commastonumber(x) {
           const parts = x.replace(/,/g, '');  
         
          return parseFloat(parts).toFixed(2);
          
        },
       

       inputblurr(){
            this.amount=this.numberWithCommas(this.amount);
       },
         inputfocus(){
            this.amount=this.Commastonumber(this.amount);
       },


       /*
      currformat(){
      // return this.defcurrency(this.currency);
      //   const cfmt= this.filtercurr(this.currency);
      // const cfmt= this.defcurrency();
         const currlogs={};
         if (cfmt.position==0) {currlogs.left=this.currencyformat.left; currlogs.right=this.currencyformat.right } else {currlogs.left=""; currlogs.right=cfmt.logo }
      //  console.log(cfmt.logo);
      //  console.log(cfmt.code);
       return currlogs;
       },
       */

/*
       handleInput(e) {
               this.formattedValue = e;
              },
              */

async submit() {
       
 
      const mdata = {"country": this.country, "gender": this.gender, "age": this.age,
       "smoker": this.smoker, "duration": this.nbyearsprotection, "premfreq": this.premiumfreq, "currency": this.currency};
      console.log(mdata);
      try {
        //  await   axios.post('http://localhost:'+this.cors_host+'/one/include/lifeins.inc.php', mdata).then((res) => {
            await   axios.post(this.webaddress1+'include/lifeins.inc.php', mdata).then((res) => {
        
            
         
            console.log(res.data);
         //    console.log(this.amount);
            this.insurancepremium=this.numberWithCommas((res.data* this.Commastonumber(this.amount)).toFixed(2));
          //  console.log(this.insurancepremium);

          })
                 
          this.showError = false;
          
      } catch (error) {
        this.showError = true
      }
      
   },



     


    },
created() {  

       
      this.country= this.regionalpreferences.country;
      this.currency= this.regionalpreferences.currency;
      //this.currparam=this.defcurrency(this.currency);
  //    this.currformat();
    //  console.log("Currency test");
     //console.log(this.defcurrency("EUR"));
  },
 
};
</script>

<style scoped>


.lifeinsurance{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;

justify-content: center;
justify-items: center;
padding-top: 40px;

}


.title{
     margin-left: 2px;
     font-size: 18px;
     font-weight: bold;
     color: #285fb3;         
}



.lifeinsurancecontent{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;
width:250px;
justify-content: center;
justify-items: center;
}

.lifeex-form-form{
display:flex;
flex-direction: column;
width: 100%;
margin:0px;
padding: 0px;;
}

.lifeex-form-form1{
    display: flex;
    flex-direction: column;
}



.lecountryselect{
 margin:10px 0 10px 0;
 
}


.radiobutton {
    display: flex ;
    flex-direction: column;
}

.radiobutton2{
    display: flex; 
     justify-items: flex-start;
  flex-direction: row;
  min-height: 100%;
  justify-content: space-between;
  
}

 .radiobutton2 label {
      display:flex;
      flex:5;
      align-items: flex-end;
      justify-content: flex-start;
      align-content: flex-start;
      align-self: flex-start;
      text-align: start;
   }

  .radiobuttondiv {
   display: flex;
   flex: 1;

   margin: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 100%;


   } 

  .radiogender {
    
   margin:10px;
   
   }

.newclass{
    display: flex;
    flex-direction: column;
}

.form-label2{
    align-self: flex-start;
      text-align: left;
}

.form-control.form-input2{
    flex:3;
    text-align: center;
}
.form-control.form-input3{
    flex:1;
    text-align: center;
    border:none;

}


.form-control.form-input3:disabled{
    background-color: inherit;

}



button  {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 20px 00px;
  width: 100%;
}
button:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}


.contentresult{
    display: flex;
    flex-direction: column;

}

.tablelifeex {
  width:100%;
  margin-bottom: 50px;
}

 .tablelifeex   tbody {
      width:100%;
         box-shadow: 5px 5px 5px rgb(178, 179, 180);
    }




  .tablelifeex td{
  padding: 5px 10px 5px 10px; 
  font-size:15px;
  border: solid 1px rgb(194, 192, 192);
 }
 
 .tablelifeex th{
  padding: 10px; 
  font-size:15px;
  border: solid 2px rgb(194, 192, 192);
 }

 .divresultyears{
     border: solid 1px rgb(194, 192, 192);
     margin: 10px 0  20px 0 ;
     padding: 5px 0 5px 0;
    box-shadow: 5px 5px 5px rgb(178, 179, 180);

 }
 

 .child-params1{
   display: flex;
 }


#error {
  color: red;
}



</style>