<template>
 <div id="topnav">
      <div id="nav1">
            <div id="nav1left">
             <h1 style="color:green; margin: 2px 30px 10px 20px"> Wealth & Retirement </h1> 
            </div>
            <div id="nav1right">
               <router-link :to="{ name: 'Regionalprefs' }">
              {{regionalpreferences.country + ' / ' + regionalpreferences.language +' / ' +regionalpreferences.currency}}
              </router-link> 
            </div> 
      </div>



 
  <div id="nav2">
    <router-link :to="{ name: 'Home' }">Home</router-link> |
    <router-link :to="{ name: 'Posts' }">Life Insurance</router-link> |
    <router-link :to="{ name: 'Portfolioview' }">Portfolio</router-link> |
    <router-link :to="{ name: 'BSprojection' }">Projection</router-link> |

    <span v-if="isLoggedIn">
      <a @click="logout">Logout</a>
    </span>
    <span v-else>
      <router-link to="/register">Register</router-link> |
      <router-link to="/login">Login</router-link>
    </span>
  </div>
  <router-view></router-view>
  <!-- <router-view />  -->

  </div>
</template>


<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'



const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('auth');


export default {
  name: "NavBar",
  computed: {
      ...mapState(['regionalpreferences']),

     // ...mapState({   regionalpreferences: state=>state.regionalpreferences  }),

    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },
     
   

  },
  methods: {
    async logout() {
      await this.$store.dispatch("LogOut");
      this.$router.push("/login");
    },
  },
};
</script>

<style>

#topnav {
  display: flex;
  flex-direction: column;
}

#nav1 {
  display: flex;
  justify-content: space-between;

}

#nav1left {
  display: flex;
  justify-content: flex-start;
  margin: 1px 0px 0px 20px;
  
}

#nav1right {
  display: flex;
  Margin: 2px 30px 1px 0px;
  max-width: 100px;
  align-self: right;
  justify-self: right;
}

#nav1right {
  font-size: 12px;
    font-weight: 400;
    font-family: roboto,Arial,Helvetica,sans-serif;
}



#nav2 {
  padding: 5px 10px 30px 30px;
}
#nav2 a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration: none;
}
a:hover {
  cursor: pointer;
  

}



#nav2 a.router-link-exact-active {
  color: #42b983;
  text-decoration:underline;
}
</style>