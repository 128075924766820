
function defaultstate ()  { return  {
    assetslist1: [
        {
            ID:1,
            BS:'A', 
            Type:"Cash",
            Designation:"Bank account 1",
            Value:100,
            IR:0.02,
            CG:0.02,
            Duration:10,
            Nominal:1,
            Issue_date:"2010-10-10",

        }, 
        {
            ID:2,
            BS:'A', 
            Type:"Bond",
            Designation: "Bond company A",
            Value:100,
            IR:0.02,
            CG:0.02,
            Duration:10,
            Nominal:1,
            Issue_date:"2010-09-09",
     },
     {
        ID:3,
        BS:'A', 
        Type:"Cash",
        Designation: "Bank account 2",
        Value:100,
        IR:0.02,
        CG:0.02,
        Duration:10,
        Nominal:1,
        Issue_date:"2010-09-09",
      },
     {
        ID:4,
            BS:'A', 
            Type:"Equity",
            Designation: "Share company 1",
            Value:100,
            IR:0.02,
            CG:0.02,
            Duration:10,
            Nominal:1,
            Issue_date:null,
      },
      {
        ID:10,
            BS:'A', 
            Type:"Fund",
            Designation: "Fund 1",
            Value:100,
            IR:0.02,
            CG:0.02,
            Duration:10,
            Nominal:1,
            Issue_date:null,
      },
     {
        ID:5,
            BS:'A', 
            Type:"Property",
            Designation: "Property 1",
            Value:300,
            IR:0.02,
            CG:0.02,
            Duration:10,
            Nominal:1,
            Issue_date:null,
      },
      {
        ID:6,
        BS:'L', 
        Type:"Mortgage",
        Designation: "Mortgage for property H1",
        Value:100,
        IR:0.02,
        CG:0.02,
        Duration:10,
        Nominal:100,
        Issue_date:null,
    }, 
    {
        ID:7,
        BS:'L', 
        Type:"Debt",
        Designation: "Debt to company H1",
        Value:100,
        IR:0.02,
        CG:0.02,
        Duration:10,
        Nominal:100,
        Issue_date:null,
 },
   {
        ID:7,
        BS:'L', 
        Type:"Debt",
        Designation: "Debt to company H1",
        Value:100,
        IR:0.02,
        CG:0.02,
        Duration:10,
        Nominal:100,
        Issue_date:null,
 },
 {
    ID:8,
        BS:'L', 
        Type:"Mortgage",
        Designation: "Mortgage for House H2",
        Value:100,
        IR:0.02,
        CG:0.02,
        Duration:10,
        Nominal:100,
        Issue_date:null,
  },
 {
        ID:9,
        BS:'L', 
        Type:"Mortgage",
        Designation: "Mortgage House H3",
        Value:100,
        IR:0.02,
        CG:0.02,
        Duration:10,
        Nominal:100,
        Issue_date:null,
  }   
    ],


    assetslist2: [
      {
          ID:1,
          BS:'A', 
          Type:"Cash",
          Designation:"Bank account 1",
          Value:100,
          IR:0.02,
          CG:0.02,
          Duration:10,
          Nominal:1,
          Issue_date:"2010-10-10",

      },
      {
        ID:2,
        BS:'L', 
        Type:"Mortgage",
        Designation: "Mortgage for property H1",
        Value:100,
        IR:0.02,
        CG:0.02,
        Duration:10,
        Nominal:100,
        Issue_date:null,
    } ],




    D_assetsitems: [
        {
            id:1,
            type:"Cash",
            param1: ' param One',
            param2: ' param Two',
            value:100
        }, 
        {
            id:2,
            type:"bond",
            param1: ' param One',
            param2: ' param Two',
            param3: ' param Three',
            value:100
     }   
    ],
    liablist: [
        {
            ID:5,
            BS:'A', 
            Type:"Mortgage",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
        }, 
        {
            ID:6,
            BS:'L', 
            Type:"Debt",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
     },
     {
        ID:7,
            BS:'L', 
         Type:"Mortgage",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
      }
      ,
     {
            ID:8,
            BS:'L', 
            Type:"Mortgage",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
      }
     
    ],


    Defaultassets: {
     Cash: {     
          ID:{value:0 , visible: true, designation: 'ID' } ,
          BS:{value:'A' , visible: true, designation: 'BS' }, 
          Type:{value:"Cash" , visible: true, designation: 'Type'} ,
          Designation:{value:"BBK1" , visible: true, designation: 'Designation'} ,
          Value:{value: 100, visible: true, designation: 'Value'} ,
          IR:{value:0.02 , visible: true,designation: 'Interest rate'} ,
          CG:{value:  0.02, visible: false, designation: 'Capital growth rate'},
          Duration:{value:10 , visible: false, designation: 'Duration'} ,
          Nominal:{value:1 , visible: false, designation: 'Nominal'} ,
          Issue_date:{value: "2022-12-31", visible: false, designation: 'Issue date'} ,

      }, 
     Bond: {
          ID:{value:0 , visible: true, designation: 'ID' } ,
          BS:{value: 'A' , visible: true, designation: 'BS'},
          Type:{value:"Bond" , visible: true, designation: 'Type'} ,
          Designation: {value: "BK2", visible: true, designation: 'Designation'} ,
          Value:{value:100 , visible: true, designation: 'Value'} ,
          IR:{value: 0.02, visible: true,designation: 'Interest rate'} ,
          CG:{value: 0.02, visible: false, designation: 'Capital growth rate'} ,
          Duration:{value: 10 , visible: true, designation: 'Duration'},
          Nominal:{value: 100 , visible: true, designation: 'Nominal'},
          Issue_date:{value: "2022-12-31", visible: true, designation: 'Issue date'} ,
   },
   Equity:{
          ID:{value: 0 , visible: true, designation: 'ID' } ,
          BS:{value:'A' , visible: true, designation: 'BS'} ,
          Type:{value:"Equity" , visible: true, designation: 'Type'} ,
          Designation: {value: "Eq1", visible: true, designation: 'Designation'} ,
          Value:{value:100 , visible: true, designation: 'Value'} ,
          IR:{value: 0.02, visible: true, designation: 'Dividend Yield'} ,
          CG:{value:  0.02, visible: true, designation: 'Capital growth rate'},
          Duration:{value:10 , visible: false, designation: 'Duration'} ,
          Nominal:{value:1 , visible: false, designation: 'Nominal'} ,
          Issue_date:{value: "2022-12-31", visible: false, designation: 'Issue date'} ,
    },
   Property: {
          ID:{value:0 , visible: true, designation: 'ID' } ,
          BS:{value: 'A' , visible: true, designation: 'BS'},
          Type:{value:"Property" , visible: true, designation: 'Type'} ,
          Designation: {value:"Property 1" , visible: true, designation: 'Designation'} ,
          Value:{value:300 , visible: true, designation: 'Value'} ,
          IR:{value:0.02 , visible: true, designation: 'Rental income rate'} ,
          CG:{value:0.02 , visible: true, designation: 'Price growth rate'} ,
          Duration:{value: 1, visible: false, designation: 'Duration'} ,
          Nominal:{value:1 , visible: false, designation: 'Nominal'} ,
          Issue_date:{value:"2022-12-31" , visible: false, designation: 'Issue date'} ,
    },
    Fund:{
         ID:{value:0 , visible: true, designation: 'ID' } ,
          BS:{value:'A' , visible: true, designation: 'BS'} ,
          Type:{value:"Fund" , visible: true, designation: 'Type'} ,
          Designation: {value: "Eq1", visible: true, designation: 'Designation'} ,
          Value:{value:100 , visible: true, designation: 'Value'} ,
          IR:{value: 0.02, visible: true, designation: 'Dividend Yield'} ,
          CG:{value:  0.02, visible: true, designation: 'Capital growth rate'},
          Duration:{value:10 , visible: false, designation: 'Duration'} ,
          Nominal:{value:1 , visible: false, designation: 'Nominal'} ,
          Issue_date:{value: "2022-12-31", visible: false, designation: 'Issue date'} ,
    },
   Mortgage: {
      ID:{value:0 , visible: true, designation: 'ID' } ,
      BS:{value: 'L', visible: true, designation: 'BS'} ,
      Type:{value:"Mortgage" , visible: true, designation: 'Type'} ,
      Designation: {value:"Mortgage H1" , visible: true, designation: 'Designation'} ,
      Value:{value:100 , visible: true, designation: 'Value'} ,
      IR:{value: 0.02, visible: true, designation: 'Interest rate'} ,
      CG:{value:0.02 , visible: false, designation: 'Capital growth rate'} ,
      Duration:{value: 10, visible: true, designation: 'Duration'} ,
      Nominal:{value: 100 , visible: true, designation: 'Nominal'},
      Issue_date:{value:"2010-09-09" , visible: true, designation: 'Issue date'} ,
  }, 
  Debt:{
      ID:{value:0 , visible: true, designation: 'ID' } ,
      BS:{value:'L' , visible: true, designation: 'BS'} ,
      Type:{value:"Debt" , visible: true, designation: 'Type'} ,
      Designation: {value: "Debt H1" , visible: true, designation: 'Designation'},
      Value:{value:100 , visible: true, designation: 'Value'} ,
      IR:{value: 0.02 , visible: true , designation: 'Interest rate'},
      CG:{value:0.02 , visible: false, designation: 'Capital growth rate'} ,
      Duration:{value: 10, visible: true, designation: 'Duration'} ,
      Nominal:{value: 100, visible: true, designation: 'Nominal'} ,
      Issue_date:{value:"2010-09-09" , visible: true, designation: 'Issue date'} ,
}  
},




    D_assetsitems: [
        {
            id:1,
            type:"Cash",
            param1: ' param One',
            param2: ' param Two',
            value:100
        }, 
        {
            id:2,
            type:"bond",
            param1: ' param One',
            param2: ' param Two',
            param3: ' param Three',
            value:100
     }   
    ],
    liablist: [
        {
            ID:5,
            BS:'A', 
            Type:"Mortgage",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
        }, 
        {
            ID:6,
            BS:'L', 
            Type:"Debt",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
     },
     {
        ID:7,
            BS:'L', 
         Type:"Mortgage",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
      }
      ,
     {
            ID:8,
            BS:'L', 
            Type:"Mortgage",
            Designation: null,
            Value:100,
            IR:0.02,
            Duration:10,
            Nominal:null,
            Issue_date:null,
      }
     
    ],
   // assetcat: ["Cash","Equity","Bond","Property","Fund"],
   assetcat: {Cash:false, Equity:false, Bond:false,Property:false,Fund:false},
   liabcat: {Mortgage:false, Debt:false, Loan:false},
   paramformat: {ID:1, BS:"T", Type:"T", Designation: "T", Value:2, IR:2, CG:2,Duration:0, Nominal:2, Issue_date:"T"},
 




 }
};


import axios from 'axios';



import auth from './auth'



const state =  defaultstate();

const API_URL = 'http://wwww.riskmet.com/';

//const state = null;

//const hostid= 8082;
 
 const getters = {
 //    allTodos: function (state) { return state.todos }
        allassets:  (state) => { return state.assets }, 
        assetcategories:  (state) => { return state.assetcat },   
        liabcategories:  (state) => { return state.liabcat },

        webaddress1 : (state,getters, rootState,rootGetters) =>  rootState.auth.webaddress1,
     
       // paramformats:  (state) => { return state.paramformat },

       totalAL: (state) => {
        //const tempobj= JSON.parse(JSON.stringify(state.assetslist1));

        console.log("state.assetslist");
       // const tempobj=  [];
       // Object.assign(tempobj, Object.values(state.assetslist1));
       // Object.values(state.assetslist1);
      // var tempobj=Object.values(state.assetslist1);
       console.log(state.assetslist1);

          var tempo =  JSON.parse(JSON.stringify(state.assetslist1));

          const tempobj = [];
              Object.keys(tempo).forEach(key => tempobj.push(
                tempo[key]
              ));

      // var tempobj=state

      //Object.assign({}, state.assetslist1);

       // var tempobj=Object.values(state.assetslist1);
        
        console.log("tempobj");
        console.log(tempobj);
        console.log("tempobj[1].BS");
        console.log(tempobj[1]["BS"]);
        console.log(tempobj[1]["Value"]);
        var totalassets = tempobj.filter(c => c["BS"] === "A").reduce((acc, val) => acc + parseFloat(val["Value"]), 0) 
          ;

          var totalliabilities= tempobj.filter(c => c.BS === "L").reduce((acc, val) => acc + parseFloat(val.Value), 0) 
          ;

         
          console.log(" totalassets");
          console.log( totalassets);
          console.log( totalliabilities);

          return {Assets:parseFloat(totalassets).toFixed(2),Liabilities:parseFloat(totalliabilities).toFixed(2)}

        },

                /*  
                
                totalAL: (state) =>{
                  var totalassets: state => {
                      return state.assetslist.reduce((acc, val) => acc + val.value, 0) 
                    };

                    var totalliabilities: state => {
                      return state.liablist.reduce((acc, val) => acc + val.value, 0) 
                    };

                    return {assets:totalassets,liabilities:totalliabilities}

                  },
              
                totalassets: (state) => {
                  return state.assetslist.reduce(); 
              } , */
            /* 
              totalassets: state => {
                  return state.assetslist.reduce((acc, val) => acc + val.value, 0) 
                },
          src/store/modules/assets.js    
                totalassets: state => { state.assetslist.reduce((type, pot) => {
                  type[pot.type] = (type[pot.type] || 0) + pot.value || 1;
                  return type;
              }, {})}
          */

    totalassetcats: (state) => (BS) => {
      //const tempobj= JSON.parse(JSON.stringify(state.assetslist1));
     // var tempobj2=Object.values(state.assetslist1);
    //  var tempobj2=state.assetslist1;

    var tempo =  JSON.parse(JSON.stringify(state.assetslist1));

    const tempobj2 = [];
        Object.keys(tempo).forEach(key => tempobj2.push(

           tempo[key]
        ));




       var newarray= tempobj2.filter(pilot => pilot.BS === BS)  ;  
    
        var categories = newarray.map(item => item.Type).filter((Value, index, self) => self.indexOf(Value) === index);

     // create a new object with sum of amounts
    var result = categories.map(item => { 
      return { 
         Type: item, 
         Value: tempobj2.filter(c => c.Type === item).reduce((accum, curr) => accum + parseFloat(curr.Value), 0) 
           } 
     });
      return result;
     },


     totalbytype: (state) => (type) => {
      //const tempobj= JSON.parse(JSON.stringify(state.assetslist1));

      var tempo =  JSON.parse(JSON.stringify(state.assetslist1));

      const tempobj3 = [];
          Object.keys(tempo).forEach(key => tempobj3.push(
             tempo[key]
          ));
  
     // var tempobj3=Object.values(state.assetslist1);
     // var tempobj3=state.assetslist1;
      const total = tempobj3.filter(c => c.Type === type).reduce((acc, val) => acc + parseFloat(val.Value), 0)  ;   
   //   const total =100;
       return parseFloat(total).toFixed(2);
         //return 100;
      },


     listassetsincat: (state) => (cat) => {
    //  var tempo =  JSON.parse(JSON.stringify(state.assetslist1));
      var tempo =  [...state.assetslist1];

      const tempobj4 = [];
          Object.keys(tempo).forEach(key =>
            { tempobj4.push(tempo[key]);
              
              console.log(key);
              console.log(tempo[key]);


            }        
          );

      var newarray= tempobj4.filter(pilot => pilot.Type === cat)  ;  
      return newarray;
    },

    assetparams: (state) => (id) => {
     // const tempobj= JSON.parse(JSON.stringify(state.assetslist1));
     var tempo =  JSON.parse(JSON.stringify(state.assetslist1));

     const tempobj5 = [];
         Object.keys(tempo).forEach(key => tempobj5.push(
            tempo[key]
         ));
 
 //     var tempobj5=Object.values(state.assetslist1);
     // var tempobj5=state.assetslist1;
      var newarray= tempobj5.filter(pilot => pilot.ID === id) [0] ;  
   
      return newarray;
    },


    defaultassetparams: (state) => (cat) => {
     // var newarray= state.Defaultassets.filter(pilot => pilot.Type === cat) [0] ;  
     // return  state.Defaultassets[cat];
     return  state.Defaultassets[cat];
    // return  newarray;

    },


    newid: (state) =>  {
     const maxValueOfY = Math.max(...state.assetslist1.map(o => o.ID));     
     return maxValueOfY+1;
 
    },

     Userid : (state,getters, rootState,rootGetters) =>  rootState.auth.user,
 
     corsthost : (state,getters, rootState,rootGetters) =>  rootState.auth.cors_host,
    
     getuserid(state,getters, rootState, rootGetters, dispatch){
     
    //rootGetters.StateUser;
     //rootGetters['auth'] ;
   //   rootGetters.someGetter 
     return  rootGetters['auth/StateUser'];


 
  },
       
    // return rootGetters['auth/StateUser'];
     //const userid = rootGetters['auth/StateUser'];
     //const userid=this.$store.getters['auth/StateUser'];
      
     //rootState.auth.user.Email;
   // }
   
    

  


/*

     totalliabcats: state =>  {
        var categories = state.liablist.map(item => item.type).filter((value, index, self) => self.indexOf(value) === index);
    
         // create a new object with sum of amounts
        var result = categories.map(item => { 
          return { 
             type: item, 
             amount: state.liablist.filter(c => c.type === item).reduce((accum, curr) => accum + curr.value, 0) 
               } 
         });
          return result;
         }

*/

 
 };
 

 
 
 const mutations = {
   //  addTodo: function ( state,newtodo) { (state.todos.push(newtodo)) }
     addAsset:  ( state,newasset) => { (state.assets.push(newasset)) },
     changeAssetCat:  ( state,assetcat) => { (state.assetcat= Object.assign({}, assetcat)) },
    
     async UPDATE_ASSET (state,  input) {
      // find doesn't create a new object, it returns an existing one from users array and we can modify it
     const editedAsset=input.editedAsset;
     const webaddress1=input.webaddress1;
     
      const oldAsset = state.assetslist1.find( asset => asset.ID === editedAsset.ID );
      if (oldAsset) {
         // not creating a new object but modifying old object here
         Object.assign(oldAsset, editedAsset);
         Object.assign(editedAsset, {submit: true});

      
     //    const hostid= getters.corsthost;    

        // const assetdata = {submit: true , ClientID: ClientIDinput };
     //     await axios.post('http://localhost:8080/one/assets/updateasset.php', editedAsset).then((res) => {
      //    await axios.post('http://localhost:'+this.hostid+'/one/assets/updateasset.php', editedAsset).then((res) => {
        
          await axios.post(webaddress1+'assets/updateasset.php', editedAsset).then((res) => {
        
          
          console.log(editedAsset);
         console.log(res.data);
          
          })

      };
      },

      async LOAD_ASSETS (state, outputData) {
        
      //  state.assetslist1=Object.values(Object.assign({}, outputData));
      //   state.assetslist1=Object.values(Object.assign({}, outputData));


      console.log("outputData in mutation");
      console.log(outputData);
     // state.assetslist1= Object.assign({}, outputData);

        state.assetslist1=[...outputData];
     // Vue.set(state, 'assetslist1', [...outputData]);

     //state.assetslist1= Object.assign(null, outputData);
     // state.assetslist1= Object.assign([], outputData);

      //   Object.assign(state.assetslist1, outputData);
         console.log("Load_assets");
         console.log(state.assetslist1);


             
        },
  


     async ADD_ASSET(state,  newasset) {

         state.assetslist1.push(newasset);

     //    state.assetslist1.push({Object.values(assettoadd)});
     //   state.assetslist1=Object.assign(state.assetslist1, Object.values({assettoadd}));
          
         console.log("add asset : state.assetslists1" ); 
         console.log(state.assetslist1);

        //  Object.assign(state.assetslist1, newasset["parameters"]);
       

      },

    
    
  async DELETE_ASSET(state,  input){

      console.log("delete asset called");
      console.log(input);
      console.log(state.assetslist1);
  
        const index = state.assetslist1.findIndex(asset => asset.ID == input.AssetID);

        console.log ( "index");
        console.log ( index);
        
        state.assetslist1.splice(index, 1) ;  
        console.log(state.assetslist1);
        
      },



  RESET_STATE (state) {
        state= Object.assign({}, defaultstate())
        },

  RESET_STATEDefaultassets (state) {
    state.Defaultassets=Object.assign({}, defaultstate().Defaultassets)
   },

   EMPTY_STATE (state) {
    console.log("emptystate assets called");
   // state.assetslist1=null;
   // state.assetslist1={};
   // Object.assign( state.assetslist1, state.assetslist2);
 // const tempobj = state.assetslist2 ;
   // state.assetslist1 = { ...state.assetslist1, tempobj};
  // state.assetslist1 = { ...state.assetslist1, tempobj};
  // Object.assign( ...state.assetslist1,  ...state.assetslist2);


  // console.log('state.assetslist2');
  const tempobj8= JSON.parse(JSON.stringify(state.assetslist2));
   state.assetslist1= [...tempobj8];
    console.log("state.assetslist1");
    console.log(state.assetslist1);

    }
           

 };


 


// *******************




 const actions = {

  
 addAssetAction: function ({commit}, newasset)  {
 // addTodoAction: function ({commit,state}, newtodo)  {
 
     setTimeout(
         function() {
             commit("addAsset",newasset);
         //   console.log("hello");
          } ,100
         
     )
 } ,
 resetstate: function ({commit, dispatch})  {
  commit('RESET_STATE');
 },
 resetstatedf: function ({commit, dispatch})  {
  commit('RESET_STATEDefaultassets');
 },


async deleteasset ({commit, getters}, id)  {

      const userid=getters.Userid;
      //console.log(userid);
      //rootState.auth.user.Email;
      //console.log(userid.Email);

      const assetdata = {submit: true , AssetID: id,  ClientID: userid.Email};

      await axios.post(getters.webaddress1+'assets/deleteasset.php', assetdata).then((res) => {
          
      console.log(res.data)
          });

      commit('DELETE_ASSET', assetdata);
      return "complete";
 
 },



 async addnewasset({commit,getters}, newasset) {
  

          const assetdata = {submit: true , ClientID: newasset["ClientID"], BS: newasset.parameters["BS"],
          AssetType: newasset.parameters["Type"], Designation:newasset.parameters["Designation"],
          Value:newasset.parameters["Value"], IR: newasset.parameters["IR"], Duration:newasset.parameters["Duration"], Nominal:newasset.parameters["Nominal"], 
          CG: newasset.parameters["CG"], IssueDate: newasset.parameters["Issue_date"] };
      
      //    console.log(assetdata);
       


        //        await axios.post('http://localhost:'+this.hostid+'/one/assets/addasset.php', assetdata).then((res) => {


        await axios.post(getters.webaddress1+'assets/addasset.php', assetdata).then((res) => {

        console.log(res.data)

        });



    commit("ADD_ASSET",newasset.parameters);

    return "complete";

  //   commit("LOAD_ASSETS",newasset.ClientID);

 },



async loadassets({commit, getters, state}, ClientIDinput) {
  //   state.assetslist1.push(newasset);

  console.log("Starting to load New Asset in STore");
  // this.hostid= getters.corsthost;    
  // console.log('this.hostid');
  // console.log(this.hostid);

    console.log("getters.webaddress1: ");
    console.log(getters.webaddress1);
    console.log(Object.values(state.assetslist1));
    const assetdata = {submit: true , ClientID: ClientIDinput };

        await axios.post(getters.webaddress1+'assets/loadassets.php', assetdata).then((res) => 
        {

          console.log("res.data");
          console.log(res.data);
          
          if (Object.keys(res.data).length !== 0) {


            let outputData = res.data.map(item=>{
              return {
                            ID:parseInt(item.ID),
                            BS:item.BS, 
                            Type:item.Type,
                            Designation:item.Designation,
                            Value:parseFloat(item.Value),
                            IR:parseFloat(item.IR),
                            CG:parseFloat(item.CG),
                            Duration:parseFloat(item.Duration),
                            Nominal:parseFloat(item.Nominal),
                            Issue_date:item.IssueDate,
                        }
                  }

              );
                          console.log("output data after loading assets : ");
                          console.log(outputData);

                          commit("LOAD_ASSETS",outputData);
                        } else {
                          commit("LOAD_ASSETS",[]);

                        // state.assetslist1=Object.values(Object.assign({}, {}));
                        };

                        console.log(" state.assetslists1 after loading assets : ");
                        console.log(state.assetslist1);

                                
                                // state.assetslist1=Object.assign({}, outputData);
                        })
        

    console.log("New Assets Loaded");

  //   commit("LOAD_ASSETS",newasset.ClientID);

 },




updateasset({commit, getters}, editedAsset) {
      
    commit("UPDATE_ASSET",{webaddress1:getters.webaddress1, editedAsset:editedAsset})
},

emptystate({commit,state}){
 
commit("EMPTY_STATE");
}
 
 };
 
 export default {
     namespaced: true,
     state,
     getters,
     actions,
     mutations,
     
 }
 