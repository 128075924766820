
import Vue from 'vue'
import Vuex from 'vuex';

import {createStore} from 'vuex'
import auth from './modules/auth'
import assets from './modules/assets'
import parameters from './modules/parameters'
import todos from './modules/todos'

import insurers from './modules/insurers'
// the following is used to have vue pages that are not reset after relad

import createPersistedState from "vuex-persistedstate";

// Create a new store instance.
const store = createStore({
    modules:{
       auth, assets, todos, parameters,
         insurers
    }, 
    plugins: [createPersistedState()]
})
export default store;

