<template>
 <div class="assetslistbycat"  >


    <div class="topsection2">


       


        <div class="div1">
           <p class="title"> {{ID}} portfolio</p> 
        </div>

        <div class="divlabelammount"> 
               <p style="font-size: 1em; colour: green; font-weight: bold; flex:1; margin:10px 10px 2px 10px; text-align: right;" > {{totalbytypeamount}} </p>
         </div> 
       

        <div class="div3">    
                 <router-link :to="{name: 'AddNewAsset', params: { Type: ID, BSCat:BSCat}}">
          
              <i class="fas fa-plus"  ></i>
              </router-link> 
              
        </div>
       </div>




    <div class="bottomsection" :key="componentKey"  >
     <ul id="example-1"  >
    
        <li v-for="asset in assetlists" :key="asset.ID">
          
           <!--
          <router-link   :to="{name: 'Assetparameters', params: { ID: asset.ID,  BSCat:assetcategory} } "  style="text-decoration: none; color: inherit;">
          -->
              <div class="listofassets" >

           <!--
               <router-link   :to="{name: 'Assetparameters', params: { ID: asset.ID, currency_left:curr_left, currency_right:Currencyformat.right, BSCat:assetcategory} } "  style="text-decoration: none; color: inherit;">
          


             <AssetItem  :assettype="asset.Designation"  :assetval="asset.Value" :currencyformat="props.Currencyformat" link="Assetparameters" :ID="asset.ID" style="flex:1" />  
        
           -->
                <AssetItem  :designation="asset.Designation" :assettype="asset.Type"   :assetval="asset.Value"  :assetID="asset.ID" link="Assetparameters" :ID="asset.Type"   :BSCat="asset.BS"   style="flex:1" @delete="test()" />  
              
            
            </div>
             <!--
             </router-link> 
            -->
       </li>
     </ul>

     </div>



     <div class="buttonsection">

         

          <button class="executionbutton" @click=" $router.push({ name: 'Portfolioview' })
"> 
            <fa class= "icon_button" icon="arrow-alt-circle-left"/>  
            Portfolio View
                  </button>



</div>


 </div>

</template>

<script>
import { ref } from 'vue'
import AssetItem from './AssetItem.vue'
import {createNamespacedHelpers} from 'vuex'
import Dropdown2 from './Dropdown2.vue'
import Addassetcategory from './AddAssetCategory.vue'
import Assetparams from './Assetparams.vue'
import Assetparameters from './views/Assetparameters.vue'
import Modal from "../comps/Modal.vue"
import AddNewAsset from "./views/AddNewAsset.vue"


const assets= createNamespacedHelpers('assets');
const auth = createNamespacedHelpers('auth');

export default {
 name: 'Catdetails',
 props:{ ID:{type: String, default: 'Cash'}, BSCat:{type: String, default: 'Assets'}, ckey:{type: [Number,String], default: 1}},
 components: {AssetItem,Dropdown2, Addassetcategory, Modal , Assetparams, Assetparameters},
   data() { 

    return {assetlists:{}, assetcategory:"Assets",
             //  ID:{type: String, default: 'Cash'}, 
               Currencyformat:{type: Object,  default: () => ({}),} ,

               totalbytypeamount:"",
               componentKey:0
           };

           
          } ,

   setup(){
        const items = ref([ 
           {note: 'alfa'}, {note:'bete'}, {note:'zeta'}, {note:'gogo'}]);
        return {
            items
      };
        
        },
   



    computed : {

          ...assets.mapGetters(['listassetsincat','totalbytype']),
          ...auth.mapGetters(['currencyformat']),
   
       },

      methods: {
        formatPrice(value) {
                var   val= this.currencyformat.left +" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currencyformat.right;              
              return val;
          },


      test(){
        console.log("delete called"); 
      //  this.componentKey+=1;
      //   console.log(this.componentKey);

   

          this.totalbytypeamount=  this.formatPrice(this.totalbytype(this.ID));
          this.assetlists = Object.assign({}, this.listassetsincat(this.ID));
    


     if (typeof this.assetlists.length > 0 )
      {
      if (this.assetlists[0].BS=="A") {this.assetcategory="Assets"} else {this.assetcategory="Liabilities"} ;
      }  
      else{};
  


      }

      },
     

   created() {
    //  this.ID=this.$route.params.IDi;
    //  console.log(ID);

    this.componentKey=this.ckey;

    console.log(this.ID);
   

      this.totalbytypeamount=  this.formatPrice(this.totalbytype(this.ID));
      this.assetlists = Object.assign({}, this.listassetsincat(this.ID));
        
      console.log(this.assetlists);

     // if (typeof this.assetlists !== "undefined" && this.assetlists !== null)

     if (typeof this.assetlists.length > 0 )
      
      {
      if (this.assetlists[0].BS=="A") {this.assetcategory="Assets"} else {this.assetcategory="Liabilities"} ;
    
      }  
      else{};
  
     
      // this.Currencyformat.left=this.$route.params.curr_left;
      // this.Currencyformat.right=this.$route.params.curr_right;

      //console.log(this.$route.params.ID);
      //console.log(this.$route.params.curr_left);
      //console.log(this.$route.params.curr_right);


      /*
      cattattribution(){
        var catt = this.assetlists[0].BS; 
          console.log(this.assetlists[0].BS);
          console.log(this.catt);
          if (this.assetlists[0].BS=="A") {this.assetcategory="Assets"} else {this.assetcategory="Liabilities"} ;
      };
     */
   //  this.componentKey+=1;
     
    // console.log("this.ckey");
    // console.log(this.ckey);
      
     },

   

      
 
    
}

 
</script>

<style scoped>

div .assetslistbycat {
display:flex;
flex-direction: column;
width: 420px;
margin:50px auto;
background: white;
text-align: left;
padding: 10px 0 10px 0 ;
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
}

.assetslistbycat  .div1 {
display:flex;
flex: 3;
align-items: center;

padding-left: 0px;
   font-size: 16px;
     font-weight: bold;


}


.p_classlabel{
display: flex;
text-align:right;
margin-right: 10px;

}



.div2 p{
text-align:right;
margin-right: 10px;
flex: 3;
}

div .div2 {
display:flex;
flex: 4;
align-content: flex-end;
justify-content: right;
}

div .div3 {
display:flex;
flex: 1;
align-items: center;
justify-content: right;
margin-right: 10px;

}





div .buttonsection {

margin-top: 30px;
display:flex;
/* justify-content: flex-end; 
border-bottom: 1px solid rgb(148, 144, 144);
border: 1px solid rgb(148, 144, 144);
border-bottom: 1px solid rgb(148, 144, 144);
*/
height: 50px;

padding: 2px 10px 2px 10px;
justify-content: space-between;
align-items: center;

font-size: 16px;
     font-weight: bold;


}

div .topsection2 {

margin-top: 20px;
display:flex;

height: 50px;

border-bottom: 1px solid rgb(148, 144, 144);


padding: 2px 10px 2px 10px;
justify-content: space-between;
align-items: center;

}


.routerlink{
  align-self: right;
  margin-right: 10px;
}

ul{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  width:100%;
}

.executionbutton {
  background-color: #0d6efd;
  color: white;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 10px 00px 10px 00px;
  height: 45px;
   width: 150px;
 justify-content: center;
    align-items: center;
}
.executionbutton:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}

.title{
     padding-left: 7px;
     font-size: 22px;
     font-weight: bold;
     color: #285fb3;
}


div .divlabelammount {
  display:flex;
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
 
  height: 40px;
  max-width: 300px;
  border: none;
  align-self: right;
  flex: 3;
  align-content: flex-end;
  justify-content: right;

}

</style>