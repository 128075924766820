<template>
  <div class="div_doughnutChart" >
    <vue3-chart-js
        :id="doughnutChart.id"
        :type="doughnutChart.type"
        :data="doughnutChart.data"
        @before-render="beforeRenderLogic"
        :options="doughnutChart.options"
    ></vue3-chart-js>
  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import 'chart.js-plugin-labels-dv'

export default {
  name: 'Piechart',
  components: {
    Vue3ChartJs,
  },
  props:{assetval:{type: Number,default: 0.00}, liabval:{type: Number,default: 0.00}, netassets: {type: Number,default: 0.00}, currency:{type: Object,  default: () => ({}),} },

  setup (props) {
    const doughnutChart  = {
      id: 'wealth',
      type: 'doughnut',
      data: {
        labels: [ 'Assets', 'Liabilities','Wealth'],
        datasets: [
          {
            backgroundColor: [
              '#41B883',
              '#E46651',
              '#00D8FF',
        
            ],
            data: [props.assetval, props.liabval, props.assetval-props.liabval ]
          }
        ]
      },
      options: {
        plugins: {
         labels: {
        // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
        render: 'value',

        // precision for percentage, default is 0
        precision: 0,

        // identifies whether or not labels of value 0 are displayed, default is false
        showZero: true,

        // font size, default is defaultFontSize
        fontSize: 12,

        // font color, can be color array for each data or function for dynamic color, default is defaultFontColor
        fontColor: '#fff',

        // font style, default is defaultFontStyle
        fontStyle: 'normal',

        // font family, default is defaultFontFamily
        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",

        // draw text shadows under labels, default is false
        textShadow: true,

        // text shadow intensity, default is 6
        shadowBlur: 10,

        // text shadow X offset, default is 3
        shadowOffsetX: -5,

        // text shadow Y offset, default is 3
        shadowOffsetY: 5,

        // text shadow color, default is 'rgba(0,0,0,0.3)'
        shadowColor: 'rgba(255,0,0,0.75)',

        // draw label in arc, default is false
        // bar chart ignores this
        arc: true,

        // position to draw label, available value is 'default', 'border' and 'outside'
        // bar chart ignores this
        // default is 'default'
        position: 'default',

        // draw label even it's overlap, default is true
        // bar chart ignores this
        overlap: true,

        // show the real calculated percentages from the values and don't apply the additional logic to fit the percentages to 100 in total, default is false
        showActualPercentages: true,

        // set images when `render` is 'image'
        images: [
          {
            src: 'image.png',
            width: 16,
            height: 16
          }
        ],

        // add padding when position is `outside`
        // default is 2
        outsidePadding: 4,

        // add margin of text when position is `outside` or `border`
        // default is 2
        textMargin: 4
      }
        },
        legend: {
          labels: {
              fontColor: "#acb5bf",
          }
        },

            /*
            tooltips: {
            callbacks: {        
                title: (tooltipItems, data) => data.labels[tooltipItems[0].index],
                label: (tooltipItems, data) =>
                data.datasets[tooltipItems.datasetIndex].label + ': ' + tooltipItems.value + '%'
                    }
                        }, 
             */

    },
   
      
    }
  
    const beforeRenderLogic = (event) => {
      //...
      //if(a === b) {
      //  event.preventDefault()
      //}   
    }    

    return {
      doughnutChart,
      beforeRenderLogic
    }
  },
}
</script>


<style>
div .assetslist {

}

</style>
