<template>
 <div class="assetparameters">
        <router-link class="routerlink" :to="{ name: 'Portfolioview' }">Portfolio</router-link> 
      <p class="title" v-show="BSCat=='Assets'" > Asset parameters </p> 
      <p class="title" v-show="BSCat=='Liabilities'" > Liability parameters </p> 
     <div class="topsection">
<!--

    <p class="p_labelassettype"> Asset type : {{assetparameters.Type }} </p> 

    <option selected="Cash" disabled="" value="Cash">--Select Category--</option>
  
-->
  
<p style="font-size: 1em; colour: green; font-weight: bold; margin:10px;" > Asset type </p>   
    <select class="lecountryselect" name="country" v-show="BSCat=='A'" v-model="assetparameters.Type"  id="l_e_country" @change="updateassetcat">
                   
                    <option value="Cash">Cash</option>
                    <option value="Equity">Equity</option>
                    <option value="Bond">Bond</option>
                    <option value="Property">Property</option>  
                    <option value="Fund">Fund</option>   
     </select>

     <select class="lecountryselect" name="country" v-show="BSCat=='L'" v-model="assetparameters.Type"  id="l_e_country" @change="updateassetcat">
                   
                   <option value="Debt">Debt</option>
                   <option value="Mortgage">Mortgage</option>
              
    </select>



     </div>



    <div class="bottomsection">
       <!--

         -->
   

           <div class="sectionline" v-if="defaultassetparameters.Designation.visible===true">
                    <div class="div1label">
                          <p class="p_labelparam" >{{defaultassetparameters.Designation.designation}} </p>   
                    </div>                        
                    <div class="div1input">
                         <Inputtext   :value="assetparameters['Designation']"  @returnevent="assetparameters['Designation']=$event"    />  
                   
                        </div>   
           </div> 
             <div class="sectionline" v-if="defaultassetparameters.Value.visible===true">
                    <div class="div1label">
                          <p class="p_labelparam" > {{defaultassetparameters.Value.designation}} </p>   
                    </div>                        
                    <div class="div1input">

                      <!--
                           <Inputnumber   :value="assetparameters['Value']"  :symbol_left=currency.left :symbol_right=currency.right @returnevent="assetparameters['Value']=parseFloat($event)"    />  
          
                      -->
                    
                      <input class="form-control form-input3" type="text" v-if="currencyformat.left"  v-model="currencyformat.left" disabled > 
                       <input class="form-control form-input2" type="text"  v-model="tempimput['Value']" @blur="inputblurr('Value')"  @focus="inputfocus('Value')" v-on:keyup.enter="inputblurr('Value')" >                     
                       <input class="form-control form-input3" type="text" v-if="currencyformat.right"  v-model="currencyformat.right " disabled > 
                   



                     </div>   
           </div> 





       <div class="sectionline" v-if="defaultassetparameters.IR.visible===true">
                    <div class="div1label">
                          <p class="p_labelparam" > {{defaultassetparameters.IR.designation}} </p>   
                    </div>                        
                    <div class="div1input">

                    <!--  
                      <span class="input-symbol-percentage">
                               <input class="inputamount" type="text" id="fname" name="fname"  :value="assetparameters['IR']*100"  @change="updateValue" >   
                        <Inputpercentage  :symbol_left="" :symbol_right=""   :value="100"  style="flex:1"  @newfooevent="assetparameters[name] = updateformat($event,paramformat[name])" />  
                          </span>



                      -->
                   <inputpercentage   :value="(assetparameters['IR']*100).toFixed(2)" symbol_left="" symbol_right="%"  @returnevent="assetparameters['IR']=$event"  />  
                    
                    
                       </div>   
           </div> 

            <div class="sectionline" v-if="defaultassetparameters.CG.visible===true">
                    <div class="div1label">
                          <p class="p_labelparam"> {{defaultassetparameters.CG.designation}} </p>   
                    </div>                        
                    <div class="div1input">

                   <inputpercentage   :value="(assetparameters['CG']*100).toFixed(2)" symbol_left="" symbol_right="%"  @returnevent="assetparameters['CG']=$event"  />  
                    
                    
                       </div>   
           </div> 

       <div class="sectionline" v-if="defaultassetparameters.Duration.visible===true">
                    <div class="div1label">
                          <p class="p_labelparam" >{{defaultassetparameters.Duration.designation}} </p>   
                    </div>                        
                    <div class="div1input">
               <!--
                      <Inputnumber   :value="assetparameters['Duration']"  symbol_left="" symbol_right="years" @returnevent="assetparameters['Duration']=Number($event)"    />  
               -->  
                        <input class="form-control form-input2" type="text"  v-model="assetparameters['Duration']"  >                     
                       <input class="form-control form-input3" type="text"  value="years " disabled > 
                   

                     </div>   
           </div>       
            <div class="sectionline"  v-if="defaultassetparameters.Nominal.visible===true">
                  <div class="div1label">
                          <p class="p_labelparam" >  {{defaultassetparameters.Nominal.designation}} </p>   
                    </div>                        
                  <div class="div1input">
 <!--
            <Inputnumber   :value="assetparameters['Nominal']"  :symbol_left=currency.left :symbol_right=currency.right @returnevent="assetparameters['Nominal']=$event"    />  
    
     <Inputnumber   :value=100 :symbol_left=currency.left :symbol_right=currency.right @returnevent="assetparameters['Nominal']=$event"    />  
                        
          --> 
                        

                         <input class="form-control form-input3" type="text" v-if="currencyformat.left"  v-model="currencyformat.left" disabled > 
                       <input class="form-control form-input2" type="text"  v-model="tempimput['Nominal']" @blur="inputblurr('Nominal')"  @focus="inputfocus('Nominal')" v-on:keyup.enter="inputblurr('Nominal')" >                     
                       <input class="form-control form-input3" type="text"  v-if="currencyformat.right"  v-model="currencyformat.right " disabled > 
                   


                     </div>   
           </div> 


            <!--
               <div class="sectionline"  v-if="defaultassetparameter[Issue_date][visible]==true">   
                   --> 

            <div class="sectionline"  v-if="defaultassetparameters.Issue_date.visible===true">
                  <div class="div1label">
                          <p class="p_labelparam" > {{defaultassetparameters.Issue_date.designation}} </p>   
                    </div>                        
                    <div class="div1input">
    
                          <input class="inputdate" type="date"  format="DD/MM/YYYY" default=dateconv    v-model=dateconv    placeholder=dateconv  @change="updatedate"  />

                     </div>   
           </div> 








     </div>
     <div class="bottom2buttons">
         <div class="bottom2left">
           
           <button class="executionbutton" @click="$router.go(-1)"> 
               <fa class= "icon_button" icon="xmark"/>   
             Cancel</button>
        </div>
        <div class="bottom2center">
           
           <button class="executionbutton" @click="deleteassetfunction()">
             <fa class= "icon_button" icon="trash"/>   
             Delete</button>

         </div>
         <div class="bottom2right">
             <button class="executionbutton" @click="saveasset()">
               <fa class= "icon_button" icon="circle-check"/>   
               Save
                  </button>
         </div>
        
     </div>



 </div>

</template>

<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'
// import Modal from "../comps/Modal.vue"
// import Inputparams from './Inputparams.vue'
import Inputnumber from '../components/Inputnumber.vue'
import Inputtext from '../components/Inputtext.vue'
import Inputpercentage from '../components/Inputpercentage.vue'
import moment from 'moment'

const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets');
const auth = createNamespacedHelpers('auth');

export default {
 name: 'Assetparameters',
 props:{ID:{type: Number}, BSCat:{type: String},},
 components: { Inputnumber, Inputtext,Inputpercentage },
   data() { 
    return {assetparameters:{}, defaultassetparameters:{}, dateconv : new Date().toISOString().substr(0, 10),
     currency:{type: Object,  default: () => ({})},
     tempimput:{Value:"", Nominal:""}   
  }
       
          }, 

 
    computed : {
         //  ...mapState({paramformats: state=>state.paramformat}),
         ...mapState(['paramformat']),
         ...auth.mapState(['user']),

          ...mapGetters(['assetparams','defaultassetparams']),
          ...auth.mapGetters(['currencyformat']),

              updatedate () {
       this.assetparameters["Issue_date"]=this.dateconv;
  
    }
         
  
       },
     methods : {
      ...mapMutations(['UPDATE_ASSET']),
      ...mapActions(['deleteasset','updateasset']),

      

      updateformat (value, format) {
    //    if (format!=="T" && value!=null){this.$emit('newfooevent', parseFloat(value).toFixed(this.paramformat))}
    // return "AA";
     var tvalue;
     if (format!=="T" && value!=null) {tvalue= parseFloat(parseFloat(value).toFixed(format))} else if (value!=null) {tvalue = value} ;
     return tvalue;
    },
     
    updateassetcat(){
      this.defaultassetparameters = Object.assign({}, this.defaultassetparams(this.assetparameters.Type))
     },

     numberWithCommas(x) {
        //    console.log(x);
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },

        Commastonumber(x) {
           const parts = x.toString().replace(/,/g, '');  
         
          return parseFloat(parts).toFixed(2);
          
        },
       
     inputblurr(val){
      this.tempimput[val]=this.numberWithCommas(this.tempimput[val]);
       },
         inputfocus(val){
          this.tempimput[val]=this.Commastonumber(this.tempimput[val]);
       },

       async saveasset(){

        this.assetparameters.Value= parseFloat(this.Commastonumber(this.tempimput.Value));
        this.assetparameters.Nominal= parseFloat(this.Commastonumber(this.tempimput.Nominal));

        Object.assign(this.assetparameters, {ClientID:this.user.Email });


        const result =  await this.updateasset(this.assetparameters);

         this.$router.go(-1)
        //console.log(this.assetparameters);

       },


       async deleteassetfunction(){

            const result =  await this.deleteasset(this.assetparameters['ID']); 

            this.$router.go(-1)
            //console.log(this.assetparameters);
            },
       

    },

     created() {
    

      //this.currency.left=this.$route.params.currency_left;
     // this.currency.right=this.$route.params.currency_right;
     // this.ID=this.$route.params.ID;
     // console.log ("currency.left");
     // console.log (this.$route.params.currency_left);
     // console.log (this.currency.left);
     console.log (this.ID);


      this.assetparameters = Object.assign({}, this.assetparams(parseInt(this.ID)));
      this.defaultassetparameters = Object.assign({}, this.defaultassetparams(this.assetparameters.Type));
     
        this.dateconv=  moment(String(this.assetparameters["Issue_date"])).format('YYYY-MM-DD');
        console.log(this.assetparameters.Value);
        console.log(this.assetparameters.Nominal);
      this.tempimput.Value=this.numberWithCommas(this.assetparameters.Value);
      this.tempimput.Nominal=this.numberWithCommas(this.assetparameters.Nominal);

     
     //   console.log (this.defaultassetparameters.Duration.visible) 
       // this.dateconv= moment(String("10/10/2021")).format('DD/MM/YYYY')

      // this.dateconv= moment(String("2021-10-10")).format('YYYY-MM-DD')

     },

     
    
}

 
</script>

<style scoped>
div .assetparameters {
 
display:flex;
flex-direction: column;
max-width: 420px;
margin:10px auto;
background: white;
text-align: left;
padding: 10px 0;
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
}



div .topsection {

margin-top: 10px;
display:flex;
/* justify-content: flex-end; 
*/
height: 50px;

justify-content: space-between;


background: white;
padding: 0px;
border-radius: 3px;
border-top :1px solid rgb(204, 204, 211);
border-bottom: 1px solid rgb(204, 204, 211);

}

div .bottom2buttons {
display :flex;
justify-content:space-between;
align-items: center;
margin-top: 10px ;
  gap: 10px;

}

div .bottom2buttons.bottom2left {
display :flex;
flex:1;
max-height: 50px;
align-self: flex-start;



}
.bottom2buttons.bottom2right {
display :flex;
flex:1;
max-height: 50px;
align-self: flex-end;


}


.bottom2buttons.bottom2center {
display :flex;
flex:1;
max-height: 50px;


}

.bottomsection{
   display:flex;
   flex-direction: column;
justify-content: space-between;
align-items: flex-start;
width:100%;
 }

div .sectionline{
display:flex;
justify-content: space-between;
align-items: flex-start;
width:100%;

margin:5px 0 5px 0 ;
background: white;
padding: 0px;
border-radius: 3px;
border-bottom: 1px solid rgb(204, 204, 211);
}

div .div1label {
display:flex;
flex:1;

}

div .div1input {
display:flex;
flex:1;

}

.p_labelassettype{
text-align: left;
font-size: 1em; colour: rgb(180, 15, 15); font-weight:bold; flex:1; 
margin: 0px 0 0px 0px;

}


.inputamount {

    max-width: 100%;
   font-size: 17px;
    height: 40px;
    text-rendering: auto;

    border-bottom: 1px solid rgb(85, 83, 83);
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
 
    

    padding: 0 10px 0 20px;
    color: #999;
    background: none;

}

.p_labelparam{
font-size: 1em; colour: green; font-weight:500; margin:10px 0 2px 10px;
}



.input-symbol-percentage {
    position: relative;
}
.input-symbol-percentage input {
    padding-left:18px;
}
.input-symbol-percentage:before {
    position: absolute;
    content:"%";
    right: 5px;
}


.inputdate {

    max-width: 100%;
   font-size: 17px;
    height: 40px;
    text-rendering: auto;

    
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    

    padding: 0 10px 0 20px;
    color: #999;
     background: none;
   

}




.executionbutton {
  background-color: #0d6efd;
  color: white;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 10px 00px;
  height: 45px;
   width: 130px;
 justify-content: center;
    align-items: center;
}
.executionbutton:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}



.routerlink{
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 20px;
}

.icon_button{
  margin-right: 10px;
}


.title{
     padding-left: 7px;
     font-size: 22px;
     font-weight: bold;
     color: #285fb3;
}


.lecountryselect{

 width:200px;
 height: 35px;
 align-self: center;
 margin-right:10px;
}


.form-control.form-input2{
    flex:3;
    text-align: center;
}
.form-control.form-input3{
    flex:1;
    text-align: center;
    border:none;

}


.form-control.form-input3:disabled{
    background-color: inherit;

}

</style>