<template>

  <div class="mainframecomponent">
    
    
    <div class="divi1">
              
         
       

            </div>
    
    <div class="divi2" >
            Company
         </div>
        
       
        
            <div class="divi3">
               Average satisfaction
            
            </div>

               <div class="divi4">
     
                  My rating
 
              </div>

        
          
        

    
          </div>
    
    </template>
    
    <script>
    import { ref } from 'vue'
    import Rating from './rating.vue'

    import StarRating from 'vue-star-rating'

    export default {
     name: 'Insurancetemplate',
  
     
         props: {
           },

      data() {
      return {
       
        
      };
      },
      

    methods: {

            },
      
        
    }
    
     
    </script>
    
    <style scoped>
    

    .mainframecomponent{
      width: 100%;
     display:flex;
     border-bottom: 1px solid #dee2e6!important;
    min-height: 40px;
    /* 
   */
     
    }

    .divi2{
            display: flex;
            flex:4;
            min-width:100px;
            color: #0d6efd;
            align-items:flex-end;;
            margin-bottom: 5px;
            margin-right: 10px;
    }

    .divi1{
          display: flex;
            margin: 0 5px;
            width:60px;

    }


    .divi3{
            display: flex;
            flex:2; 
           
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 10px;
            
    }

    .divi4{
            display: flex;
            flex:2; 
           
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 2px;
            

    }

    .p_name {
        text-align: left;
        align-self: flex-end;
        font-size: 17px;
         margin:0 0 0 0;
        padding: 0 10px;
       

    }

    label {
       margin:0;
        height:30px;
        text-align: left;
       
    }


    .flexinput {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-bottom: 1px solid #ccc;
      
      border-right: 0;
      line-height: 2.5;
    
    }
    
    .flexinput .inputcurrency {
      font-size: 17px;
      padding: 0 10px 0 20px;
      color: #999;
      background: none;
      height: 45px;
       max-width: 300px;

      border: none;
      
    }
    
    
    .flexinput .inputcurrency :focus {
      outline: 0;
    }
    .flexinput .inputcurrency .error {
      border-color: #ff0000;  

    }
    
    
    
    
    
    
  </style>