<template>
<div id="app">
    <NavBar3 />
  <!--  <router-view></router-view>  -->
  <!--  <SignupForm /> -->
  
  </div>
 
</template>

<script>

import NavBar from './components/NavBar.vue'
import NavBar3 from './components/NavBar3.vue'
import Portfolio from './components/Portfolio.vue'


import {createNamespacedHelpers} from 'vuex'
const {  mapActions } = createNamespacedHelpers('assets');
const insurers = createNamespacedHelpers('insurers');
const auth = createNamespacedHelpers('auth');

export default {
  name: 'App',
  components: {NavBar,Portfolio,NavBar3},

      computed : {
          ...mapActions(['resetstate', 'resetstatedf']), 
          ...insurers.mapActions(['resetinsurers']), 
          ...auth.mapActions({resetauth:'resetstate'}), 
       },


 
 
 created() {
       
      // this.resetstate;   
       this.resetstatedf;  
       this.resetinsurers;
       this.resetauth;

     },


}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 5px;
}
body{
  margin: 0;
  background: #eee;
}
</style>
