<template>
 
<div class="wrap">
<span class="decor"></span>
<div class="navo">
  <ul class="primary">
    <li>
      <a href="">Dog</a>
      <ul class="sub">
        <li>
             
             <div class="row">
              <a href="">Bulldog</a>
            <input type="checkbox" id="jack" value="Jack" v-model="checkedNames" />
             </div>                
        </li>
        <li>
           <div class="row">
             <a href="">Mastiff</a>
           <input type="checkbox" id="jack" value="Jack" v-model="checkedNames" />
             </div>     
          </li>
        <li>
           <div class="row">
          <a href="">Labrador</a>
         <input type="checkbox" id="jack" value="Jack" v-model="checkedNames" />
             </div>   
        </li>
        <li>
           <div class="row">
          <a href="">Mutt</a>
           <input type="checkbox" id="jack" value="Jack" v-model="checkedNames" />
             </div>  
          </li>
      </ul>
    </li>
    <li>
      <a href="">Cat</a>
      <ul class="sub">
        <li><a href="">Tabby</a></li>
        <li><a href="">Black Cat</a></li>
        <li><a href="">Wrinkly Cat</a></li>
      </ul>
    </li>
    
   
  </ul>
</div>
</div>


</template>

<script>
//importing bootstrap 5
//import "bootstrap/dist/css/bootstrap.min.css";

 //import { BDropdown } from 'bootstrap'



export default {
  name: 'Dropdown2',
  //components: {'b-dropdown': BDropdown},


   data() { 
        //newinfo:'Hello'
     // const newtodo=''
    return {checkedNames:'hello'
           }
          } ,
}
</script>


<style>


 .row{
display: flex;
justify-content: baseline;
align-items:center;
 }

.wrap {
  display: inline-block;
  -webkit-box-shadow: 0 0 70px #fff;
  -moz-box-shadow: 0 0 70px #fff;
  box-shadow: 0 0 70px #fff;
  margin-top: 40px;
}

.wrap body { 
  background: #ccc;
  font-family: helvetica, arial, serif;
  font-size: 13px;
  text-transform: uppercase;
  text-align: center;
}
/* a little "umph" */
.wrap .decor {
  background: #6EAF8D;
  background: -webkit-linear-gradient(to left, #CDEBDB 50%, #6EAF8D 50%);
  background: -moz-linear-gradient(to left, #CDEBDB 50%, #6EAF8D 50%);
  background: -o-linear-gradient(to left, #CDEBDB 50%, #6EAF8D 50%);
  background: linear-gradient(to left, white 50%, #6EAF8D 50%);
  background-size: 50px 25%;;
  padding: 2px;
  display: block;
}

.wrap a {
  text-decoration: none;
  color: #fff;
  display: block;
}

.wrap ul {
  list-style: none;
  position: relative;
  text-align: left;
}

.wrap li {
  float: left;
}

/* clear'n floats */
.wrap ul:after {
  clear: both;
}

.wrap ul:before,
.wrap ul:after {
    content: " ";
    display: table;
}

.navo {
  position: relative;
  background: #2B2B2B;
  background-image: -webkit-linear-gradient(to bottom, #2B2B2B 7%, #333333 100%);
  background-image: -moz-linear-gradient(to bottom, #2B2B2B 7%, #333333 100%);
  background-image: -o-linear-gradient(to bottom, #2B2B2B 7%, #333333 100%);
  background-image: linear-gradient(to bottom, #2B2B2B 7%, #333333 100%);
  text-align: center;
  letter-spacing: 1px;
  text-shadow: 1px 1px 1px #0E0E0E;
  -webkit-box-shadow: 2px 2px 3px #888;
  -moz-box-shadow: 2px 2px 3px #888;
  box-shadow: 2px 2px 3px #888;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* prime */
.wrap ul.primary li a {
  display: block;
  padding: 20px 30px;
  border-right: 1px solid #3D3D3D;
}

.wrap ul.primary li:last-child a {
  border-right: none;
}

.wrap ul.primary li a:hover {
  
  color: #000;
}

/* subs */
.wrap ul.sub {
  position: absolute;
  z-index: 200;
  box-shadow: 2px 2px 0 #BEBEBE;
  width: 70%;
  display:none;
}

.wrap ul.sub li {
  float: none;
  margin: 0;
}

.wrap ul.sub li a {
  border-bottom: 1px dotted #ccc;
  border-right: none;
  color: #000;
  padding: 15px 30px;
}

.wrap ul.sub li:last-child a {
  border-bottom: none;
}

.wrap ul.sub li a:hover {
  color: #000;
  background: #eeeeee;
}

/* sub display*/
.wrap ul.primary li:hover ul {
  display: block;
  background: #fff;
}

/* keeps the tab background white */
.wrap ul.primary li:hover a {
  background: #fff;
  color: #666;
  text-shadow: none;
}

.wrap ul.primary li:hover > a{
  color: #000;
} 



@media only screen and (max-width: 600px) {

  .wrap  {
  .decor {
    padding: 3px;
  }
  
  .wrap {
    width: 100%;
    margin-top: 0px;
  }
  
   .wrap li {
    float: none;
  }
  
  .wrap ul.primary li:hover a {
    background: none;
    color: #8B8B8B;
    text-shadow: 1px 1px #000;
  }

  .wrap ul.primary li:hover ul {
    display: block;
    background: #272727;
    color: #fff;
  }
  
  .wrap ul.sub {
    display: block;  
    position: static;
    box-shadow: none;
    width: 100%;
  }
  
  .wrap ul.sub li a {
    background: #272727;
  	border: none;
    color: #8B8B8B;
  }
  
  .wrap ul.sub li a:hover {
    color: #ccc;
    background: none;
  }
  }
}


</style>