<template>
 <router-view></router-view>
  <div class="login">
    <div class="logincontent">
      <span class="title"> Log in </span>

      <form @submit.prevent="submit" class="formlogin1">
        <div class="rowline">
          <label for="username" class="rowlinelabel">Username:</label>
          <input type="text" name="username" v-model="form.username" placeholder="Email" required/>
        </div>
         <div class="rowline">
          <label for="password" class="rowlinelabel">Password:</label>
          <input type="password" name="password" v-model="form.password" placeholder="Password" required />
        </div>
        <button type="submit">Submit</button>
      </form>
      <router-link class="link" :to="{ name: 'Forgotlogin' }" >Forgot your password?</router-link>
     <!--<p><a href="#">Forgot your password?</a></p>  -->
      <p v-if="showError" id="error">Username or Password is incorrect</p>
    </div>
  </div>
</template>

<script>


import {createNamespacedHelpers} from 'vuex'


import axios from "axios";

import AxiosPlugin from 'vue-axios-cors';

const { mapState, mapActions } = createNamespacedHelpers('auth');



export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: "c@y.com",
        password: "1",
      },
      showError: false
    };
  },
  methods: {
    ...mapActions(["LogIn"]),


  


async submit() {
       const User = new FormData();
    // const User = "";

      User.append("uid", this.form.username);
      User.append("pwd", this.form.password);
     // console.log(this.form.username);
     // console.log(Object.values(User));
      const User2 = {submit: true , uid: this.form.username, pwd:this.form.password};
      // const User2 = {uid: "ab", pwd:"ab1"};
      /* 
       axios.post('http://localhost:8080/newlogin.php', User2).then((res) => {
            console.log(res.data)
          })
         */
      try {
          await this.LogIn(User2);
  ///        this.$router.push("/posts");
         
            this.showError = false;
            this.$router.push("/Home");
          

      } catch (error) {
        this.showError = true
      }
      
   }
    },
 
};
</script>

<style>


.login{
  display: flex;
  justify-content: center;
  padding: 60px 0;



}

.logincontent {
        display: flex;  
        width: 300px;
        padding: 40px;
        justify-content:center;
        flex-direction: column;
         border: 1px solid rgb(216, 216, 216);
         border-radius: 5px;
       
            
      }

.title{
             font-size: calc(1.375rem + 1.5vw);
            font-family: sans-serif;
            margin: 10px 0 20px 0;
            
}

.formlogin1{
margin:0px;
padding: 0px;;

}

.rowlinelabel {
  padding: 0;
  margin:0;
  
}

button[type="submit"] {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 10px 00px;
  width: 100%;
}
button[type="submit"]:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}
.rowline input {
  margin: 0 0 20px 0;
 /* box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06); */
  padding: 5px 5px;
 
   border: 1px solid rgb(216, 216, 216);
         border-radius: 0.5px;
 
}
#error {
  color: red;
}
</style>