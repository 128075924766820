<template>
   <div class="assetcomponent">
            <p> inside asset component</p>
       <div class="component"> 
        <div class ="assetcontent0_left"> 
             <div class = "asset" >
           
                        <label class ="labelparam"> title1 </label>
                        <input  type="text" :placeholder= "value1" />
                   
             </div>   
           
              <div class = "asset" >
            

                  
                        <label class ="labelparam"> title2 </label>
                        <input  type="text" :placeholder= "value2" />
                
             </div>   

              </div>

             <div class ="assetcontent_right"> 
                <i class="fas fa-check-circle" style="font-size: 1.73em; colour: green; font-weight: bold;"></i>
                <i class="far fa-trash-alt" style="font-size: 1.73em; colour: green; font-weight: bold;align-self: flex-end; margin : 5px 15px 5px 5px ;"></i>
            
             </div>   

       </div>
            <div class="assetgroup" >   
               <div class = "asset" v-for="(value, name, index) in allparams">
             
                   <div class ="assetcontent_left"> 
                    <!--    <p>  {{ index }}. {{ name }}: {{ value }}</p>    -->
                        <label class ="labelparam"> {{name}} </label>
                        <input  type="text" :placeholder= "value" />


                   </div>
                  <div class ="assetcontent_right"> 
                    <i class="fas fa-pen" @click="message('pen')"></i>
                    <i class="far fa-trash-alt" @click="message(allparams.type)"></i>
                   </div> 
             </div> <!-- 
            -->   
               

          
      </div>
      <p> this is the prop called note : {{assetid}}</p> 
       
 </div>
</template>

<script>
//import { mapGetters } from 'vuex';
// import {createNamespacedHelpers} from 'vuex'

// const { mapGetters } = createNamespacedHelpers('assets');




export default {  
Name:'AssetComponent',
 props:['assetid','allparams'],
computed : {
  //  ...mapGetters({allAssetComponents:"allAssetcomponents"})
     },
methods : {
       message: function(text) {
          console.log("clicked on button  "+text);
         } 
      }
}
</script>


<style>



div .assetcomponent {
max-width: 600px;
margin:3px auto;
background: white;
text-align: left;
padding: 10px;
border-radius: 3px;




div .assetelement {
max-width: 420px;
margin:2px auto;
background: rgb(163, 117, 117);
text-align: left;
padding: 15px;
border-radius: 3px;
position: relative;
}


.labelparam{
    color: #aaa;
    display: inline-block;
    margin: 5px 0 5px;
    padding: 0 0 0 5px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
input {
/*display: block;
box-sizing: border-box;*/

padding: 0 0 0 5px;
max-width: 100px ;
height: 10px;
border: none;
border-bottom: 1px solid #ddd;
color: #5555;
}

div .component {
display: flex;

flex-wrap: wrap; 
padding: 5px;
cursor: pointer;


}


div .assetgroup {

display: flex;
flex-wrap: wrap; 
 padding: 5px;
border-radius: 3px;

}

div .asset {
display: flex;
 align-items: center;
 padding: 5px;
border-radius: 3px;
margin: 1px 5px 0px 0px ;
border: 3px solid rgb(147, 217, 238);

}

div .assetcontent_right{
display: flex;
flex-direction: column;
flex:1;
justify-content:space-evenly;

}
div .assetcontent_left{
display: flex;
flex:6;
flex-direction: column;

}

div .assetcontent0_left{
display: flex;
flex-wrap: wrap; 
flex:6;

}


.fas.fa-pen{
margin: 5px 3px 5px 3px;
}

.far.fa-trash-alt{
margin: 5px 3px 0px 3px;
}

.fas.fa-check-circle{
     align-self: flex-end;
     margin : 5px 15px 5px 5px ;
}


}


</style>