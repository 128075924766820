<template>

    <div class="mainframecomponentrating">
         <div class="divi1">
            <input class="ratingtext" id="name" name="name" v-model='Rating'   >
         </div>
        
      
        
            <div class="divi2">
           
                <span class="fa fa-star checked"></span>
                <span class="fa fa-star checked"></span>
            
                <i class="fa fa-regular fa-star-half"></i>

                <font-awesome-icon icon="fa-regular fa-star-half-stroke"  />
               
               
              
                <span class="fa fa-star"></span>
                <span class="fa fa-star"></span>
          

            </div>
        
         

        </div>
    
    
    </template>
    
    <script>
    import { ref } from 'vue'
    export default {
     name: 'Rating',
    
    props: {
           Rating: {type:Number, default: 3}, 
            },
           
        
    }
    
     
    </script>
    
    <style scoped>
    


    .mainframecomponentrating  {
     display:flex;
     flex-direction: column;
    border:0;
     border-style:none;
    /**
    border: 1px solid #dee2e6!important;
     */ 
     
    }

    .divi1{
            display: flex;
            flex:1;
            border: none;
            justify-items: center;
    }
    

    .divi2{
            display: flex;
            flex:1;
            border: none;
          
            justify-content: center;
    }

     .ratingtext {
       margin:0;
        height:30px;
        text-align: center;
         color:blue;
        font-size: 1em; colour: green; font-weight:500; margin:10px 0 2px 10px;
    }


    .flexinput {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-bottom: 1px solid #ccc;
      
      border-right: 0;
      line-height: 2.5;
    
    }
    
    .flexinput .inputcurrency {
      font-size: 17px;
      padding: 0 10px 0 20px;
      color: #999;
      background: none;
      height: 45px;
       max-width: 300px;

      border: none;
      
    }
    
    
    .flexinput .inputcurrency :focus {
      outline: 0;
    }
    .flexinput .inputcurrency .error {
      border-color: #ff0000;  
    }
    

  
    

    .checked {
  color: orange;
  
}
    
.fa-star-half{
  color: orange;
}
    

    
    
    </style>