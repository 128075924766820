<template>
  <Carousel :autoplay="2000" :wrap-around="true" class="carouselmain" >
    <Slide v-for="slide in 4" :key="slide">

   


<div  class="carousel__item"  :style=image[slide-1]  >
    <!---- 
    <div  class="carousel__item"  :style="{ 'background-image': 'url(${isd})' }" >
<div class="carousel__item"  :style="{ backgroundImage : 'url('+ require('@/assets/20220109_103033.jpg') +')'  }"> 

    <div class="carousel__item"  :style="{backgroundImage: 'url(${imageaddress})'}"> 
      <div  class="carousel__item"  :style="{ color: color, backgroundImage : url(require(isd) )    }" >

   <div class="carousel__item"  :style="{ 'backgroundImage' : 'url(require('+isd+'))'  }"> 

   <div class="carousel__item"  :style="{ backgroundImage: 'url('+require('@/assets/20220109_103033.jpg')+')'  }"> 
      
    
    <div class="carousel__item"  :style="{ backgroundImage: 'url('+require('../../assets/20220109_103033.jpg')+')'  }"> 
        
  <div class="carousel__item"  v-bind:style="{ 'backgroundImage': 'url('+ carousselcontent2[slide-1].image +')'  }"> 

<div class="carousel__item"  :style="{ backgroundImage: 'url('+ imageaddress +')'  }"> 


<div class="carousel__item"  > -->

    
<div class="span_banner">

     <span class="span_item"    >
         <!----   
         
               <span class="span_item"  :style="{ backgroundImage: 'url( ' + require(carousselcontent2[slide-1].image) + ')' }"  >
         <Carousselitem1/>  -->
         <router-link class="linkcarrousel" :to="{ name: carousselcontent[slide-1].link }">    {{ carousselcontent[slide-1].title }}</router-link>   
                 
           

        </span>
</div>
   
    
        </div>
    </Slide>
   
    <template #addons>
  
    <Pagination />
     <Navigation />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'


import 'vue3-carousel/dist/carousel.css';



import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
// import Carousselitem1 from './Carousselitem1.vue';


export default defineComponent({
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
   // Carousselitem1
},
  data(){
    return{
   /*  carousselcontent:[{title:'build your profolio',image: '../../assets/20220109_103033.jpg'},
       {title:'calculate your retirement income',image: '../../assets/20220109_103033.jpg'},
       {title:'what is your wealth',image: '../../assets/20220109_103033.jpg'},
       {title:'Lila is magic !',image: '../../assets/20220109_103033.jpg'}
       ]
   */
 carousselcontent:[
       {title:'How long will you live ?', link:'Lifeexpectancy'},
       {title:'Evaluate your real wealth', link:'Portfolioview' },
       {title:'Find your retirement income', link:'Annuity'},
       {title: 'Find the best insurance for you', link:'Insurers'}   
     ],




//imageaddress: "../../assets/20220109_103033.jpg",
// isd: '/Users/alexisbailly/Documents/Programming/Website/vueform/web-form/src/assets/20220109_103033.jpg',
// isd: "url('@/assets/20220109_103033.jpg')",
//  isd: '../../assets/20220109_103033.jpg',

  image:[
{ backgroundImage: 'url('+ require('@/assets/20220109_103033.jpg') +')' },
{ backgroundImage: 'url('+ require('@/assets/20220109_102751.jpg') +')' },
{ backgroundImage: 'url('+ require('@/assets/20220202_075936.jpg') +')' },
{ backgroundImage: 'url('+ require('@/assets/20220208_130344.jpg') +')' },
{ backgroundImage: 'url('+ require('@/assets/20220109_102843.jpg') +')' },
  ] ,
   

  

  carousselcontent2:[{title:'build your profolio',image: '../../assets/20220109_103033.jpg'},
       {title:'calculate your retirement income',image: '../../assets/20220109_103033.jpg'},
       {title:'what is your wealth',image: '../../assets/20220109_103033.jpg'},
       {title:'Lila is magic !',image: '../../assets/20220109_103033.jpg'}
       ]

  };
  }
});
</script>

<style >

.carouselmain{
 
 
}

.carousel__item {
  min-height: 400px;
  width: 100%;
 
  
  /*     
   background-color: var(--vc-clr-primary);   

background-image: url('../../assets/20220109_103033.jpg') ;  ;



*/
background-size: cover;
   background-color: green;
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
  
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
  background-color: #0284b0;

}

.carousel__pagination-button {
   background-color: rgb(192, 216, 192);
}

.carousel__pagination-button--active{
   background-color: #0284b0;
  
}


.span_banner{
background-color : rgba(0,0,0,.4);
width: 100%;

}

.span_item {
font-size: 30px ; 
display:inline-flex;
align-items: center;
text-align: center;
align-content: center;

}

.linkcarrousel{
  text-decoration: none;
  color: white;
}
  .linkcarrousel:hover{
                      color: #cae8f1;
                      border-color: #cae0e8;
                  }


</style>