<template>
  <div class="login">
    <div class="logincontent">
      <span class="title"> Sign up </span>

      <form @submit.prevent="submit" class="formlogin1">
        <div class="rowline">
          <label for="firstname" class="rowlinelabel">Firstname:</label>
          <input type="text" name="firstname" v-model="form.firstname" placeholder="Firstname" required/>      
        </div>
         <div class="rowline">
          <label for="surname" class="rowlinelabel">Surname:</label>
          <input type="text" name="surname" v-model="form.surname" placeholder="Surname" required/>      
        </div>
         <div class="rowline">
          <label for="email" class="rowlinelabel">Email:</label>
          <input type="text" name="email" v-model="form.email" placeholder="Email" required/>
        </div>
         <div class="rowline">
          <label for="password" class="rowlinelabel">Password:</label>
          <input type="password" name="password" v-model="form.password" placeholder="Password" required />
        </div>
           <div class="rowline">
          <label for="password" class="rowlinelabel">Password:</label>
          <input type="password" name="conrfirmpassword" v-model="form.confirmpassword" placeholder="Confirm Password" required />
        </div>
        <button type="submit">Submit</button>
      </form>
      <p v-if="showError" id="error">Username or Password is incorrect</p>
    </div>
  </div>
</template>

<script>

import {createNamespacedHelpers} from 'vuex';

import axios from "axios";
import AxiosPlugin from 'vue-axios-cors';



const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('auth');


export default {
  name: "Register",
  components: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmpassword:"",
        firstname: "",
        surname:""
      },
      showError: false
    };
  },


  computed: {
  ...mapState(['webaddress1']),
},




  methods: {
    ...mapActions(["Register"]),
    async submit() {
      const User = new FormData();
      User.append("email", this.form.email);
      User.append("firstname", this.form.firstname);
      User.append("surname", this.form.surname);
      User.append("pwd", this.form.password);
      User.append("pwdRepeat", this.form.confirmpassword);
       const User2 = {submit: true , email: this.form.email, firstname: this.form.firstname,
       surname:this.form.surname, pwd:this.form.password,
       pwdrepeat:this.form.confirmpassword};
     
       //axios.post('http://localhost:8080/one/newsignup.php', User2).then((res) => {
        axios.post( this.webaddress1+'newsignup.php', User2).then((res) => {    
          console.log(res.data)
          })
   /*

      try {
         // await this.LogIn(User);
          axios.post('http://localhost:8087', User2).then((res) => {
          console.log(res.data)
          })
       //   this.$router.push("/posts");
          this.showError = false
      } catch (error) {
        this.showError = true
      }

      */
    },
  },
};
</script>

<style>


.login{
  display: flex;
  justify-content: center;
  padding: 60px 0;



}

.logincontent {
        display: flex;  
        width: 300px;
        padding: 40px;
        justify-content:center;
        flex-direction: column;
         border: 1px solid rgb(216, 216, 216);
         border-radius: 5px;
       
            
      }

.title{
             font-size: calc(1.375rem + 1.5vw);
            font-family: sans-serif;
            margin: 10px 0 20px 0;
            
}

.formlogin1{
margin:0px;
padding: 0px;;

}

.rowlinelabel {
  padding: 0;
  margin:0;
  
}

button[type="submit"] {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 0px 00px;
  width: 100%;
}
button[type="submit"]:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}
.rowline input {
  margin: 0 0 20px 0;
 /* box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06); */
  padding: 5px 5px;
 
   border: 1px solid rgb(216, 216, 216);
         border-radius: 0.5px;
 
}
#error {
  color: red;
}
</style>