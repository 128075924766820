import { getCurrentScope } from "vue";

const state = {

    countries: [

        {code:'DE', name: 'Germany' }, 
        {code:'AU', name: 'Australia' }, 
        {code:'AT', name: 'Austria' }, 
        {code:'BE', name: 'Belgium' }, 
        {code:'PL', name: 'Poland' }, 
        {code:'US', name: 'USA' }, 
        {code:'RU', name: 'Russia' }, 
        {code:'IN', name: 'India'}, 
        {code:'CH', name: 'Switzerland' }, 
        {code:'CN', name: 'China' }, 
        {code:'ES', name: 'Spain' }, 
        {code:'IT', name: 'Italy'}, 
        {code:'LU', name: 'Luxembourg' }, 
        {code:'FR', name: 'France' }, 
        {code:'BR', name: 'Brazil' }, 
        {code:'CA', name: 'Canada' }, 
        {code:'HK', name: 'Hong Kong' },  
        {code:'IN', name: 'India' }, 
        {code:'ID', name: 'Indonesia' }, 
        {code:'JP', name: 'Japan' }, 
        {code:'NL', name: 'Netherlands' }, 
        {code:'NZ', name: 'New Zealand' },  
        {code:'NO', name: 'Norway' }, 
        {code:'PT', name: 'Portugal' }, 
        {code:'RU', name: 'Russia' }, 
        {code:'ZA', name: 'South Africa'}, 
        {code:'SE', name: 'Sweden' }, 
        {code:'TR', name: 'Turkey' }, 
        {code:'GB', name: 'United Kingdom' }, 
        {code:'US', name: 'USA'}, 

        

],

 

    languages: [
        {code:'de', name: 'German' }, 
        {code:'en', name: 'English' }, 
        {code:'zh', name: 'Chinese' }, 
        {code:'nl', name: 'Dutch' }, 
        {code:'fr', name: 'French' }, 
        {code:'it', name: 'Italian' }, 
        {code:'ja', name: 'Japanese' }, 
        {code:'ru', name: 'Russian' }, 
        {code:'es', name: 'Spanish' }, 
        {code:'pt', name: 'Protuguese' }, 
        {code:'tr', name: 'Turkish' }, 
    ],
      

    currencies: [
        {code:'AUD', logo: '$', designation:'Australia Dollar', position: 0 }, 
        {code:'BRL', logo: 'R$',designation:'Brazil Real', position: 1  }, 
        {code:'EUR', logo: '€' , designation:'Euro', position: 1  }, 
        {code:'CAD', logo: '$' , designation:'Canada Dollar', position: 0 }, 
        {code:'USD', logo: '$' , designation:'United States Dollar', position: 0  }, 
        {code:'GBP', logo: '£', designation:'United Kingdom Pound', position: 0  }, 
        {code:'HKD', logo: '$', designation:'Hong Kong Dollar', position: 0  }, 
        {code:'CNY', logo: '¥' , designation:'China Yuan Renminbi', position: 1 }, 
        {code:'JPY', logo: '¥' , designation:'Japan Yen', position: 1 }, 
        {code:'INR', logo: 'INR', designation:'India Rupee', position: 1  }, 
        {code:'RUB', logo: '₽', designation:'Russia Ruble', position: 1  }, 
        {code:'TRL', logo: 'TRL', designation:'Turkey Lira', position: 1  }, 
        {code:'ZAR', logo: 'R', designation:'South Africa Rand', position: 0  }, 
        {code:'CHF', logo: 'CHF', designation:'Switzerland Franc', position: 1  }, 
        {code:'IDR', logo: 'Rp', designation:'Indonesian rupiah', position: 0  }, 
        {code:'NZD', logo: '$', designation:'New Zealand Dollar', position: 0  }, 
        {code:'NOK', logo: 'kr', designation:'Norwegian krone', position: 1  }, 
        {code:'SEK', logo: 'kr', designation:'Swedish krona', position: 1  }, 
     
    ],

    listdefaultcountryparameters: [
        {country:'DE', language: 'de' , currency : 'EUR'}, 
        {country:'AU', language: 'en' , currency : 'AUD'},
        {country:'AT', language: 'de', currency : 'EUR' }, 
        {country:'BE', language: 'fr', currency : 'EUR' }, 
        {country:'PL', language: 'en', currency : 'EUR' }, 
        {country:'CH', language: 'en', currency : 'CHF' }, 
        {country:'CN', language: 'zh', currency : 'CNY' }, 
        {country:'ES', language: 'es' , currency : 'EUR'}, 
        {country:'IT', language: 'it', currency : 'EUR'}, 
        {country:'LU', language: 'fr' , currency : 'EUR'}, 
        {country:'FR', language: 'fr' , currency : 'EUR'}, 
        {country:'BR', language: 'pt', currency : 'BRL' }, 
        {country:'CA', language: 'en', currency : 'CAD' }, 
        {country:'HK', language: 'en', currency : 'HKD' },  
        {country:'IN', language: 'en' , currency : 'INR'}, 
        {country:'ID', language: 'en' , currency : 'USD'}, 
        {country:'JP', language: 'ja' , currency : 'JPY'}, 
        {country:'NL', language: 'nl', currency : 'EUR' }, 
        {country:'NZ', language: 'en' , currency : 'NZD'},  
        {country:'NO', language: 'en' , currency : 'NOK'}, 
        {country:'PT', language: 'pt' , currency : 'EUR'}, 
        {country:'RU', language: 'ru' , currency : 'RUB'}, 
        {country:'ZA', language: 'en', currency : 'ZAR'}, 
        {country:'SE', language: 'en' , currency : 'SEK'}, 
        {country:'TR', language: 'tr' , currency : 'TRL'}, 
        {country:'GB', language: 'en' , currency : 'GBP'}, 
        {country:'US', language: 'en', currency : 'USD'}, 
    
],



 };
 
 const getters = {

        countries:  (state) => { return state.countries }, 
        languages:  (state) => { return state.languages },   
        currencies:  (state) => { return state.currencies },

        filtercurr: (state) => (curr) => {
            return state.currencies.find(item => item.code === curr)
          },

        currformat: (state) =>(curr) => {
         //  const cfmt= getters.filtercurr(curr);
            const cfmt=  state.currencies.find(item => item.code === curr)
            const currlogs={};
            if (cfmt.position==0) {currlogs.left=cfmt.logo; currlogs.right="" } else {currlogs.left=""; currlogs.right=cfmt.logo }
          return currlogs;
        //  return cfmt;

          },
       

 };
 






 const actions= {
   // defcurrency: (state) => (curr) =>{
    //    return state.currencies.find(o => o.code === curr);

      
  };
 

  const mutations = {
 
};
 export default {
     namespaced: true,
     state,
     getters,
     actions

 }
 