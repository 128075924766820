<template>
 <Assets />
  <form >
      <label> Email: </label>
      <input type="email" required v-model="email">
       <label> Password: </label>
       <input type="password" required v-model="password">
    <label> \note: </label>
        <input type="text" required v-model="note">
  </form>
   <p> Email : {{ email }}</p>
   <p> Password : {{ password }} </p>
   <p> Note : {{ note }} </p>
</template>

<script>
import Assets from './Assets.vue'
export default {
    name: 'Portfolio',
    components: {Assets},
    data() {
    return {
        email:'',
        password:'',
        note:''
    }
   }
}

</script>

<style>
form {
max-width: 420px;
margin:30px auto;
background: white;
text-align: left;
padding: 40px;
border-radius: 10px;
}
label{
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
input {
display: block;
padding: 10px 6px;
width: 100%;
box-sizing: border-box;
border: none;
border-bottom: 1px solid #ddd;
color: #5555;
}
</style>
