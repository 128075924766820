<template>
 <div class="assetslist">
  
      <p class="title" v-show="BSCat=='Assets'" > Add new asset </p> 
      <p class="title" v-show="BSCat=='Liabilities'" > Add new liability </p> 
 
   
      <div class="topsection">

          <!--
                 <p> Asset type : {{assetparameters.Type }} </p> 
         -->



    <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px;" > Asset type </p>   
 
                <select class="lecountryselect" name="country" v-show="BSCat=='Assets'" v-model="assetparameters.Type"   id="l_e_country" @change="updateassetcat">
                   
                   <option value="Cash">Cash</option>
                   <option value="Equity">Equity</option>
                   <option value="Bond">Bond</option>
                   <option value="Property">Property</option>  
                   <option value="Fund">Fund</option>   
    </select>

  

        <select class="lecountryselect" name="country" v-show="BSCat=='Liabilities'" v-model="assetparameters.Type"  id="l_e_country" @change="updateassetcat">
                  
                  <option value="Debt">Debt</option>
                  <option value="Mortgage">Mortgage</option>
             
   </select>

  


    
     </div>



    <div class="bottomsection">
       <!--

         -->
   

           <div class="inputline" v-if="defaultassetparameters.Designation.visible===true">
                    <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > {{defaultassetparameters.Designation.designation}} </p>   
                    </div>                        
                    <div class="div1input">
                         <Inputtext   :value="assetparameters['Designation']"  @returnevent="assetparameters['Designation']=$event"    />  
                   
                        </div>   
           </div> 
             <div class="inputline" v-if="defaultassetparameters.Value.visible===true">
                    <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > {{defaultassetparameters.Value.designation}}  </p>   
                    </div>                        
                    <div class="div1input">

                      <!--
                         <Inputnumber   :value="assetparameters['Value']"  symbol_left="€" symbol_right="" @returnevent="assetparameters['Value']=parseFloat($event)"    />  
                    
                      -->
                     
                      <input class="form-control form-input3" type="text" v-if="currencyformat.left"  v-model="currencyformat.left" disabled > 
                       <input class="form-control form-input2" type="text"  v-model="tempimput['Value']" @blur="inputblurr('Value')"  @focus="inputfocus('Value')" v-on:keyup.enter="inputblurr('Value')" >                     
                       <input class="form-control form-input3" type="text" v-if="currencyformat.right"   v-model="currencyformat.right " disabled > 
                   




                     </div>   
           </div> 


       <div class="inputline" v-if="defaultassetparameters.IR.visible===true">
                    <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > {{defaultassetparameters.IR.designation}}  </p>   
                    </div>                        
                    <div class="div1input">

                    <!--  
                      <span class="input-symbol-percentage">
                               <input class="inputamount" type="text" id="fname" name="fname"  :value="assetparameters['IR']*100"  @change="updateValue" >   
                        <Inputpercentage  :symbol_left="" :symbol_right=""   :value="100"  style="flex:1"  @newfooevent="assetparameters[name] = updateformat($event,paramformat[name])" />  
                          </span>

                      -->
                   <inputpercentage   :value="(assetparameters['IR']*100).toFixed(2)" symbol_left="" symbol_right="%"  @returnevent="assetparameters['IR']=$event"  />  
                    
                    
                       </div>   
           </div> 

            <div class="inputline"  v-if="defaultassetparameters.CG.visible===true">
                    <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > {{defaultassetparameters.CG.designation}}  </p>   
                    </div>                        
                    <div class="div1input">

                   <inputpercentage   :value="(assetparameters['CG']*100).toFixed(2)" symbol_left="" symbol_right="%"  @returnevent="assetparameters['CG']=$event"  />  
                    
                    
                       </div>   
           </div> 
       <div class="inputline" v-if="defaultassetparameters.Duration.visible===true">
                    <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" >{{defaultassetparameters.Duration.designation}}  </p>   
                    </div>                        
                    <div class="div1input">
                 <!--
                      <Inputnumber   :value="assetparameters['Duration']"  symbol_left="" symbol_right="years" @returnevent="assetparameters['Duration']=Number($event)"    />  
                    -->
                    
                     <input class="form-control form-input2" type="text"  v-model="assetparameters['Duration']"  >                     
                       <input class="form-control form-input3" type="text"   value="years" disabled > 
                   


                     </div>   
           </div>       
            <div class="inputline" v-if="defaultassetparameters.Nominal.visible===true">
                  <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" >  {{defaultassetparameters.Nominal.designation}}  </p>   
                    </div>                        
                    <div class="div1input">

                      <!--
                           <Inputnumber   :value="assetparameters['Nominal']"  symbol_left="€" symbol_right="" @returnevent="assetparameters['Nominal']=$event"    />  
                
                      -->
                      
                               
                      <input class="form-control form-input3" type="text"  v-if="currencyformat.left"   v-model="currencyformat.left" disabled > 
                       <input class="form-control form-input2" type="text"  v-model="tempimput['Nominal']" @blur="inputblurr('Nominal')"  @focus="inputfocus('Nominal')" v-on:keyup.enter="inputblurr('Nominal')" >                     
                       <input class="form-control form-input3" type="text"  v-if="currencyformat.right" v-model="currencyformat.right " disabled > 
                   


                     </div>   
           </div> 

            <div class="inputline" v-if="defaultassetparameters.Issue_date.visible===true">
                  <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > {{defaultassetparameters.Issue_date.designation}}  </p>   
                    </div>                        
                    <div class="div1input">


     <input class="inputdate" type="date"  format="DD/MM/YYYY" default=dateconv    v-model=dateconv    placeholder=dateconv  @change="updatedate"  />

                     </div>   
           </div> 





     </div>


     <div class="bottom2buttons">
    
 
         <div class="bottom2left">
           
           <button class="executionbutton" @click="$router.go(-1)"> 
               <fa class= "icon_button" icon="xmark"/>   
             Cancel</button>
        </div>
       

         <div class="bottom2right">
             <button class="executionbutton" @click="saveasset()">
               <fa class= "icon_button" icon="circle-check"/>   
               Save
                  </button> 
     </div>
 




     </div>



 </div>

</template>

<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'
import Inputnumber from '../components/Inputnumber.vue'
import Inputtext from '../components/Inputtext.vue'
import Inputpercentage from '../components/Inputpercentage.vue'
import moment from 'moment'

//const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets');

const assets = createNamespacedHelpers('assets');
const auth = createNamespacedHelpers('auth');

export default {
 name: 'AddNewAsset',
 props:{Type:{type: String, default: "Cash" }, BSCat:{type: String}},
 components: { Inputnumber, Inputtext,Inputpercentage },
   data() { 
    return {assetparameters:{}, defaultassetparameters:{} , dateconv : new Date().toISOString().substr(0, 10),
            tempimput:{Value:"", Nominal:""}  
           } 
          } ,

 
    computed : {
         //  ...mapState({paramformats: state=>state.paramformat}),
         ...assets.mapState(['paramformat']),
         ...auth.mapState(['user']),

          ...assets.mapGetters(['newid']),
          ...assets.mapGetters(['defaultassetparams']), 
          ...auth.mapGetters(['currencyformat']),
          
          updatedate () {
       this.assetparameters["Issue_date"]=this.dateconv;}
  
       },
     methods : {
    //  ...assets.mapMutations(['ADD_ASSET']),
      ...assets.mapActions(['addnewasset']),
     

      updateformat (value, format) {
    //    if (format!=="T" && value!=null){this.$emit('newfooevent', parseFloat(value).toFixed(this.paramformat))}
    // return "AA";
     var tvalue;
     if (format!=="T" && value!=null) {tvalue= parseFloat(parseFloat(value).toFixed(format))} else if (value!=null) {tvalue = value} ;
     return tvalue;
    },


    updateassetcat(){
      this.defaultassetparameters = Object.assign({}, this.defaultassetparams(this.assetparameters.Type))

     },

     numberWithCommas(x) {
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },

        Commastonumber(x) {
           const parts = x.toString().replace(/,/g, '');  
         
          return parseFloat(parts).toFixed(2);
          
        },
       
     inputblurr(val){
      this.tempimput[val]=this.numberWithCommas(this.tempimput[val]);
       },
         inputfocus(val){
          this.tempimput[val]=this.Commastonumber(this.tempimput[val]);
       },

      async saveasset(){

        this.assetparameters.Value= parseFloat(this.Commastonumber(this.tempimput.Value));
        this.assetparameters.Nominal= parseFloat(this.Commastonumber(this.tempimput.Nominal));
       // this.assetparameters.Nominal= parseFloat(this.Commastonumber(this.tempimput.Nominal));

       // const inputfornewaddition= Object.assign(this.assetparameters, {ClientID:this.user.Email });

       const inputfornewaddition= {parameters:this.assetparameters, ClientID:this.user.Email};


        const result =  await this.addnewasset(inputfornewaddition);
   

        this.$router.push({ name: 'Catdetails', params: { ID: this.assetparameters.Type, BSCat: this.BSCat} })

        console.log(inputfornewaddition);

     },
    },

     created() {
    
      this.defaultassetparameters = Object.assign({}, this.defaultassetparams(this.Type));
     // this.defaultassetparameters = Object.assign({}, this.defaultassetparams(this.Type));
      console.log( this.Type);
     console.log(    this.defaultassetparams("Equity"));
      this.assetparameters  = {};
      
   //   console.log(  "defaultassetparameters : "   +  this.defaultassetparameters );

      for (var key in this.defaultassetparameters) {
        console.log(key);
;       this.assetparameters[key] = (this.defaultassetparameters[key]["value"]);

        //      Object.assign(this.assetparameters, {key:this.defaultassetparameters[key].value});
      //  console.log(key + " : "   + this.assetparameters[key] );
      };


    

      // this.assetparameters = Object.assign({}, this.defaultassetparameters );
      this.assetparameters.ID = this.newid;

      this.tempimput.Value=this.numberWithCommas(this.assetparameters.Value);
      this.tempimput.Nominal=this.numberWithCommas(this.assetparameters.Nominal);

      
     },
    
    
}

 
</script>
<style scoped>
div .assetslist {
 
display:flex;
flex-direction: column;
max-width: 420px;

margin:10px auto;
background: white;
text-align: left;
padding: 20px  0 0 0;
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
}



div .topsection {

margin-top: 10px;
display:flex;
/* justify-content: flex-end; 
*/
height: 50px;
border:1px solid rgb(204, 204, 211);


justify-content: space-between;

}

div .bottom2buttons {
display :flex;
justify-content:space-between;
align-items: center;
margin-top: 10px ;
  gap: 10px;

}

div .bottom2buttons.bottom2left {
display :flex;
flex:1;
max-height: 50px;
align-self: flex-start;



}
.bottom2buttons.bottom2right {
display :flex;
flex:1;
max-height: 50px;
align-self: flex-end;


}


.bottom2buttons.bottom2center {
display :flex;
flex:1;
max-height: 50px;


}

.bottomsection{
   display:flex;
   flex-direction: column;
justify-content: space-between;
align-items: flex-start;
width:100%;
 }

div .inputline{
display:flex;
justify-content: space-between;
align-items: flex-start;
width:100%;

margin:10px 0 10px 0 ;
background: white;
padding: 0px;
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
}

div .div1label {
display:flex;
flex:1;

}

div .div1input {
display:flex;

flex:1;

}




.inputamount {

    max-width: 100%;
   font-size: 17px;
    height: 45px;
    text-rendering: auto;

    border: 1px solid #777;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    

    padding: 0 10px 0 20px;
    color: #999;
    background: white;

}


.input-symbol-percentage {
    position: relative;
}
.input-symbol-percentage input {
    padding-left:18px;
}
.input-symbol-percentage:before {
    position: absolute;
    content:"%";
    right: 5px;
}


.inputdate {

    max-width: 100%;
   font-size: 17px;
    height: 45px;
    text-rendering: auto;

    border: 1px solid #777;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    

    padding: 0 10px 0 20px;
    color: #999;
    background: white;

}



.executionbutton {
  background-color: #0d6efd;
  color: white;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 10px 00px;
  height: 45px;
   width: 130px;
 justify-content: center;
    align-items: center;
}
.executionbutton:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}



.routerlink{
  align-self: right;
  margin-right: 10px;
}
 

.title{
     padding-left: 7px;
     font-size: 22px;
     font-weight: bold;
     color: #285fb3;
}


.lecountryselect{
 
  width:200px;
 height: 35px;
 align-self: center;
 margin-right:10px;
 
}



.form-label2{
    align-self: flex-start;
      text-align: left;
}

.form-control.form-input2{
    flex:3;
    text-align: center;
}
.form-control.form-input3{
    flex:1;
    text-align: center;
    border:none;

}


.form-control.form-input3:disabled{
    background-color: inherit;

}

</style>