<template>

   <!--     
   <div class="div2input">


  

    <input class="inputparam" type="text" id="fname" name="fname"  :value=value  @change="updateValue" >

  <p> {{symbol_left}}</p>
  <input class="inputparam" type="text" id="fname" name="fname"  :value=value   >
   <p> {{symbol_right}}</p>
 --> 


<div class="flexinputnumber2">
      <span class="input_left">{{symbol_left}}</span>
      <!--
      <input class="inputcurrency" id="amount" name="amount" v-model=displayvalue  @blur="handleBlur"  @keyup="handleBlur" maxlength="15" @change="updateValue" >
     
   

      <input class="inputcurrency2" type="text" id="amount" name="amount" v-model=displayvalue  @change="updateValue"    onkeypress="this.style.width = ((this.value.length + 1) * 8) + 'px';">
  


  <input class="inputcurrency2" type="text" id="amount" name="amount" v-model=displayvalue  @change="updateValue"   onkeypress="this.style.width = (Math.max(150,(this.value.length + 1) * 8)) + 'px';">
  
     --> 
       
    


<!-- :data-position="position"  -->

  <input ref="amount"  class="inputcurrency2" type="text" id="amount" name="amount"  v-on:input="handleInput "  v-model="displayvalue" :data-start="positionEnd"  v-on:change="updateValue, handleBlur" v-on:keyup="handleBlur "   onkeypress="this.style.width = (Math.min(Math.max(100,(this.value.length + 5) * 8),200) )+ 'px'; this.updateposition" v-on:click="updatepositionclick"  v-on:compositionupdate="positioncursor"  v-format-with-comma>
  
      <span class="input_right">{{symbol_right}}</span>
    </div>



  


</template>

<script>
import { ref } from 'vue'



export default {
 name: 'Inputnumber',
//  props:{paramname:{type:String, default: "cash"}, paramvalue:{type: String, default: "0.00"} },


     props: {
       value: {type:String, default: "0"}, 
       symbol_left: {type:String, default: "aa"}, 
       symbol_right: {type:String, default: "bb"},
       digits : {type:Number, default: 2}, 
        },

/*
       setup(props) {
       const displayvalue = props.value;
         return displayvalue ;
        },
      */

     data() {
       return {calculatedvalue:"", displayvalue:"0", position:3, positionEnd:0, positionStart:0, prevValue: "",}
     },
       

 directives: {
    formatWithComma: {
      update(e) {
        let positionDiff = 0;
        if (e.dataset.prevValue.length === (e.value.length - 1)) {
          positionDiff = 1;
        }
        if (e.dataset.prevValue.length === (e.value.length + 1)) {
          positionDiff = -1;
        }
        if (e.selectionEnd !== e.dataset.position) {
          e.selectionEnd = Number(e.dataset.position) + positionDiff;
          //    e.selectionEnd = e.dataset.position;

        }
      }
    }
  },



  


      computed : {
       
    handleBlur(){        
            //  this.value=this.commaSeparateNumber(this.displayvalue);
           
           /*
             const inputa = document.getElementById('amount');


  const input = document.getElementById('input');
  const position = input.selectionStart;

           */

         //   const inputa =  this.$refs["amount"];
         
            //  const oldposition=this.position;
           //   console.log(e.target.selectionStart);
            //  console.log(this.$refs["amount"]);


               this.displayvalue=this.commaSeparateNumber(this.converttoNumber(String(this.displayvalue)));
              ////  this.displayvalue="xxx" 
            // this.positionStart=e.target.selectionStart;
             //  this.positionEnd=e.target.selectionEnd;
  //  this.displayvalue=this.commaSeparateNumber("23423452453");
               // inputa.selectionStart= position;
           //    if (inputa.selectionEnd !== this.position) {
            //          inputa.selectionEnd = this.position;
             //       }
              // inputa.target.selectionEnd = this.position;
          //  this.position=6;
        //    this.cursorposition();
               
  /*
    */

            },

       },
       

     methods : {



      updateposition(e)
     {
     //   console.log ("Update position : ");
     //   console.log ("position selection start");
     //   console.log( e.target.selectionStart);

    //  e.target.selectionStart=this.positionstart;
     //   e.target.selectionStart=this.positionStart;
      //  e.target.selectionEnd=this.positionEnd;

      console.log ("updateposition : ");
     // console.log( e.target.selectionStart);
     // console.log( e.target.selectionEnd);
       //    this.positionStart=  e.target.selectionStart;
         //  this.positionEnd= e.target.selectionEnd;

            //   e.target.selectionStart=this.positionstart;
         //     console.log ("position end");
           //   console.log( e.target.selectionEnd);

     }, 



/*

     handleBlur(e) {
        
               this.displayvalue=this.commaSeparateNumber(this.converttoNumber(String(this.displayvalue)));

            },

*/

 handleBlur2(e){        
   
               this.displayvalue=this.commaSeparateNumber(this.converttoNumber(String(this.displayvalue)));
           
            },





            handlekeyup (e) {
              
                //  this.positionStart=e.target.selectionStart;
                // this.positionEnd=e.target.selectionEnd;
               
                this.value=this.converttoNumber(String(this.displayvalue));
              
             
           //      this.updateposition(e);

            //       this.displayvalue=this.commaSeparateNumber("23423452453");
            },







      updateValue (e) {
    //  this.displayvalue=String(parseFloat(this.displayvalue).toFixed(this.digits));
       this.handleBlur;
      // this.$emit('returnevent', parseFloat(this.displayvalue).toFixed(this.digits)) ;
      
    this.$emit('returnevent', this.converttoNumber(this.displayvalue)) ;
    
         
     //    this.updateposition(e);

    },

/*
   

*/
  

    handleInput(e) {
  //     console.log("handleInput:");
     this.positionStart=e.target.selectionStart;
      this.positionEnd=e.target.selectionEnd;
    //  console.log(e.target.selectionStart);
      this.updateposition(e);
    },

      

     updatepositionclick(e)
     {
     //   console.log ("Update positionclick : ");
      //  console.log ("positionstart");
      //  console.log (this.positionStart);
       //    console.log ("positionEnd");
      //  console.log (this.positionEnd);
      //  console.log ("position selection start before update");
      //  console.log( e.target.selectionStart);
        e.target.selectionStart=this.positionStart;
        e.target.selectionEnd=this.positionEnd;
        // console.log ("position selection start after update");
       //  console.log( e.target.selectionStart);

    //  e.target.selectionStart=this.positionstart;

      //  e.target.selectionEnd=e.target.selectionStart;
       // e.target.selectionEnd=this.positionstart;
            //   e.target.selectionStart=this.positionstart;
          //    console.log ("position end");
          //    console.log( e.target.selectionEnd);

     }, 


   positioncursor(e) {
        
        //    console.log(e.selectionStart);
           console.log("position cursor");
           
            },






     commaSeparateNumber(val){
//            const atext=val.replace(/,/, "");

            const atext=val.replace(",", "");
      ///      const aval=parseFloat(atext);

    //        const valueString = val.replace(/\D/g, "");
         //    const aval=parseFloat(atext).toFixed(2);
         return String(atext.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
     //       const aval=parseFloat(atext);
    //return     aval.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

       //  var amount=parseFloat(valueString).toFixed(2);
       // var amount=1087800.22;
       //    var formattedString= amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


     //return formattedString;
 
      },

      converttoNumber(val){
   
         const valueString = val.replaceAll(",", "");
       // const valueString = val.replace(/[^\d\.]/g,"");
        
         const amount=parseFloat(valueString).toFixed(this.digits);
       //
      // var amount=100;
     return amount
  
      },
  
     
   


         },





           created() {
         //  this.displayvalue=String(this.value);
           this.displayvalue=this.commaSeparateNumber(String(this.value));
           this.handleBlur2;

         //    console.log(this.$refs.amount);
   
     },

     


}

 
</script>

<style scoped>

.flexinputnumber2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  border-right: 0;
  line-height: 2.5;

}

.inputcurrency2 {
  display: inline-flex;
  font-size: 17px;

  color: #999;
  background: none;
  height: 45px;
  
 /* flex: 1 1 300px; */
  border: none;
}


.flexinputnumber2 .inputcurrency2 :focus {
  outline: 0;
}
.flexinputnumber2 .inputcurrency2 .error {
  border-color: #ff0000;  
}



.flexinputnumber2 .input_left {
  display: flex;
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
  width: fit-content;

}

.flexinputnumber2 .input_right {
 display: flex;
  font-size: 17px;
  padding: 0 20px 0 10px;
  color: #999;
  width: fit-content;

}


</style>