<template>
 <div class="assetslist">

  <p class="title"> Wealth projection  </p>
  
  <div  class="topsection">
     <p style="margin-top:10px">  <i class="fas fa-circle"  ></i> Your current wealth is 
     <span style="font-weight: bold; margin-left:5px">   
      {{formatPrice((TotalBSProj.W[0]).toFixed(2))}}
    </span> 
  </p> 
 <p style="margin-top:10px"> <i class="fas fa-circle"  ></i>  Your wealth projected at year {{pyear}} is 
     <span style="font-weight: bold; margin-left:5px"> 
     
      {{formatPrice((TotalBSProj.W[Object.keys(TotalBSProj.W).length-1]).toFixed(2))}}
    
    </span>
    </p> 
  
    <p style="margin-top:10px">  <i class="fas fa-circle"  ></i> 
       Your monthly retirement annuity will be 
          <span style="font-weight: bold; margin-left:5px"> {{formatPrice(differredincome)}}  </span> 
    </p> 

  </div>

   


    <div class="midsection">
       <!--

         -->
   
           <div class="midtopline">
                    
            
            
                    <div class="newclass"> 
                        <label class="form-label2" for="customRange3">Projection Period : </label>  

                            <div class="container-params">
                                              
                                              <div class="child-params1"> 

                                                 
                                                            <input class="form-control form-input2" type="number"   v-model="pyear" min="1" max="100"    @input="checkpyear()">   
                                                          <input class="form-control form-input3" type="text"  placeholder="years" disabled > 
                    
                                                    
                                              </div>
                                                
                                                  <div class="child-params2">    
                                                          <input type="range" class="customrange" min="1" max="100" step="1" v-model="pyear" > 
                                                  </div>       
                            </div> 

                        <hr class="new1"> 
                        </div> 
            
            
            
            
            
            
            <!--

            <div class="div1label">
                          <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > Projection period : </p>   
                    </div>    
              -->  

                   
                     <div class="div1label2">
                          <button class="executionbutton"  @click="Project_TotalBS(this.YC,Number(this.pyear)), getretincome() "> Update </button>

                    </div>  
           </div> 
           
            <div class="inputline">

                   <div class="divchart" v-if="chartvisible">
                   
                    
                          <Linechart2  :assetval="Object.entries(TotalBSProj.A)"  :liabval="Object.entries(TotalBSProj.L)" :wealth="Object.entries(TotalBSProj.W)"/>
   <!-- 
                     -->


                    </div> 

                    <!--         
                  <div class="div1label">

                     <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > Results of the projections </p>   
                 
                   
                    </div>                        
                    <div class="div2input">
          

                      {{projections}} 
                      <table v-if="Object.keys(this.projections).length > 0" class="table table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>Year</th>
                                <th>Value</th>
                                <th>Cashg</th>
                                 <th>Capg</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="index in Number(pyear)" :key="index">
                                <td>{{index}}</td>
                              <td>{{projections.Value[index]}}</td>
                                    <td>{{projections.CapGrowth[index]}}</td>
                                <td>{{projections.Cash_outflow[index]}}</td>
                      

                            </tr>
                        </tbody>
                    </table> 



                     </div>  -->  

                  
           </div> 





     </div>

     <div class="divportfolioview">
    <router-link class="routerlink" :to="{ name: 'Portfolioview' }">Portfolio summary</router-link> 
    </div>

     <div class="bottom2buttons">
         <div class="bottom2left">
       <!--       
           <button class="executionbutton" @click="$router.go(-1)">Cancel</button>
        </div>
        <div class="bottom2center">
        
           <button class="executionbutton"  @click="ProjectBond(this.assetbond, this.YC, 5)">Run Projections</button>



              <button class="executionbutton"  @click="Project_Mortgage(this.assetslist[8],this.YC,Number(this.pyear))">Run Projections</button>

-->
<!--

 <button class="executionbutton"  @click="Project_TotalBS(this.YC,Number(this.pyear))">Run Projections</button>


      



         </div>
         <div class="bottom2right">
             <button class="executionbutton"  @click="$router.go(-1)">
               Save
                </button>
                 --> 
         </div>
        
     </div>



 </div>

</template>

<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'
// import Modal from "../comps/Modal.vue"
// import Inputparams from './Inputparams.vue'
import Inputnumber from '../components/Inputnumber.vue'
import Inputtext from '../components/Inputtext.vue'
import Inputpercentage from '../components/Inputpercentage.vue'
import moment from 'moment'

import axios from "axios";

import LineChart from '../components/charts/Linechart.vue'

import Linechart2 from '../components/charts/Linechart2.vue'


const assets = createNamespacedHelpers('assets');
const auth = createNamespacedHelpers('auth');

export default {
 name: 'BSprojection',
 props:{ID:{type: Number}},
 components: { Inputnumber, Inputtext,Inputpercentage, LineChart, Linechart2},
   data() { 
    return {pyear:10, projections:[], assetparameters:{}, defaultassetparameters:{}, dateconv : new Date().toISOString().substr(0, 10),
           YC:[0.011,0.011, 0.011, 0.011, 0.011,0.011,0.011,0.011,0.011,0.011],
            assetbond:  {
            ID:2,
            BS:'A', 
            Type:"Bond",
            Designation: "Bond company A",
            Value:0,
                            IR:0.1,
                            CG:0.1,
                            Duration:10,
                            Nominal:1,
            Issue_date:"2010-09-09",
           },
           TotalBSProj:{A:[0],L:[0], W:[0]},
           chartvisible:false,
           differredincome:0,
             
           } 
          } ,

 
    computed : {
         //  ...mapState({paramformats: state=>state.paramformat}),
        ...assets.mapState({  assetslist: state=>state.assetslist1}),
        
         ...assets.mapState(['paramformat']),

          ...assets.mapGetters(['assetparams','defaultassetparams']),
          ...auth.mapState(['regionalpreferences', 'session','user','webaddress1']),
          ...auth.mapGetters(['currencyformat']),


      
       },


     methods : {
  
   
     checkpyear(){
      if(this.pyear > 100 ) { this.pyear = 100 };
      if(this.pyear < 1 ) { this.pyear= "" };
     },


      formatPrice(value) {
                 
                 //  var   val= this.currencyformat.left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currencyformat.right;              
                   var   val= this.currencyformat.left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currencyformat.right;              
     
                   return val;
                           },
       

        updateformat (value, format) {
        //    if (format!=="T" && value!=null){this.$emit('newfooevent', parseFloat(value).toFixed(this.paramformat))}
        // return "AA";
        var tvalue;
        if (format!=="T" && value!=null) {tvalue= parseFloat(parseFloat(value).toFixed(format))} else if (value!=null) {tvalue = value} ;
        return tvalue;
        },
     
         projectasset(Nominal,ir, N) {
            
            var tvalue={value:10, Cashg:5, Capg:0};
            var nproj = [];
           for (let i = 0; i < 10; i++) {
                tvalue={value:10, Cashg:ir*Nominal, Capg:0};
                if (i==Mat){tvalue.Cashg+=Nominal; Nominal=0 }
                nproj.push({year:i+1, proj:tvalue});
                };
           this.projections = Object.assign({}, nproj); 
              
            },

        //  CREATION OF FUNCTIONS TO PROJECT ASSET CLASSES
        // THIS SECTION WILL HAVE TO BE MOVED INTO A SEPARATE FILE

        Project_Bond(asset, yc, N){
 
       //   var asset=this.assetbond;
       //   var yc=this.YC;
       //   var N=6;

           var projasset= {
            ID:asset.ID,
            BS: asset.BS, 
            Type: asset.Type,
            Designation: asset.Designation,
            Value:parseFloat(asset.Value),
                            IR:parseFloat(asset.IR),
                            CG:parseFloat(asset.CG0),
                            Duration:parseFloat(asset.Duration),
                            Nominal:parseFloat(asset.Nominal),
            Issue_date:asset.Issue_date,
            ValueP:[],
            CapGrowth: [],
            Cash_outflow:[]
            };


          // I need to perform projection till maturity of asset in order to be able to calculate prices
          
          // define an intermediate variable for bond price calculations calculations
         

          var bond= {value : [], cash:[], capg:[]};
          var M=asset.Duration;  
            bond.cash[0]=0;
            for (let i = 1; i <= M; i++) {
                bond.cash[i]=parseFloat(asset.Nominal)*parseFloat(asset.IR);             
                };
           bond.cash[M]+=parseFloat(asset.Nominal);

           bond.value[M]=0;
            for (let i = M-1; i >=0; i--) {
                bond.value[i]= (bond.value[i+1]+bond.cash[i+1])/(1+yc[i]);
                };
            
            // Perfrorm adjustment

            
            if (bond.value!=0 ){
             for (let i = M-1; i >= 0; i--) {
                bond.value[i]=bond.value[i]*parseFloat(asset.Value)/bond.value[0];             
                }};

          

            if (M<=N){

              for (let i = 0; i <=M ; i++) {
               projasset.ValueP[i] = bond.value [i];
               projasset.Cash_outflow[i] = bond.cash[i];
                      
                };  
              
             
             for (let i = M+1; i <=N ; i++) {
                projasset.ValueP[i] = 0 ;
                projasset.Cash_outflow[i] = 0;
                      
                };  
            };

             if (M>N){
           
             for (let i = 0; i <=N ; i++) {
                projasset.ValueP[i] = bond.value[i] ;
                projasset.Cash_outflow[i] = bond.cash[i];           
                };  
            };

             projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0)); ;

         // this.projections = Object.assign({}, projasset); 
         
         return projasset;

        },

        
  
       

        Project_Cash(asset, yc, N){

  console.log("asset :  in cahs projection");
  console.log(asset );



                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value: parseFloat(asset.Value),
                      IR: parseFloat(asset.IR),
                      CG: parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };
                    
                      console.log(asset.IR);
                      console.log("cash projection  : "); 
                      console.log(projasset);

                    var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=parseFloat(asset.Value);
                    projasset.Cash_outflow[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1]  ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR)); 
                          };  
                     

                      projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0));
               
             // this.projections = Object.assign({}, projasset); 
         
         return projasset;
                
        },
        



        Project_Equity(asset, yc, N){
                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value:parseFloat(asset.Value),
                      IR:parseFloat(asset.IR),
                      CG:parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };


                 //   var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=asset.Value;
                    projasset.Cash_outflow[0]=0;
                    projasset.CapGrowth[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1] * (1+parseFloat(asset.CG)) ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR));
                          projasset.CapGrowth[i] = projasset.ValueP[i-1] * parseFloat((asset.CG));                         
                          };  
                     
               
                   // this.projections = Object.assign({}, projasset); 
                       
         return projasset;
                
        },
        
        Project_Property(asset, yc, N){
                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value:parseFloat(asset.Value),
                      IR:parseFloat(asset.IR),
                      CG:parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };


                    var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=parseFloat(asset.Value);
                    projasset.Cash_outflow[0]=0;
                    projasset.CapGrowth[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1] * (1+parseFloat(asset.CG)) ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR));
                          projasset.CapGrowth[i] = projasset.ValueP[i-1] * parseFloat(asset.CG);                         
                          };  
                     
               
                  // this.projections = Object.assign({}, projasset); 


                  
         return projasset;
                
        },





        Project_Fund(asset, yc, N){
                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value:parseFloat(asset.Value),
                      IR:parseFloat(asset.IR),
                      CG:parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };


                 //   var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=asset.Value;
                    projasset.Cash_outflow[0]=0;
                    projasset.CapGrowth[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1] * (1+parseFloat(asset.CG)) ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR));
                          projasset.CapGrowth[i] = projasset.ValueP[i-1] * parseFloat((asset.CG));                         
                          };  
                     
               
                   // this.projections = Object.assign({}, projasset); 
                       
         return projasset;
                
        },

        /*
        ***  LOAN AND MORTAGE

        */
        
        Project_Debt(asset, yc, N){
 
                          var projasset= {
                            ID:asset.ID,
                            BS: asset.BS, 
                            Type: asset.Type,
                            Designation: asset.Designation,
                            Value:parseFloat(asset.Value),
                            IR:parseFloat(asset.IR),
                            CG:parseFloat(asset.CG0),
                            Duration:parseFloat(asset.Duration),
                            Nominal:parseFloat(asset.Nominal),
                            Issue_date:asset.Issue_date,
                            ValueP:[],
                            CapGrowth: [],
                            Cash_outflow:[]
                            };


                     // calculate the monthly paymenbt
                     var M=asset.Duration;  

                     var ia = asset.IR;
                     var im = (1+ia)**(1/12)-1;
                     var am = 1/(1+im);
                     var D = (1-am**(asset.Duration*12+1))/(1-am);
                     var MP=asset.Value/ (D-1);  // mmontly payment


                      var bond= {value : [], cash:[], capg:[]};
                      bond.value[0]= parseFloat(asset.Value);
                      bond.cash[0]=0;
                      bond.capg[0]=0;
                     
                      for (let i = 1; i <= M; i++) {
                                bond.value[i]=bond.value[i-1]+bond.value[i-1]*parseFloat(asset.IR)-MP*12; // this is a simplified calculation as I am displaying annual values, later should perform accurate calcualtions based on monthly payments
                                bond.cash[i]=-MP*12; 
                                };
                       

                     

                            if (M<=N){

                              for (let i = 0; i <=M ; i++) {
                              projasset.ValueP[i] = bond.value [i];
                              projasset.Cash_outflow[i] = bond.cash[i];
                                      
                                };  
                              
                            
                            for (let i = M+1; i <=N ; i++) {
                                projasset.ValueP[i] = 0 ;
                                projasset.Cash_outflow[i] = 0;
                                      
                                };  
                            };

                            if (M>N){
                          
                            for (let i = 0; i <=N ; i++) {
                                projasset.ValueP[i] = bond.value[i] ;
                                projasset.Cash_outflow[i] = bond.cash[i];           
                                };  
                            };

                            projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0)); 

                         // this.projections = Object.assign({}, projasset); 

                   //      console.log(bond);

                     //    console.log(projasset);
                  return projasset;
        },

        
        
        Project_Mortgage(asset, yc, N){
                          
                          var projasset= {
                            ID:asset.ID,
                            BS: asset.BS, 
                            Type: asset.Type,
                            Designation: asset.Designation,
                            Value:parseFloat(asset.Value),
                            IR:parseFloat(asset.IR),
                            CG:parseFloat(asset.CG0),
                            Duration:parseFloat(asset.Duration),
                            Nominal:parseFloat(asset.Nominal),
                            Issue_date:asset.Issue_date,
                            ValueP:[],
                            CapGrowth: [],
                            Cash_outflow:[]
                            };


                     // calculate the monthly paymenbt
                     var M=asset.Duration;  

                     var ia = asset.IR;
                     var im = (1+ia)**(1/12)-1;
                     var am = 1/(1+im);
                     var D = (1-am**(asset.Duration*12+1))/(1-am);
                     var MP=asset.Value/ (D-1);  // mmontly payment


                      var bond= {value : [], cash:[], capg:[]};
                      bond.value[0]= parseFloat(asset.Value);
                      bond.cash[0]=0;
                      bond.capg[0]=0;
                     
                      for (let i = 1; i <= M; i++) {
                                bond.value[i]=bond.value[i-1]+bond.value[i-1]*parseFloat(asset.IR)-MP*12; // this is a simplified calculation as I am displaying annual values, later should perform accurate calcualtions based on monthly payments
                                bond.cash[i]=-MP*12; 
                                };
                       

                     

                            if (M<=N){

                              for (let i = 0; i <=M ; i++) {
                              projasset.ValueP[i] = bond.value [i];
                              projasset.Cash_outflow[i] = bond.cash[i];
                                      
                                };  
                              
                            
                            for (let i = M+1; i <=N ; i++) {
                                projasset.ValueP[i] = 0 ;
                                projasset.Cash_outflow[i] = 0;
                                      
                                };  
                            };

                            if (M>N){
                          
                            for (let i = 0; i <=N ; i++) {
                                projasset.ValueP[i] = bond.value[i] ;
                                projasset.Cash_outflow[i] = bond.cash[i];           
                                };  
                            };

                            projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0)); 

                         // this.projections = Object.assign({}, projasset); 

                     
                  return projasset;
        },




       //  ***************
       

         Project_TotalBS(yc, N){ 
          // Project_TotalBS(ALlist, yc, N){ 
         

       
         var CBproj =  Object.assign({}, Array(N+1).fill(0)); 
         var Aproj=Object.assign({}, Array(N+1).fill(0)); 
         var LiabProj= Object.assign({}, Array(N+1).fill(0)); 
         var WealthProj= Object.assign({}, Array(N+1).fill(0)); 

         var dcashi=0;  // this is the default chase increase
         
         // filter through all cash types of assets and check wich one is the default cash.
         //dcashi=asset.IR;
         
         var assetpj = [];
         var nbitem=0;
       
         // FILTER FOR CASH

         console.log(this.assetslist);
         var newarray= this.assetslist.filter(pilot => pilot.Type === "Cash")  ; 
       
        // console.log(newarray) ;
        // console.log(this.assetslist) ;


         dcashi=newarray[0].IR;

       
        

      //   nbitem= newarray.length;
          nbitem= Object.keys(newarray).length;
          console.log("nbitem :  "  + nbitem);
       console.log("dcashi");
       console.log(dcashi);


   //    console.log("START ZERO  :");
     // console.log( CBproj);


         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                         //     console.log("ia  "  ) ;
                          //      console.log(ia    ) ;
                             assetpj =  Object.assign({}, this.Project_Cash(newarray[ia], yc, N)); 
                             console.log("newarray[ia]");
                             console.log(newarray[ia]) ;
                             //     console.log(assetpj.Cash_outflow) ;
                         //   console.log(assetpj.Cash_outflow[5]) ;
                          //   console.log(assetpj.ValueP) ;

                               for (let y = 0; y <=N ; y++) {
                                  
                                CBproj[y] += assetpj.Cash_outflow[y];
                              //  console.log("Cashoutflo : " + assetpj.Cash_outflow[y]) ;
                              
                                Aproj[y] += assetpj.ValueP[y];                    
                                };  

                                 console.log("Cash :");
                                  console.log( CBproj);

                             //   console.log(CBproj) ;
         } }
   
          

      
          // FILTER FOR PROPERTY


          Object.keys(newarray).forEach(key => newarray[key] = null);

        //  console.log(this.assetslist);

   
         newarray = Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Property") ) ;  
      
         
        // var newarray= this.assetslist.filter(pilot => pilot.Type === "Cash")  ; 


        nbitem= Object.keys(newarray).length;
           


        


         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Property(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                               CBproj[y] += assetpj.Cash_outflow[y];
                               Aproj[y] += assetpj.ValueP[y];                    
                                };  
                        //   console.log("Property :" );
                       //     console.log(CBproj);
         } }
        

           // FILTER FOR Bond
        
         newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Bond") ) ;  
      
         nbitem= Object.keys(newarray).length;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Bond(newarray[ia], yc, N)); 


                                console.log("Bond Cash:" );
                                 console.log(assetpj.Cash_outflow);
                                console.log("Bond Value:" );
                                 console.log(assetpj.ValueP);

                               for (let y = 0; y <=N ; y++) {
                               CBproj[y] += assetpj.Cash_outflow[y];
                               Aproj[y] += assetpj.ValueP[y];                    
                                };  
                                console.log("Bond :" );
                                 console.log(CBproj);
         } }
         
          // FILTER FOR EQUITY
     
    
        newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Equity") ) ;  
      
         nbitem= Object.keys(newarray).length;

        // console.log(newarray) ;
          //console.log(nbitem) ;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Equity(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                                CBproj[y] += assetpj.Cash_outflow[y];
                                Aproj[y] += assetpj.ValueP[y];                    
                                };  
                      //          console.log("Equity :" );
                      //           console.log(CBproj);
         } }
         

            // FILTER FOR FUND
                
                
            newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Fund") ) ;  
                  
                  nbitem= Object.keys(newarray).length;

                // console.log(newarray) ;
                  //console.log(nbitem) ;

                  if (nbitem) {
                      for (let ia = 0; ia <nbitem ; ia++) {
                                      
                                      assetpj =  Object.assign({}, this.Project_Equity(newarray[ia], yc, N)); 

                                        for (let y = 0; y <=N ; y++) {
                                        CBproj[y] += assetpj.Cash_outflow[y];
                                        Aproj[y] += assetpj.ValueP[y];                    
                                        };  
                              //          console.log("Equity :" );
                              //           console.log(CBproj);
                  } }
                  



         // FILTER FOR MORTGAGE
         
        newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Mortgage") ) ;  
      
     
          nbitem= Object.keys(newarray).length;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Mortgage(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                                CBproj[y] += assetpj.Cash_outflow[y];
                                LiabProj[y] += assetpj.ValueP[y];                    
                                };  
                         //       console.log("Mortgage :" );
                         //        console.log(CBproj);
         } }


         // FILTER FOR DEBT
         
        newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Debt") ) ;  
      
          nbitem= Object.keys(newarray).length;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Debt(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                                CBproj[y] += assetpj.Cash_outflow[y];
                                LiabProj[y] += assetpj.ValueP[y];                    
                                };  
                          //      console.log("Debt :" );
                          //       console.log(CBproj);
         } }

    

          for (let y = 1; y <=N ; y++) {                     
                               CBproj[y]  += CBproj[y-1]*(1+dcashi);                     
                                };  


          for (let y = 0; y <=N ; y++) {                     
                              Aproj[y]  += CBproj[y]; 
                              WealthProj[y]= Aproj[y]-LiabProj[y];                   
                                };  




         // go throught eac asset in assetlist
         // for BS type and each asset type apply filter and check if the list is not emply
         // apply the right projection function
         // var proj = asetprojec(asset,yc,N)
         // for i=1 to N CBproj[i]+=proj.Cash_outflow[i]
         //
         // for Assets:  Aproj[i]=proj.value[i]
         // for Liabilities : Liabproj[i]=proj.value[i]

         // when all calclations have been completed. calculated interest increased value of Cash
                 
         // for i=1 to N
         //CBproj[i]+=CBproj[i-1]*dcashi;

         // finally for i=0 to N
         //Aproj[i]+=CBproj[i];
        /* 
          */

         this.TotalBSProj = Object.assign({}, {A:Aproj, L:LiabProj, W: WealthProj}   ); 
        // LineChart.update();
         this.chartvisible=true;
       //  this.Linechart2.updateChart();  

         } ,
         
         

       //  ***************

   // Calcaulate the monthly retirement income 

  async getretincome() {
    
       // const user = {"country": this.country, "gender": this.gender, "age": 20, "smoker": "S" };
      
      const amount = (this.TotalBSProj.W[Object.keys(this.TotalBSProj.W).length-1]).toFixed(2);
       const mdata = {"submit": true , "country":  this.user.Country, "gender": this.user.Gender, "age": this.calculateAge(this.user.Birthdate),
     "smoker": this.user.Smoker,  "currency": this.user.Currency,
     "projy": Number(this.pyear),"amount": Number(amount)};
    
  
     console.log("  mdata from differred annuity income :  ");
     console.log(mdata);
     console.log(this.cors_host);

    try {

    //  await axios.post('http://localhost:'+this.cors_host+'/one/lifemodels/differredannuityincome.php', mdata).then((res) => {     
        await axios.post(this.webaddress1+'lifemodels/differredannuityincome.php', mdata).then((res) => {     
         
    //    await   axios.post('http://localhost:'+this.cors_host+'/one/lifemodels/differredannuityincome.php', mdata).then((res) => {
          console.log("  differred annuity income :  ");
          console.log(res.data);

          console.log("  different annuity income :  ");
            this.differredincome=(Number(res.data)).toFixed(2);

           

            console.log(this.differredincome);
         
        })
               
        this.showError = false;
        
    } catch (error) {
      this.showError = true
    }
    
 },




///  **********  Calculate age

 calculateAge(birthday) { // birthday is a date
 //  var datenew = Date.now() ;
   var datenew = new Date()
  
  //    1991-06-04

    const ynew = datenew.getFullYear();
    const mnew = datenew.getMonth();
    const dnew = datenew.getDate();
    const yold = Number(birthday.substr(0, 4));
    const mold = Number(birthday.substr(5, 2));
    const dold = Number(birthday.substr(8, 2));
    
    const diffnumberdays= ynew*365+mnew*30+dnew- (yold*365+mold*30+dold);
    const age = Math.floor(diffnumberdays/365);

   return age;
 },


async getyc(){

  const mdata = {"submit": true , "ClientID":  this.user.Email,  "Currency": this.user.Currency };
    
  
     console.log("  downloading yc :  ");
     console.log(mdata);
     console.log(this.cors_host);

    try {

     // await axios.post('http://localhost:'+this.cors_host+'/one/assets/loadyc.php', mdata).then((res) => {     
        await axios.post(this.webaddress1+'assets/loadyc.php', mdata).then((res) => {     
         
    //    await   axios.post('http://localhost:'+this.cors_host+'/one/lifemodels/differredannuityincome.php', mdata).then((res) => {
          console.log(" yieldcurve ");
          console.log(res.data);

          console.log('myData');
      //   this.YC=res.data[Spot];
      var myData = Object.keys(res.data).map(key => {
                return res.data[key]['Spot'];
            });
     

         console.log(myData);
        console.log(this.YC);

      

          //  console.log(this.differredincome);
         
        })
               
        this.showError = false;
        return "finished";
        
    } catch (error) {
      this.showError = true
    }
   
},

async updateprojections(){
  const result =   await this.getyc();;

  this.Project_TotalBS(this.YC,Number(this.pyear));
  this.getretincome();

} 


},  





     created(){
                
               //  this.projections = Object.assign({}, this.Project_Bond(this.assetbond,this.YC,7)); 
              
                this.updateprojections();
                
             
            
                }
}

 
</script>

<style scoped>

div .assetslist {
display:flex;
flex-direction: column;
max-width: 420px;
margin:20px auto;

background: white;
text-align: left;
padding: 0px;
border-radius: 3px;
/*    border: 1px solid blue;  */ 
}

.title {
     padding: 0px 10px;
     margin-top: 20px;
     font-size: 22px;
     font-weight: bold;
     color: #285fb3;
}

div .bottom2buttons {
display :flex;
justify-content:space-between;
align-items: center;
margin-top: 10px ;
  gap: 10px;

}

div .bottom2buttons.bottom2left {
display :flex;
flex:1;
max-height: 50px;
align-self: flex-start;



}
.bottom2buttons.bottom2right {
display :flex;
flex:1;
max-height: 50px;
align-self: flex-end;


}


.bottom2buttons.bottom2center {
display :flex;
flex:1;
max-height: 50px;


}

.topsection{
   display:flex;
   flex-direction: column;
justify-content: space-between;
align-items: flex-start;
width:100%;
padding: 0px 10px;
 }

.midsection{
   display:flex;
   flex-direction: column;
justify-content: space-between;
align-items: flex-start;
width:100%;
 }

div .midtopline{
display:flex;
flex-direction: column;

justify-content: space-between;
align-items:center;
width:100%;

margin:10px 0 30px 0 ;

background: white;

border-radius: 3px;
/*  border: 1px solid blue;  */
}


.inputyears {
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
  background: white;
  height: 45px;
   max-width: 100px;  
  border: none;
}

.inputline{
border: none;
  
}

div .div1label {
display:flex;
flex-direction: column;
flex:3;
}
div .div1label2 {
display:flex;
flex-direction: column;
flex:3;
justify-items: center;
align-items: center;
justify-content: center;

}


div .divchart {
display:flex;
flex-direction: column;
width: 100%; ;

}

div .div2input {
display:flex;
flex:1

}

.form-label2{
    align-self: flex-start;
      text-align: left;
      color:#285fb3;
}




.executionbutton{
height: 35px;
width: 80px;
margin:0px
}


.table.table-striped.table-bordered {

  margin : 20px;
}


.divportfolioview{
  display: flex;
  width:420px;
  margin:7px 0 15px 0;
  justify-content: flex-end;

}

.fas.fa-circle {
  font-size: 11px;
  color: #9a9da3;
  margin-right: 10px;
}


.child-params1{
  display:flex;
}

.form-control.form-input2{
 display : flex;
 flex :1;

}

.form-control.form-input3{
  display : flex;
 flex :1;
 background-color: inherit;
 border: none;
}



</style>