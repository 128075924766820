<template>

  <div class="loan">
  

  
  <title>Loan calclation</title>

 



<div class="loancontent">
     

<div class="lifeex-form-form">    

                    <div class="divtitle">
                        <p class="title"> Calculate loan payment </p>
                    </div>

  <div class="lifeex-form-form1">   

         <hr class="new1"> 

            <div class="newclass"> 
                        <label class="form-label2" for="customRange3">Amount to borrow : </label>  
                                 <div class="child-params1"> 
                                           <input class="form-control form-input3" type="text"  v-model="currformat().left" disabled > 
                                           <input class="form-control form-input2" type="text"  v-model="amount" @blur="inputblurr"  @focus="inputfocus" v-on:keyup.enter="inputblurr;$event.target.blur()" >   
                                                       <input class="form-control form-input3" type="text"  v-model="currformat().right" disabled > 
  
                                    </div>
                       
                    
            </div> 



        <hr class="new1"> 
                            <div class="newclass"> 
                            <label class="form-label2" for="customRange3"> Duration of the loan </label>  


                            <div class="container-params">
                            
                            <div class="child-params1"> 

                                     <div class="child-params1"> 
                                           <input class="form-control form-input2" type="number"   v-model="duration" min="1" max="60">   
                                         <input class="form-control form-input3" type="text"  placeholder="years" disabled > 
  
                                    </div>
                                   
                            </div>
                              
                                <div class="child-params2">    
                                        <input type="range" class="customrange" min="0" max="60" step="1" v-model="duration" > 
                                </div>       
                            </div> 

                            </div> 



  <hr class="new1"> 


                          <div class="newclass"> 
                            <label class="form-label2" for="customRange3"> Interest rate </label>  
                          

                            <div class="container-params">
                            
                                    <div class="child-params1"> 
                                            <input class="form-control form-input2" type="text"  placeholder="1.00" v-model="rate"> 
                                            <input class="form-control form-input3" type="text"  placeholder="%" disabled> 
  
                                    </div>
                               
                                        <div class="child-params2">    
                                                <input type="range" class="customrange" min="0.00" max="20.00" step="0.01" v-model="rate" > 
                                        </div>       
                            </div> 

                            </div> 



  <hr class="new1"> 




    
    </div>   


    
 

  
  
  <div class="container-params">
      <button class="button" name="submit2" @click="submit"> Calculate</button>    
  </div>

  <div class="contentresult">
                 <hr class="new1"> 
               <p style=" text-align:left; margin:0px; padding:0px;"> 
                 
                   Your monthly payment is :
              </p>
                 <div class="divresultyears">
                      <b> {{currformat().left + ' '+ result +' '+currformat().right}} </b> 
                 </div>
               
              <hr class="new1"> 

             
         
    </div>

</div>



 

</div>





  </div>
</template>

<script>


import {createNamespacedHelpers} from 'vuex'


import axios from "axios";




const {  mapState} = createNamespacedHelpers('auth');
const parameters = createNamespacedHelpers('parameters');


export default {
  name: "Loan",
  components: {},
  data() {
    return {
      rate:1.00,
      amount:"100,000.00",
      duration:10,
      result:0,
      currency:"EUR",
      currparam:  {code:'USD', logo: '$' , designation:'United States Dollar', position: 0  }, 
      
    };
  },


 computed: {
   ...mapState(['regionalpreferences']),
    ...parameters.mapState(['listdefaultcountryparameters']),
    ...parameters.mapGetters(['filtercurr']),
   },




methods: {


 submit() {
   
  const val=this.loan(this.Commastonumber(this.amount),this.rate/100,this.duration);
     this.result=this.numberWithCommas(val.toFixed(2));
 
      
   },

    loan(K,r,N){
        const rm= (1+r)**(1/12)-1;   
        const a=1/(1+rm);
        const hh= (1-a**(N*12))/(1-a);
        const P = K/hh*(1+rm);
        return P;
        },


        numberWithCommas(x) {
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },

        Commastonumber(x) {
           const parts = x.replace(/,/g, '');         
          return parseFloat(parts).toFixed(2);
        },
       

       inputblurr(){
            this.amount=this.numberWithCommas(this.amount);
       },

         inputfocus(){
            this.amount=this.Commastonumber(this.amount);
       },
         currformat(){

                  const cfmt= this.filtercurr(this.currency);
                  const currlogs={};
                  if (cfmt.position==0) {currlogs.left=cfmt.logo; currlogs.right="" } else {currlogs.left=""; currlogs.right=cfmt.logo }
                  return currlogs;
           },



    },


    created() {  
 
      this.currency= this.regionalpreferences.currency;
      this.country= this.regionalpreferences.country;
      this.currformat();

  },
 
 
};
</script>

<style scoped>


.loan{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;

justify-content: center;
justify-items: center;
padding-top: 40px;

}


.title{
     margin-left: 2px;
     text-align: left;
     font-size: 18px;
     font-weight: bold;
     color: #285fb3;         
}



.loancontent{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;
width:250px;
justify-content: center;
justify-items: center;
}

.lifeex-form-form{
display:flex;
flex-direction: column;
width: 100%;
margin:0px;
padding: 0px;;
}

.lifeex-form-form1{
    display: flex;
    flex-direction: column;
}



.lecountryselect{
 margin:10px 0 10px 0;
 
}


.radiobutton {
    display: flex ;
    flex-direction: column;
}

.radiobutton2{
    display: flex; 
     justify-items: flex-start;
  flex-direction: row;
  min-height: 100%;
  justify-content: space-between;
  
}

 .radiobutton2 label {
      display:flex;
      flex:5;
      align-items: flex-end;
      justify-content: flex-start;
      align-content: flex-start;
      align-self: flex-start;
      text-align: start;
   }

  .radiobuttondiv {
   display: flex;
   flex: 1;

   margin: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 100%;


   } 

  .radiogender {
    
   margin:10px;
   
   }

.newclass{
    display: flex;
    flex-direction: column;
}




.form-label2{
    align-self: flex-start;
     text-align: left;
}

.form-control.form-input2{
    flex:3;
    text-align: center;
}
.form-control.form-input3{
    flex:1;
    text-align: center;
    border:none;

}


.form-control.form-input3:disabled{
    background-color: inherit;

}


button  {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 20px 00px;
  width: 100%;
}
button:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}


.contentresult{
    display: flex;
    flex-direction: column;

}

.tablelifeex {
  width:100%;
  margin-bottom: 50px;
}

 .tablelifeex   tbody {
      width:100%;
         box-shadow: 5px 5px 5px rgb(178, 179, 180);
    }




  .tablelifeex td{
  padding: 5px 10px 5px 10px; 
  font-size:15px;
  border: solid 1px rgb(194, 192, 192);
 }
 
 .tablelifeex th{
  padding: 10px; 
  font-size:15px;
  border: solid 2px rgb(194, 192, 192);
 }

 .divresultyears{
     border: solid 1px rgb(194, 192, 192);
     margin: 10px 0  20px 0 ;
     padding: 5px 0 5px 0;
    box-shadow: 5px 5px 5px rgb(178, 179, 180);


 }
 

 .child-params1{
   display: flex;
 }

#error {
  color: red;
}



</style>