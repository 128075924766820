<template>

  <div class="lifeexpectancy">
  




  
  <title>My life expectancy</title>

 



<div class="lifeexpectancycontent">
     

<div class="lifeex-form-form">    

     <div class="divtitle">
        <p class="title" style="text-align:left"> Calculate my life expectancy </p>
      </div>

  <div class="lifeex-form-form1">   
  <hr class="new1"> 

     <div class="newclass"> 
        <label class="form-label2" for="customRange3">Country : </label>  
                <select class="lecountryselect" name="country" v-model="country"  id="l_e_country">
                    <option selected="" disabled="" value="">--Select Country--</option>
                    <option value="US">USA</option>
                    <option value="GB">UK</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>   
                </select>
                
                <hr class="new1"> 
       </div>


    <div class="radiobutton">
      <div class="radiobutton2">
      <label for="m">Male</label>
       <div class="radiobuttondiv">  
         <input type="radio" name="gender" value="M" checked="" class="radiogender"  v-model="gender" >   
       </div>
      </div>
      <div class="radiobutton2">
      <label for="f">Female</label>
      <div class="radiobuttondiv"> 
      <input type="radio" name="gender" value="F" class="radiogender"  v-model="gender"  >  
      </div>
      </div>
   <hr class="new1"> 
    </div>

   
    <div class="newclass"> 
       <label class="form-label2" for="customRange3">Age : </label>  

          <div class="container-params">
                            
                            <div class="child-params1"> 

                                     <div class="child-params1"> 
                                           <input class="form-control form-input2" type="number"   v-model="age" min="1" max="100">   
                                         <input class="form-control form-input3" type="text"  placeholder="years" disabled > 
  
                                    </div>
                                   
                            </div>
                              
                                <div class="child-params2">    
                                        <input type="range" class="customrange" min="1" max="100" step="1" v-model="age" > 
                                </div>       
           </div> 

      <hr class="new1"> 
      </div> 

    
    </div>   


    
 

  
  
  <div class="container-params">
      <button class="button" name="submit2" id="submit2; " @click="submit"> Calculate</button>    
  </div>

  <div class="contentresult">
                 <hr class="new1"> 
               <p style=" text-align:left; margin:0px; padding:0px;"> 
                 Your life expectancy is :
              </p>
                 <div class="divresultyears">
                      <b> {{Ex}} years</b> 
                 </div>
               
              <hr class="new1"> 

            <p style="font-weight: bold; text-align:left; margin:0 0 5px 0; padding:0px;"> Probabilty that you reach age :
                </p> 


                 <table class="tablelifeex">
                     <tbody>
                    <tr>
                        <th  >Age</th>
                        <th >Probability</th>
                 
                    </tr>
                    <tr v-if="Proba['50']">    
                        <td>50</td>
                        <td>{{percentage(Proba['50'])}}</td>
                    </tr>
                    <tr v-if="Proba['70']">
                         <td>70</td>
                        <td>{{percentage(Proba['70'])}}</td>
                    </tr>
                       <tr v-if="Proba['80']">
                         <td>80</td>
                        <td>{{percentage(Proba['80'])}}</td>
                    </tr>
                       <tr>
                         <td>90</td>
                        <td>{{percentage(Proba['90'])}}</td>
                    </tr>
                       <tr v-if="Proba['100']">
                         <td>100</td>
                        <td>{{percentage(Proba['100'])}}</td>
                    </tr>
                    </tbody>
                 </table>

         
    </div>

</div>



 

</div>





  </div>
</template>

<script>


import {createNamespacedHelpers} from 'vuex'


import axios from "axios";


//var geoip = require('geoip');



const {  mapState } = createNamespacedHelpers('auth');



export default {
  name: "Lifeexpectancy",
  components: {},
  data() {
    return {
      age:20,
      country:"US",
      gender:"M",

      form: {
        username: "",
        password: "",
      },
      showError: false,
      Ex:80,
      Proba:{50:'',70:'',80:'',90:'',100:''}


    };
  },

/*
  mounted() {
        const scripts = [
            "/geoip-js.com/js/apis/geoip2/v2.1/geoip2.js",
            
        ];
        scripts.forEach(script => {
            let tag = document.head.querySelector(`[src="${ script }"`);
            if (!tag) {
                tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.setAttribute("type", 'text/javascript');
                document.head.appendChild(tag); 
            }
        })},
*/

 computed: {
   ...mapState(['regionalpreferences','cors_host', 'webaddress1','webaddress2'])
   },



methods: {

 percentage(a) {
   if(!isNaN(a)){
     const res=(a*100).toFixed(2) ;
     return (res + "%");
     };

/*
     axios.get("http://ip-api.com/json").then((res)=> {
      console.log(res.country)
     });
*/

/*
const whois = `https://ipwhois.app/json` ;
console.log(whois); 
*/

/*
 axios.get("http://ip-api.com/json").then((res)=> {
      console.log(res.country)
     });

*/


  



/*

try {
           axios.get('https://ipinfo.io/').then((res) => {
              console.log(res)

          })
                 
          this.showError = false;
         

          
      } catch (error) {
        this.showError = true
      }
      
  */
 /*    
 axios.get('https://ipinfo.io/'){
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*' // Could work and fix the previous problem, but not in all APIs
  }}.then(response => console.log(response));

*/
/*
const res = axios.get('https://ipinfo.io/', {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': 'http://localhost:8080/' // Could work and fix the previous problem, but not in all APIs
  },
 })

 console.log(res);
*/

 }, 




 
updatecountry(){
//axios.get('http://localhost:'+this.cors_host+'/two/')
//
axios.get(this.webbadress2)
                            .then((response)=> {
    	                           
                              //    console.log(response);
                                   this.country = response.data.countryCode;
                            //      console.log(response.data.countryCode);
                                             })

},



async submit() {
       
  
      const mdata = {"country": this.country, "gender": this.gender, "age": this.age};
      
      try {
         // await   axios.post('http://localhost:8080/include/lifeexpectancy.inc.php', mdata).then((res) => {
            await   axios.post(this.webaddress1+'include/lifeexpectancy.inc.php', mdata).then((res) => {

            this.Ex=res.data.Ex;
            this.Proba=res.data.Surv;
         

          })
                 
          this.showError = false;
         

          
      } catch (error) {
        this.showError = true
      }
      
   },




    },
     created(){
//this.updatecountry();

  this.country= this.regionalpreferences.country;

 }
 
};
</script>

<style scoped>


.lifeexpectancy{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;

justify-content: center;
justify-items: center;
padding-top: 40px;

}


.title{
     margin-left: 2px;
     font-size: 18px;
     font-weight: bold;
     color: #285fb3;         
}




.lifeexpectancycontent{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;
width:250px;
justify-content: center;
justify-items: center;
}

.lifeex-form-form{
display:flex;
flex-direction: column;
width: 100%;
margin:0px;
padding: 0px;;
}

.lifeex-form-form1{
    display: flex;
    flex-direction: column;
}



.lecountryselect{
 margin:10px 0 10px 0;
 
}


.radiobutton {
    display: flex ;
    flex-direction: column;
}

.radiobutton2{
    display: flex; 
     justify-items: flex-start;
  flex-direction: row;
  min-height: 100%;
  justify-content: space-between;
  
}

 .radiobutton2 label {
      display:flex;
      flex:5;
      align-items: flex-end;
      justify-content: flex-start;
      align-content: flex-start;
      align-self: flex-start;
      text-align: start;
   }

  .radiobuttondiv {
   display: flex;
   flex: 1;

   margin: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 100%;


   } 

  .radiogender {
    
   margin:10px;
   
   }

.newclass{
    display: flex;
    flex-direction: column;
}

.form-label2{
    align-self: flex-start;
      text-align: left;
}

.form-control.form-input2{
    flex:3;
    text-align: center;
}
.form-control.form-input3{
    flex:1;
    text-align: center;
    border:none;

}


.form-control.form-input3:disabled{
    background-color: inherit;

}



button  {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 20px 00px;
  width: 100%;
}
button:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}


.contentresult{
    display: flex;
    flex-direction: column;

}

.tablelifeex {
  width:100%;
  margin-bottom: 50px;
}

 .tablelifeex   tbody {
      width:100%;
         box-shadow: 5px 5px 5px rgb(178, 179, 180);
    }




  .tablelifeex td{
  padding: 5px 10px 5px 10px; 
  font-size:15px;
  border: solid 1px rgb(194, 192, 192);
 }
 
 .tablelifeex th{
  padding: 10px; 
  font-size:15px;
  border: solid 2px rgb(194, 192, 192);
 }

 .divresultyears{
     border: solid 1px rgb(194, 192, 192);
     margin: 10px 0  20px 0 ;
     padding: 5px 0 5px 0;
    box-shadow: 5px 5px 5px rgb(178, 179, 180);


 }
 

.child-params1{
   display: flex;
 }


#error {
  color: red;
}



</style>