<template>
 <div class="regionalprefs">
<div class="topsectionregional">
        <div :class='buttonclass(1)' @click="display=1; changeshow() ">
        Country
        </div>
        <div :class="buttonclass(2)" @click="display=2; changeshow()">
         Language
        </div>
        <div :class="buttonclass(3)" @click="display=3; changeshow()">
         Currency
        </div>
 </div>


  <transition name="tdropdown">  
 <div class="middlesectionregional" v-if="show">
      
             <div class="middlesection_country"  v-if="display==1"> 
                <div class="section_title">

                   Select country
               
                </div>
                <div class="section_bodyreg">
                      
         <div class="countrylanguageswitch__radiogroup countrylanguageswitch__radiogroupLanguage">
          

                <div class="form__element"   v-for="acountry in countries" :key="acountry.code">  
                  <div class="mdc-form-field form__radioElementWrapper">
                    <div class="form__radio mdc-radio mdc-ripple-upgraded mdc-ripple-upgraded--unbounded" data-mdc-initialised="true" style="--mdc-ripple-fg-size:24px; --mdc-ripple-fg-scale:2.77369; --mdc-ripple-left:8px; --mdc-ripple-top:8px;">
              
                      <input class="input_mdc-radio" :value="acountry.code" type="radio" name="country" :id="acountry.code" v-model="regionalpreference.country"   v-on:change="UPDATE_REGION(regionalpreference)">

                      <div class="mdc-radio__background">
                        <div class="mdc-radio__outer-circle"></div>
                        <div class="mdc-radio__inner-circle"></div>
                      </div>
                    </div>
                    <label class="form__label_form__radioLabel" for="radiogroup__language-fr"> {{acountry.name}}  </label>
                  </div>
                </div>
       </div>


                  </div>
              </div>
  
               <div class="middlesection_language" v-if="display==2">

                 <div class="section_title">
                  Select language        
                 </div>
               <div class="section_body">
                
                  <div class="countrylanguageswitch__radiogroup countrylanguageswitch__radiogroupLanguage">
          

                <div class="form__element"   v-for="(language) in languages" :key="language.code">  
                  <div class="mdc-form-field form__radioElementWrapper">
                    <div class="form__radio mdc-radio mdc-ripple-upgraded mdc-ripple-upgraded--unbounded" data-mdc-initialised="true" style="--mdc-ripple-fg-size:24px; --mdc-ripple-fg-scale:2.77369; --mdc-ripple-left:8px; --mdc-ripple-top:8px;">
              
                      <input class="input_mdc-radio" :value="language.code" type="radio" name="language" :id="language.code" v-model="regionalpreference.language"  v-on:change="UPDATE_REGION(regionalpreference)">

                      <div class="mdc-radio__background">
                        <div class="mdc-radio__outer-circle"></div>
                        <div class="mdc-radio__inner-circle"></div>
                      </div>
                    </div>
                    <label class="form__label_form__radioLabel" for="radiogroup__language-fr"> {{language.name}}  </label>
                  </div>
                </div>
             </div>
    
            </div>

         
          </div>

    
              <div class="middlesection_currency" v-if="display==3">  
                     <div class="section_title">
                           Select currency
                     </div>
                         <div class="section_body">
                         <div class="countrylanguageswitch__radiogroup countrylanguageswitch__radiogroupLanguage">
  

                <div class="form__element"   v-for="(currency) in currencies" :key="currency.code">  
                  <div class="mdc-form-field form__radioElementWrapper">
                    <div class="form__radio mdc-radio mdc-ripple-upgraded mdc-ripple-upgraded--unbounded" data-mdc-initialised="true" style="--mdc-ripple-fg-size:24px; --mdc-ripple-fg-scale:2.77369; --mdc-ripple-left:8px; --mdc-ripple-top:8px;">
              
                      <input class="input_mdc-radio" :value="currency.code" type="radio" name="currency" :id="currency.code" v-model="regionalpreference.currency"  v-on:change="UPDATE_REGION(regionalpreference)">

                      <div class="mdc-radio__background">
                        <div class="mdc-radio__outer-circle"></div>
                        <div class="mdc-radio__inner-circle"></div>
                      </div>
                    </div>
                    <label class="form__label_form__radioLabel" for="radiogroup__language-fr"> {{currency.designation}}  </label>
                  </div>
                </div>

       </div>
       </div>

                
     </div>
    



 </div>

  </transition>

 <div class="bottomsectionregional">



             <button class="executionbutton" @click="$router.go(-1)">
               <fa class= "icon_button" icon="circle-check"/>   
               Save
                  </button>
         

 </div>


 </div>

</template>

<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'


const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('parameters');
// { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('auth');
const auth = createNamespacedHelpers('auth');

export default {
 name: 'Regionalprefs',
 props:{Type:{type: String}},
 components: {  },
   data() { 
    return { display:1, show:true,  regionalpreference: {country:'US' , currency:'USD' , language:'en'} 
           } 
          } ,

 
    computed : {
         //  ...mapState({paramformats: state=>state.paramformat}),
         ...mapState(['countries','languages','currencies']),
         ...auth.mapState(['regionalpreferences']),
          
       },
     methods : {
    
        ...auth.mapActions(['UPDATE_REGION']),


     changeshow(){
               this.show=false;
               setTimeout(()=>{ 
               this.show = true; 
                }, 1);
                  },

    buttonclass(i){
               if(this.display==i) {return "topsectiontabi"} else {return "topsectiontab"}
                  }



     },

     created() {
         

        if (this.regionalpreference!==null) {this.regionalpreference = Object.assign({}, this.regionalpreferences)}


     },

     
    
}

 
</script>

<style>


.regionalprefs{
display:flex;

flex-direction: column;
align-content: center;
justify-content: center;
align-items: center;
margin: 0;

}



.topsectionregional{
display:flex;
margin:10px 0px 0px 0px;
background: white;
text-align: left;
width: 400px;
height: 40px;;

}


.topsectiontab{
display:flex;
flex-direction: row;
flex: 1;
border: 1px solid rgb(216, 216, 216);
 border-radius: 0.5px;
justify-content: center;
align-items: center;
/*padding: 0px 0px 0px 0px;*/
 padding: 10px 20px;
/* font-family: "Lufthansa Web","Helvetica Neue",Helvetica,Arial,sans-serif;
*/

font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;

   font-size: 15px;

}



.topsectiontab:hover {
 /*background: rgb(240, 240, 240);*/
 background-color: #0d6efd;
 color: white;
  transition: 0.5s ease all;
}


.topsectiontabi{
display:flex;
flex-direction: row;
flex: 1;

justify-content: center;
align-items: center;
/*padding: 0px 0px 0px 0px;*/
 padding: 10px 20px;
/* font-family: "Lufthansa Web","Helvetica Neue",Helvetica,Arial,sans-serif;
*/

font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;

   font-size: 15px;
    background-color: #0d6efd;
 color: white;

}

.middlesectionregional{
display:flex;
flex-direction:column;
 border: 1px solid rgb(216, 216, 216);
border-radius: 0.5px;
width: 400px;
background: white;

transform-origin: top ;
}



.dropdownA-content {
 display:block;  
  position: absolute;
  background-color: rgb(253, 250, 250);
  min-width: 160px;
 border: 1px solid rgb(216, 216, 216);
  border-radius: 0.5px;
  right:0; 
  transform-origin: top ;
  overflow: hidden;
  z-index: 10;
}




.bottomsectionregional{
display:flex;
margin: 10px 0px 0px 0px ;
width: 400px;
background: white;

justify-content: flex-end;


}

.middlesection_country{
display: flex;

flex-direction:column ;
}

.middlesection_language{
  display: flex;

flex-direction:column ;

}

.middlesection_currency{
  display: flex;

flex-direction:column ;

}




.tdropdown-enter-from,
.tdropdown-leave-to 
{
    transform: scaleY(0);
    opacity: 0;
}

.tdropdown-enter-active, .tdropdown-leave-active {
transition: all .4s ease-in-out;
}

.section_title{
  display: flex;
  justify-content: flex-start;
  margin: 30px 0px 0px 20px;
   font-size: 15px;
     font-weight: bold;
    line-height: 34px;
    color: var(--cbodytext);
 
  /* font-family: "Lufthansa Web","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
 
  */
   font-family: sans-serif;

}

.section_bodyreg{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 0px 5px 20px;

}


/* language section */


.countrylanguageswitch__radiogroup {
   
    margin-top: 10px;
    position: relative;
    z-index: 1;
}
.form__element {
    position: relative;
    width: 100%;
}

.countrylanguageswitch__radiogroup .form__radioElementWrapper {
    align-items: center;
    display: flex;
}
.form__checkboxElementWrapper, .form__radioElementWrapper {
    align-items: flex-start;
    display: flex;
}
.mdc-form-field {
     /* font-family: "Lufthansa Web","Helvetica Neue",Helvetica,Arial,sans-serif;
  */
  font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 1.25rem;
    text-decoration: inherit;
    text-transform: inherit;
    color: rgba(0,0,0,0.87);
    color: var(--mdc-theme-text-primary-on-light, rgba(0,0,0,0.87));
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
}

.mdc-radio {
    --mdc-ripple-left: 11px!important;
    --mdc-ripple-top: 11px!important;
}

.input_mdc-radio {

    -webkit-tap-highlight-color: rgba(0,0,0,0);
    display: inline-block;
    position: relative;
    flex: 0 0 auto;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    padding: 14px;
    cursor: pointer;
    will-change: opacity, transform, border-color, background-color, color;
}

.mdc-form-field {
    font-family: "Lufthansa Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.04em;
    line-height: 1.25rem;
    text-decoration: inherit;
    text-transform: inherit;
    /* color: rgba(0,0,0,0.87); */
    color: var(--mdc-theme-text-primary-on-light, rgba(0,0,0,0.87));
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    margin: 10px;
}

.form__label_form__radioLabel {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: var(--cbodytext);
    margin: 0px 0px 0px 20px; 
    align-self: center;
    justify-self: center;
    justify-content: center;
}

.executionbutton {
  display:flex;
  background-color: #0d6efd;
  color: white;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 20px;
  
  height: 45px;
  width: 130px;
 
    align-items: center;
}
.executionbutton:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}

.icon_button{
  margin-right: 10px;
}





</style>