<template>

 <div class="div_lineChart" >

    <vue3-chart-js
        :id="Linechart2.id"
        ref="chartRef"
        :type="Linechart2.type"
        :data="Linechart2.data"
        :options="Linechart2.options"
        
    ></vue3-chart-js>
<!--  :options="options"
    <button @click="updateChart">Update Chart</button>
    -->
  </div>
</template>

<script>
import { ref } from 'vue'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
  name: 'Linechart2',
  components: {Vue3ChartJs},

props:{assetval:{type: Array,  default: () => []}, liabval:{type: Array,  default: () => []}, wealth:{type: Array,  default: () => []}, datalabels:{type: Array,  default: () => []} },
  
    watch: {
    assetval() {
      this.updateChart();
    }
  },

  setup (props) {
    const chartRef = ref(null);

    const Linechart2 = {
      
      id: 'doughnut',
      type: 'line',
    
    
    options: {
        plugins: {
            title: {
                display: false,
                text: 'Custom Chart Title'
            }
        }
    },
    
 
      data: {
       // labels: ['0','1','2','3','4','5','6','7','8','9','10'],
        
        datasets: [
          {
            label:'Asset',
            borderColor: "#41B883",
            backgroundColor: [
              '#41B883'],
            data: props.assetval,
          },
           {
            label:'Liabilities',
            borderColor: "#00D8FF",
            backgroundColor: [ '#00D8FF'],
            data: props.liabval,
          },
           {
            label:'Wealth',
            borderColor: "black",
            backgroundColor: ['black' ],
            data: props.wealth,
          },
        ]
      }
    };

    const updateChart = () => {
     // Linechart2.data.labels = [0,1,2,3,4,5,6,7,8,9,10],
      Linechart2.data.datasets = [
    {
            label:'Asset',
            borderColor: "#41B883",
            backgroundColor: [
              '#41B883'],
            data: props.assetval,
          },
           {
            label:'Liabilities',
            borderColor: "#00D8FF",
            backgroundColor: [ '#00D8FF'],
            data: props.liabval,
          },
           {
            label:'Wealth',
            borderColor: "black",
            backgroundColor: ['black' ],
            data: props.wealth,
          },
      ];

      chartRef.value.update()
    }

    return {
      Linechart2,
      updateChart,
      chartRef
    }
  },
}
</script>


<style>


.div_lineChart{
  display: flex;
  flex-direction: column;
 
}
</style>
