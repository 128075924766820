<template>
   <div id="topnav">
     <div id="topnav2">
    <header :class="{'scrolled-nav':scrolledNav}">  
     <nav>
           
           <div  id="topnavcontent">

              
            <div class="branding" >
                   <h2 @click="this.$router.push({name:'Home'});"  style="cursor:pointer">Wealth & Retirement </h2>  <!-- add picture with : 
              <img src="@/assets/logo.pmg" alt="">
             -->
             </div>

            <div v-show="!mobile" id="topnavcontentright">
                 <div v-show="!session" id="topnavcontentrightUp">
                          <router-link class="linktop" :to="{ name: 'Regionalprefs' }">
                            <div  class="buttonworld">           
                                  <!--  <i class="fas fa-regular fa-globe"></i>    
                                  -->   
                          <fa icon="globe"/>                                     
                            </div>
                          </router-link>  

                        
                                   <router-link class="linktop" to="/Register">
                                  <div  class="buttonlogin">
                                   Sign up
                                     </div>
                                   </router-link>
                          
                      
                        
                        
                           <router-link class="linktop" to="/login">
                           <div  class="buttonlogin">
                           Log in
                            </div> 
                           </router-link>
                                 
                                      
                  </div>
                     <div v-show="session" id="topnavcontentrightUp">
                        
                        
                                  <router-link class="linktop" :to="{ name: 'Regionalprefs' }">
                                <div  class="buttonworld">
                                <i class="fa fa-regular fa-globe"></i>
                                 </div>
                                </router-link> 



                                <router-link class="linktop" to="/Userdetails">
                                   <div  class="buttonlogin">
                                  
                                    <i class="fa fa-user"></i>
                                 </div>
                                 </router-link> 

                                 <router-link class="linktop" to="">
                                    <div  class="buttonlogin" @click="this.logout();  this.$router.push({name:'Home'});">
                                              <span > Log off</span>
                                    </div>     
                                   </router-link> 
                                           
                  </div>





                  <div id="topnavcontentrightDown">

                            <ul v-show="!mobile" class="navigation">
                            <li> <router-link class="link" :to="{ name: 'Home' }"  @mouseover="activateshow(0)" >Home</router-link> </li>
                             <li><router-link class="link" :to="{ name: 'Lifeexpectancy' }"  @mouseover="activateshow(0)" >Life Expectancy</router-link> </li>
                             
                           




                              <li> 
                              <div class="dropdownB"  @click="show[2]=!show[2]" @mouseover="activateshow(2)" @mouseleave=" waittime(2)" >
                              <span class="link">Insurance</span> 
                              <transition name="tdropdown">    
                                      <div class="dropdownB-content" v-if="show[2]">
                            <!--          <router-link  :to="{name: 'Catdetails', params: { assetcat:"Cash" } }" > Show details </router-link> 
                            -->          
                                          <router-link class="link" :to="{ name: 'Lifeinsurance' }">Life insurance</router-link> 
                                          <router-link class="link" :to="{ name: 'Annuity' }">Annuity</router-link>                        
                                      
                                      </div>
                              </transition>
                              </div>
                            </li>

                              <li><router-link class="link" :to="{ name: 'Loan' }"  @mouseover="activateshow(0)" >Loan</router-link> </li>



                              <li> 
                              <div class="dropdownB"  @click="show[1]=!show[1]" @mouseover="activateshow(1)" >
                             <!--    <span class="link">My wealth</span> 
                             -->
                                  <router-link class="link" :to="{ name: 'Portfolioview' }">Wealth</router-link>                     
                              <transition name="tdropdown">    
                                      <div class="dropdownB-content" v-if="show[1]"  >
                            <!--          <router-link  :to="{name: 'Catdetails', params: { assetcat:"Cash" } }" > Show details </router-link> 
                            -->          
                                           <router-link class="link" :to="{ name: 'Portfolioview' }">My wealth</router-link>                     
                                          <router-link class="link" :to="{ name: 'BSprojection' }">Projection</router-link>                                         
                                          <router-link class="link" :to="{ name: 'Summaryreport' }">Summary report</router-link>     
                                        </div>
                              </transition>
                              </div>
                            </li>


                             <li><router-link class="link" :to="{ name: 'Insurers' }"  @mouseover="activateshow(0)" >Find Insurer</router-link> </li>

                            </ul>
                              </div>   
            </div>   

             <div class="icon">
                 <i @click="clickMobileNav" v-show="mobile" class="fa fa-bars" :class="{'icon-active':mobileNav}"></i>
             </div>           
       
        <transition name="mobile-nav">  
             <div v-show="mobileNav" class="dropdown-nav"  v-click-outside="onClickOutside">  
        
              <ul >
              <li> <router-link class="link" :to="{ name: 'Home' }" @click="toggleMobileNav"  >Home</router-link> </li>
             <li><router-link class="link" :to="{ name: 'Portfolioview' }" @click="toggleMobileNav"  >Portfolio</router-link> </li>
             <li> <router-link class="link" :to="{ name: 'BSprojection' }" @click="toggleMobileNav" >Projection</router-link></li>     
             <li> <router-link class="link" :to="{ name: 'Summaryreport' }" @click="toggleMobileNav" >Summary report</router-link>        </li>   
             <li> <router-link class="link" :to="{ name: 'Lifeexpectancy' }" @click="toggleMobileNav" >Life expectancy</router-link></li>        
             <li> <router-link class="link" :to="{ name: 'Lifeinsurance' }" @click="toggleMobileNav" >Life insurance</router-link></li>        
             <li> <router-link class="link" :to="{ name: 'Annuity' }" @click="toggleMobileNav" >Annuity</router-link></li>        
             <li> <router-link class="link" :to="{ name: 'Loan' }" @click="toggleMobileNav" >Loan</router-link></li>        
             <li> <router-link class="link" :to="{ name: 'Insurers' }" @click="toggleMobileNav" >Find Insurers</router-link></li>                           
             -------------------------
             <li> <router-link class="link" :to="{ name: 'Regionalprefs' }" @click="toggleMobileNav" >Regional Preferences</router-link></li>          
             -------------------------
             <li> <router-link class="link" to="/Register" @click="toggleMobileNav" >Register</router-link> </li>
             <li v-show="!session"><router-link class="link" to="/login" @click="toggleMobileNav" >Login</router-link> </li>
             <li v-show="session"><router-link class="link" to="/Home" @click="this.logout()" >Log off</router-link> </li>



             -------------------------
             <li v-show="session"> <router-link class="link" to="/Userdetails" @click="toggleMobileNav">User details</router-link> </li>
       
          
            </ul>

           
          </div>
           

         </transition>

           </div>
     </nav> 
     </header>
     </div>
     <div class="mainbody" v-bind:style="{ 'padding-top': mobilemargin(mobile) }" @mouseover="activateshow(0)"  >
       <router-view></router-view>
     </div>
    
     </div>
</template>

<script>

import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'

//import vClickOutside from 'v-click-outside'

//import useDetectOutsideClick from '../code/useDetectOutsideClick'

import axios from "axios";



import vClickOutside from 'click-outside-vue3'


const modal = ref(false)
const modalRef = ref(null)


// const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('auth');

const auth = createNamespacedHelpers('auth');
const parameters = createNamespacedHelpers('parameters');

export default {

     name: "NavBar3",
     data(){
       return{
       //  session:false,
       country:"",
         scrolledNav: null,
         mobile:true,
         mobileNav:false,
         windowWidth:null,
         show:[false,false,false],
         clickoutside:false,
       //  mobilemargin:'10px',
         //mobilemargin:'10px'

        

       }
     },


  

       computed: {
      ...auth.mapState(['regionalpreferences', 'session','webaddress2']),

      ...parameters.mapState(['listdefaultcountryparameters', 'currencies']),
       
    
      
      

      //     ...mapState({ parameters: state=>state.parameters  }),
      //...mapState(['session']),

     // ...mapState({   regionalpreferences: state=>state.regionalpreferences  }),

    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },

    /*
    mobilemargin (){
      if (this.mobile) {return '40px'} else {return '200px'};
    }
*/
/*
directives: {
      clickOutside: vClickOutside.directive
    },
 */
     },
 
 

  mounted() {
   window.addEventListener('scroll', this.updateScroll);
  this.$router.push({name:'Home'});




  },
  
  
  directives: {
      clickOutside: vClickOutside.directive
    },

  methods: {
  
    ...auth.mapActions(['UPDATE_REGION','UPDATE_LOCALREGION','logout']),
    ...auth.mapMutations(['UPDATE_REGIONDATA_STORE']),
    // ...mapState({   listdefaultcountryparameters: state=>state.assets  }),
 
    toggleMobileNav(){
          this.mobileNav=!this.mobileNav;
    },

    waittime(n){
       //  setTimeout(function() { this.show[n]=false; }, 500);        
       setTimeout(()=> { this.show[n]=false; }, 2);  
    },

  closeshow(n){
       //  setTimeout(function() { this.show[n]=false; }, 500);        
       setTimeout(()=> { this.show[n]=false; }, 500);      
    },
   activateshow(n){
        const lshow=this.show.length;
        for (let step = 0; step < lshow; step++) {
           if(step==n){
        this.show[step]=true;
      } else { this.show[step]=false;} 
              }  
    },

    
      mobilemargin(mobile){
        if (mobile) {return '10px'} else {return '80px'};
      }, 
      

    checkScreen(){
      this.windowWidth=window.innerWidth;
      if(this.windowWidth<=750){
        this.mobile=true;
        return;
      }
      this.mobile=false;
      this.mobileNav=false;
      return;

    },
    updateScroll(){
      const scrollPosition = window.scrollY;
      if (scrollPosition>50) {
        this.scrolledNav=true;
        return;
      }
      this.scrolledNav=false;

    },
    updatecountry(){
     axios.get(this.webaddress2)
                            .then((response)=> {
    	                           
                               //  console.log(response);
                                   this.country = response.data.countryCode;
                                  console.log(response);
                                
                         //  const newregion = {country:response.data.countryCode, currency:"USD", language:"en"};
                                //   commit('UPDATE_REGIONDATA_STORE',newregion);
                                   // listdefaultcountryparameters
                             //      console.log(this.listdefaultcountryparameters);
                                   let obj = this.listdefaultcountryparameters.find(o => o.country === response.data.countryCode);
                                     // console.log(obj);
                                      if(obj.country.length===0) {obj= {country:"USA", currency:"USD", language:"en"}};

                                this.UPDATE_LOCALREGION (obj);
                                this.UPDATE_REGION (obj);

                                             })
         


     },

    onClickOutside (event) {
     //   console.log('Clicked outside. Event: ', event);
       console.log( this.mobileNav);
      // this.mobileNav=false;

     // this.clickoutside = false;

       
        if (this.mobileNav==true) {
          this.mobileNav=false;
          this.clickoutside = true;
        } 
        
        
        
        console.log( this.mobileNav);
        return this.mobileNav;
      },

      

      clickMobileNav(){
       
     //  if ((this.mobileNav==false) && (this.clickoutside ==false)) {

      if ((this.mobileNav==false) ) 
        {
          this.mobileNav=true;
          this.clickoutside =false;
        } 
        
        //else {this.mobielNav = false} ;


       
       /*
        if (this.clickoutside==false) {
          this.mobileNav=true;
        } 
        */

        /*

        if (this.mobileNav==true) {
          
        //  const  result = await this.onClickOutside();
          this.mobileNav=false;

        } else {
           const  result = await this.onClickOutside();
                   
             this.mobileNav=true;

     

        }; 
      
           */



      },





      handler (event) {
        console.log('Clicked outside (Using config), middleware returned true :)')
      },
      // Note: The middleware will be executed if the event was fired outside the element.
      //       It should have only sync functionality and it should return a boolean to
      //       define if the handler should be fire or not
      middleware (event) {
        return event.target.className !== 'modal'
      }
     

  },

   created() { 
       window.addEventListener("resize", this.checkScreen);
       this.checkScreen();
       


       this.updatecountry();  
       console.log(this.regionalpreferences.country);


       if(this.regionalpreferences.country.length===0
       ){
       this.updatecountry();
      
      };

  },
};


</script>
<!-- <style >-->
<style lang="scss" scoped> 

#topnav {
display: flex;
flex-direction: column;
justify-content: space-between;

}

#topnav2 {
display: flex;
flex-direction: column;
margin-bottom: 60px;
}
  
.mainbody{
   //padding-top: 100px;
} 

header {
    background-color: rgba(0,0,0,0.8);
    z-index: 99;
    width: 100%;
    position: fixed;
    transition : 0.5s ease all;
    color: #fff;

            nav{
            display: flex;
            position: relative;
            flex-direction: row;
            padding: 12px 0;
            transition : 0.5s ease all;
            width: 100%;
            margin: 0 auto;
                 
            @media(min-width:1140px){ max-width:1140};   
            }

            ul,
            .link{
                font-weight: 500;
                color:#fff;
                list-style: none;
                text-decoration: none;
            }

            li{
               text-transform:uppercase ;
               padding: 16px;
               margin-left:16px;
               

            }

            .link{
                font-size: 14px;
                transition: 0.5s ease all;
                padding-bottom: 4px;
                border-bottom : 1px solid transparent;

                &:hover{
                    color: #00afea;
                    border-color: #00afea;
                }
            }

            .branding{
                display: flex;
              
                margin-left: 15px;

                img{
                    width:50px;
                     transition: 0.5s ease all;
                }

            }





                          .dropdownB {
                            position: relative;
                            display: inline-block;
                            margin-right: 3px;
                          
                                  }

                          .dropdownB-content {
                            display:block;  
                            position: absolute;
                         
                            min-width: 160px;
                            box-shadow: 0px 8px 16px 0px rgba(247, 244, 244, 0.2);
                            right:0; 
                            transform-origin: top ;
                            overflow: hidden;
                            z-index: 10;

                          }

                          .dropdownB-content a {
                          
                          color:#fff;
                            padding: 12px 16px;
                            text-decoration: none;
                            display: block;
                            background-color: rgba(0,0,0,0.8);

                          }

                          .dropdownB-content a:hover {color: #00afea;
                           border-color: #00afea;}
        /*
                          .dropdownB:hover .dropdownB-content {display: block;}

                          .dropdownB:hover .dropbtn {background-color: #3e8e41;}
   */
                          .tdropdown-enter-from,
                          .tdropdown-leave-to 
                          {
                              transform: scaleY(0);
                              opacity: 0;
                          }

                          .tdropdown-enter-active {
                          transition: all .4s ease-in-out;
                          }

                         .tdropdown-leave-active {
                          transition: all .6s ease-in-out;
                          }


                          #topnavcontent{
                              display: flex;
                              width:100%;
                              justify-content: space-between;
                              align-items: flex-start;
                              justify-items: flex-start;
                              flex-direction: row;
                              padding: 0 10px;
                            


                               #topnavcontentright{
                                      display: flex;
                                      flex-direction: column;

                                              #topnavcontentrightUp{
                                                display: flex;
                                                justify-content: flex-end;

                                               
                                                    

                                                    .linktop {
                                                        text-decoration: none;
                                                        color: #96abb3;  
                                                         .buttonlogin, .buttonworld {
                                                              margin :0 5px;
                                                            border:1px solid #96abb3;
                                                            padding: 3px 10px;
                                                              border-radius: 35%;
                                                              font-size: 14px;   
                                                              color: #96abb3;
                                                      }
                                                   
                                                      }
                                                .buttonlogin:hover, .buttonworld:hover {
                                                     color: #00afea;
                                                     border:1px solid #00afea;
                                                         .linktop {
                                                             color: #00afea;  
                                                          }


                                                     transition: 0.5s ease all;
                                                      

                                                      }
                                             /*         
                                                      i {
                                                        color: #96abb3;  
                                                      }
                                                        i:hover {color: #00afea;
                                                           transition: 0.5s ease all;
                                                      }
                                              */



                                              }
                                              #topnavcontentrightDown{
                                                display: flex;
                                              }      

                                    }


                            }

                         

                           

                         








            .navigation {
              display:flex;
              align-items: center;
              flex:1;
              justify-content: flex-end;
            }

            .icon {
              display: flex;
              align-items: center;
              position: absolute;
              top:0;
              right:24px;
              height: 100%;
              i {
                cursor: pointer;
                font-size: size 24px;
                transition: 0.8s ease all ;
               
              }

            }

            .icon-active{
              transform: rotate(180deg);
            
            }

            .dropdown-nav{
              display: flex;
              overflow: auto;
              flex-direction: column;
              position: fixed;
              width:100%;
              max-width: 250px;
              height: 100%;
              background-color: rgb(27, 25, 25);
              top:0;
              left:0;

              li {
                margin-left:0;
                .link {
                  color:#fff;
                }
              }
            }
            
            
            .mobile-nav-enter-active, 
            .mobile-nav-leave-active {
              transition: 1s ease all;
            }
            


            .mobile-nav-enter-from, 
            .mobile-nav-leave-to {
              transform: translateX(-250px);
            }

            .mobile-nav-enter-to{
              transform: translateX(0);
            }

}

.scrolled-nav{
  background-color: #000;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.06);

  nav{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding : 8px 0;

    .branding{
    img {
      width : 40px;
      box-shadow:0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgb(0, 0, 0, 0.06); ;
    }
    }

  }









}
</style>