
const state = {
   todos: [
       {
           id:1,
           title: ' Todo One'
       }, 
       {
        id:2,
        title: ' Todo Two'
    }
    
   ],
   info:431
};

const getters = {
//    allTodos: function (state) { return state.todos }
       allTodos:  (state) => { return state.todos }  
  //  info: (state) => state.info
};




const mutations = {
  //  addTodo: function ( state,newtodo) { (state.todos.push(newtodo)) }
    addTodo:  ( state,newtodo) => { (state.todos.push(newtodo)) }
};

const actions = {
addTodoAction: function ({commit}, newtodo)  {
// addTodoAction: function ({commit,state}, newtodo)  {

    setTimeout(
        function() {
            commit("addTodo",newtodo);
        //   console.log("hello");
         } ,100
        
    )
} 


};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    
}
