<template>
  <div class="home">
  <h3 class="hometitle">Plan and prepare your income for life</h3>
  <div class="boxcontent1">
    
       <div class="boxcontent1_ch_leftbox">
    
     </div>

     <div class="boxcontent1_ch_rightbox">
          <Caroussel />
     </div>

  </div>
    <div class="boxcontent2">


         <div class="boxcontent2_ch_leftbox">
                
               
         </div>

          <div class="boxcontent2_ch_rightbox">
               
            



                      <div class="aositemleft" data-aos="fade-right" data-aos-mirror="true">
                           <div class = "aosband">
                               <h2>Life expectancy</h2>
                           </div>  
                            
                                       
                      </div>




                   <div class="aositemright" data-aos="fade-left" data-aos-mirror="true">
                        <h5>

                              Knowing your life expectancy can help you make informed decision about your health and well-being. It can also help you plan for the future, both financially and personnaly. 
                              For example, if you know that you have a shorter life expectancy, you may chooose to prioritize certain goals and make different life choices. On the other hand, if you have a longer life expectancy, you may feel more comfortable making long-term plans and investments.  


                  <!--
                        How long you live depends on which country you live in. <br>
                        Life expectancy of the population can impact your retirement income. <br>
                        Find out which country will offer you best retirement income.<br>
                          <router-link class="linkhome" :to="{ name: 'Lifeinsurance' }">Compare life insurance premium</router-link>   
                 
                  -->        
                
                  
                   <router-link class="linkhome" :to="{ name: 'Lifeexpectancy' }">   Assess your life expectancy</router-link>
                
                        </h5>
                                       
                      </div>
                                   

                                    
                                    
                                                  
                    
            

                  
               
           
               
               
          </div>

     <!-- 
      <img src="../assets/20220109_103033.jpg" width= "200px" length= "300px">
      <img src="/Users/alexisbailly/Documents/Programming/Website/vueform/web-form/src/assets/20220109_103033.jpg" width= "200px" length= "300px">

       <img src="/src/assets/logo.png" width= "200px" length= "300px">
     "hello 2"
-->

  
  </div>
    <div class="boxcontent3">

      <div class="boxcontent3_ch_leftbox">
                
               
         </div>

          <div class="boxcontent3_ch_rightbox">
                 <div class="aositemleft" data-aos="fade-right" data-aos-mirror="true">
                
                          <div class = "aosband">
                                <h2> Your wealth </h2>
                           </div>  
               
               </div>
                 <div class="aositemright" data-aos="fade-left" data-aos-mirror="true">
                        <h5>
                          Your wealth is the difference between your assets and your debts and will change over time depending on your investment decisions. Planning your wealth according to your life expectancy will help ensure that you have enought resources to support yourself throughout your lifetime.
                       
                <!--
                     <br>
                  Your wealth is the difference between your assets and your debts <br>
                  Assets will grow with your investment decisions <br>

                -->          
                
                    <router-link class="linkhome" :to="{ name: 'Portfolioview' }">Is your wealth sufficient to cover your retirement needs ? </router-link>   
                  
                  
                  
                         </h5>
                                       
                      </div>

              
               
          </div>
     </div>

    <div class="boxcontent4">

      <div class="boxcontent4_ch_leftbox">
                
               
         </div>

          <div class="boxcontent4_ch_rightbox">
                <div class="aositemleft" data-aos="fade-right" data-aos-mirror="true">
              
                           <div class = "aosband">
                                <h2> Compare Insurers </h2>
                           </div>  
 
               </div>



            <div class="aositemright" data-aos="fade-left" data-aos-mirror="true">
                        <h5>
                  

                Choosing a life insurance provider that is financially stable and has a good track record can help ensure that that your policy will be there for you when you need it. 
                Life insurance policies can vary significanly in terms of the coverage they provide, the premiums they charge and the terms and conditions they offer.
                  <!-- 
                    What are the insurers in your country ? <br>
                  Compare how much insurers charge above your pure premium<br>
                  Customer satisfactions <br>
                  -->
                  

                  <router-link class="linkhome" :to="{ name: 'Insurers' }">Compare different insurers </router-link>   
              
                            
                   </h5>
                                       
                      </div>
               
          </div>
     </div>
         

  </div>
</template>
<script>

import Caroussel from "../components/menus/Caroussel.vue";


import { onMounted } from "vue";
import AOS from "aos";





export default {
  name: 'Home',
  components: {Caroussel },

setup(){



},
 

onMounted ()  {
    AOS.init({
  duration: 1000,once: false, mirror: true
});
}





}
</script>

  

<style scoped>

.home{
display: flex;
flex-direction: column;
overflow: auto;


}


.hometitle{
  margin-top:10px;
}

Caroussel {

}


.boxcontent1{
display: flex;
background: rgb(201, 196, 196);
height: 500px;
}
.boxcontent2{
display: flex;
background: rgb(230, 221, 221);



}
.boxcontent3{
display: flex;
background: rgb(224, 224, 224);
/*height: 500px;
*/
}
.boxcontent4{
display: flex;
background: rgb(224, 224, 224);
/*height: 500px;
*/
}

.boxcontent1_ch_leftbox{
display:  flex;
background: rgb(224, 224, 224);
height: inherit;
width: 20px;

}
.boxcontent1_ch_rightbox{
display: flex;
flex-direction: column;
background: rgb(80, 77, 77);
width: 100%;
height: inherit;
padding:0 10px ;
justify-content: center;
}

.boxcontent2_ch_leftbox{
display:  flex;

background: rgb(198, 172, 172);
height: inherit;
width: 20px;

}


.boxcontent2_ch_rightbox{
display: flex;
flex-direction: column;
background: rgb(165, 89, 89);
width: 100%;
height: inherit;
color:white;

}

.boxcontent3_ch_leftbox{
display:  flex;


background: rgb(127, 198, 141);
height: inherit;
width: 20px;

}
.boxcontent3_ch_rightbox{
display: flex;
flex-direction: column;
background: rgb(65, 159, 95);
width: 100%;
height: inherit;

color:white;
}



.boxcontent4_ch_leftbox{
display:  flex;


background: rgb(140, 172, 237);
height: inherit;
width: 20px;

}
.boxcontent4_ch_rightbox{
display: flex;
flex-direction: column;
background: rgb(75, 93, 129);
width: 100%;
height: inherit;

color:white;
}




.aositemleft {
  display:flex;
  align-self: flex-start;
  justify-self: flex-start;
  height: 60px;
  margin-bottom: 10px;
 
  text-align: left;
  color: #FFF;
  font-size: 3em;
  justify-content: center;
  align-content: center;
    align-items: center;

}


.aositemright {
  display: flex;
  align-self: flex-end;
  justify-self: center;
 /* height: 70%; 
 */
  width: 70%;
  margin-right: 50px;
  padding-top: 50px;

  text-align: left;
  color: #FFF;
  font-size: 3em;
  align-items: center;

  line-height: 1.6


}

.aosband{
display: flex;
 align-self: flex-start;
  justify-self: flex-start;
  height: 60px;
background: rgba(80, 77, 77, 0.57);
margin-top:20px;


flex-grow: 0.1;
padding : 0 30px 0 30px ;

align-items:center;

}


h5 {
line-height: 2;
}


  .linkhome { color:rgb(206, 247, 195);
              text-decoration: unset;   

               }

                 .linkhome:hover{
                    color: #00afea;
                    border-color: #00afea;
                }
            


</style>
