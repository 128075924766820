<template>
<div class="mainframe" v-if="visible">


<div class="textsummary">

   <!--
    <h3  style="text-align: center; ; font-weight: bold;  "> My Wealth  </h3>

     -->
     <p class="title"> Wealth Report  </p>

  <div >
     
     <!--


      <button class="buttonmodal" id="show-modal" @click="showModal = true">Show Modal</button>  
     -->
     <!-- use the modal component, pass in the prop -->
      
      <transition name="fademodal">  
      <modal v-if="showModal" @close="showModal = false">
    
          <template v-slot:header> 
              <h3 > Custom header </h3>
            </template>

            <template v-slot:body> 
              <h3 > Custom header </h3>
               <Addassetcategory  style="flex:1"  />
            </template>
        
     <!--   <h3 slot="body">This is some body text son.</h3> -->
 
      </modal>
 </transition> 
   
   </div>

<!--
  <fade-transition>
    <div v-if="show" class="box"></div>
  </fade-transition>
-->

  </div>


   <div class="secondsection">
 
<!--
-->
<BSSummary  :assetval="Number(totalAL.Assets)"  :liabval="Number(totalAL.Liabilities)" :currency="{left:currformat().left, right:currformat().right}"  :netassets="Number(totalAL.Assets - totalAL.Liabilities)" style="flex:1"  />  

<div class="divportfolioprojection">
<router-link class="routerlink" :to="{ name: 'BSprojection' }">Portfolio projection</router-link> 
</div>

<AssetsList balancesheetsection="Assets" style="flex:1"  :currency="{left:currformat().left, right:currformat().right}"  :key="componentKey"  />


 <AssetsList balancesheetsection="Liabilities" style="flex:1" :currency="{left:currformat().left, right:currformat().right}" :key="componentKey"  />



</div>
<!--
     <Addassetcategory  style="flex:1"  />
<BSSummary  assetval="100" , liabval="90" , netassets="10" />

    
     <LiabilitiesList />
 <NetAssets />
-->


</div>

</template>

<script>
import AssetsList from './AssetsList.vue'
import BSSummary from './BSSummary.vue'
import Addassetcategory from './AddAssetCategory.vue'
// import transitionassetclass from './transitionassetclass.vue'
import Modal from "../comps/Modal.vue";
import {createNamespacedHelpers} from 'vuex'




// import LiabilitiesList from './LiabilitiesList.vue'
// import NetAssets from './NetAssets.vue'
const assets= createNamespacedHelpers('assets');
const auth= createNamespacedHelpers('auth');
const parameters = createNamespacedHelpers('parameters');

export default {
     name: 'Portfolioview',

    components: {AssetsList, BSSummary, Addassetcategory,  Modal}, 
   
   data() {
    return {
       // email:'',
      //  password:'',
      //  note:''
      visible:false,
      showModal:false,
       chartData: [
        ["Language", "Blog"],
        ["PHP", 180],
        ["Laravel", 200],
        ["Html", 100],
        ["vue.js", 150],
      ],
      chartOptions: {
        chart: {
          title: "Company Performance",
          subtitle: "blog in total: languages"
        }
      },
      country:"US",
      currency:"EUR",
      currparam:  {code:'USD', logo: '$' , designation:'United States Dollar', position: 0  }, 
      componentKey:0,

    }
   },


computed : {
  
              ...assets.mapGetters(['totalAL','getuserid']),
              ...auth.mapState(['regionalpreferences', 'session','user']),
              ...auth.mapGetters(['currencyformat']),
              ...parameters.mapState(['listdefaultcountryparameters']),
             ...parameters.mapGetters(['filtercurr']),
        //     ...assets.mapMutations(['LOAD_ASSETS']),
             ...assets.mapActions(['loadassets']),


        


      },

   methods: {
        switchModal() {
            this.showModal = !this.showModal;
        } ,

        currformat(){
         const cfmt= this.filtercurr(this.currency);
         const currlogs={};
         if (cfmt.position==0) {currlogs.left=cfmt.logo; currlogs.right="" } else {currlogs.left=""; currlogs.right=cfmt.logo }
       return currlogs;
       },
   
    },


   async created() {  
        
    /*
    try {
          await 
        this.loadassets(this.user.Email );
      } catch (error) {
        console.log('error');
      }

     */ 
    
     
      this.country= this.regionalpreferences.country;  
        this.currency= this.regionalpreferences.currency;
        this.currformat();
        this.visible=true;
             

        this.componentKey += 1;

        console.log("getuserid : "); 
        console.log(this.getuserid); 
          
//          console.log("Ready")


      
    
        
     
     

         }

         

}

</script>

<style>

.mainframe{
display:flex;
flex-direction: column;
align-items: center;

}

.framecentral{
display:flex;
flex-direction: column;
justify-self:auto;
width: 420px;
justify-items: center;

}

form {
max-width: 420px;
margin:30px auto;
background: white;
text-align: left;
padding: 40px;
border-radius: 10px;
}
label{
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
input {
display: block;
padding: 10px 6px;
width: 100%;
box-sizing: border-box;
border: none;
border-bottom: 1px solid #ddd;
color: #5555;
}

div .textsummary{
display:flex;

width: 420px;

margin:auto;
margin-top:30px;
background: white;

padding: 1px 0 1px 5px;

/*
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
*/
justify-content: space-between;
align-content: center;

}

.box {
  width: 200px;
  height: 200px;
  margin-top: 20px;
  margin-left: -150px;
  position:absolute;
  background-color: rgb(171, 187, 219);
  box-shadow: rgba(108, 141, 213, 0.5) 0px 6px 20px;
  border-radius: 10px;
   z-index: 11;
}

div .secondsection{
display:flex;
flex-direction: column;
width: 420px;
/* z-index: -1;  */
}


.divportfolioprojection{
  display: flex;
  width:420px;
  margin:7px 0 15px 0;
  justify-content: flex-end;

}

.fademodal-enter-active, .fademodal-leave-active {
  transition: all  0.5s ease;
}
.fademodal-enter-from, .fademodal-leave-to /* .fade-leave-active below version 2.1.8 */ {
 opacity: 0 ;
}


.buttonmodal {
align-self: right;
height: 60px;
margin: 5px 10px 0 0 ;
color:grey;

}

.title{
     padding-left: 7px;
     font-size: 22px;
     font-weight: bold;
     color: #285fb3;
}

</style>
