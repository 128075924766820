<template>

    <div class="mainframe" :key="componentKey">
    
         <title>List of insurers</title>
  
          <div class="listinsurerscontent">
       
  
            <div class="lifeex-form-form">    
  
                            
    <div class="lifeex-form-form1"   >   

      <div class="title" @click="updateuserratings">
                                <p style="text-align:left" > Find insurers </p>
                                </div>
                        
    <hr class="new1"> 
  
       <div class="newclass"> 
          <label class="form-label2" for="customRange3" style="margin-right:10px">Country : </label>  
                  <select class="lecountryselect" name="country" v-model="country"  id="l_e_country" @change="updatecountry">
                      <option selected="" disabled="" value="">--Select Country--</option>
                      <option value="US">USA</option>
                      <option value="GB">UK</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>   
                  </select>
                  
                  <hr class="new1"> 
         </div>
  


      <div class="insurancelistsUL">
        <ul id="example-1"  ref = "ulist">


               <insurancetemplatetitles   class="insurancetemplatetitles"  />  
        
        
               <!-- 

                -->
                <li v-for="insurer in listinsurers" :key="insurer.ID">    
                      <div class="listofassets" >       
                            <Insurancetemplate  :InsuranceID=insurer.ID :Logo=insurer.Logo :Name=insurer.Name :Averagerating=insurer.Averagerating :Clientrating=insurer.Clientrating   class="insurancetemplate" :key="insurer.ID" />  
                     </div>         
               </li>
        
   

              

     </ul>


      </div> 
      
      

      <!-- 
  


        
      -->
       
           
      
           <!--
   <router-link   :to="{name: 'Catdetails', params:{ ID:asset.Type, curr_left:currency.left, curr_right:currency.right} }"  style="text-decoration: none; color: inherit">   
             </router-link> 
       --> 
 
 

    

  
  
        </div> 
        </div>   
</div> 
 </div>

  </template>
  
  <script>
  
  
  import {createNamespacedHelpers} from 'vuex'
  
  
  import axios from "axios";
  import {ref} from 'vue';
  import { createApp } from 'vue'
  
  import Insurancetemplate from "/src/components/Portfolio/components/insurancetemplate.vue";
  import Insurancetemplatetitles from "/src/components/Portfolio/components/insurancetemplatetitles.vue";
import { finished } from 'stream';

  
  

  
  
  
  const auth= createNamespacedHelpers('auth');
  const insurers = createNamespacedHelpers('insurers');

  //const componentKey=ref(0);
  
  
  export default {
    name: "Insurers",
    components: {Insurancetemplate, Insurancetemplatetitles},
/*
    setup(){
       const componentKey = ref (0);
       return {componentKey};
    },
*/
    data() {
      return {
        country: "US",
        listinsurers:{},
        listinsurers_temp:{},
        componentKey : 0,
        update:false,
      };
    },

      
 
  
   computed: {
     ...insurers.mapGetters(['insurerspercountry']),
     ...auth.mapState(['user']),
    

     //listinsurers = Object.assign({}, this.insurerspercountry("USA"))
    // listinsurers :  getters.insurerspercountry(this.country),
     
    async updatelist(){
      
      const result = await  this.Get_insurers({Country:this.country, ClientID:this.user.Email});
  
      console.log("result :  ");
      console.log(result);

     this.listinsurers = Object.assign({}, this.insurerspercountry(this.country));
     this.update=true;
   //  this.componentKey+=1;
   


/*
    this.Get_insurers({Country:this.country, ClientID:this.user.Email});
    this.listinsurers = Object.assign({}, this.insurerspercountry(this.country));
     this.update=true;
     this.componentKey+=1;
          
*/

   },
  

     },
  
  
  
  methods: {
    ...insurers.mapActions(['Get_insurers']), 


   

 
     unactivate() {
               setTimeout(() => this.update =false, 100)
      },


    async activate() {



                 this.updatelist; 
               
           /*    
                 this.componentKey+=1;
                this.update = true;

                  await nextTick()
                  this.$refs.ulist.focus()
            */
                       
            },
/*
      refreshColumns() {
        this.listinsurers_temp = Object.assign({}, this.listinsurers);
        this.listinsurers = {};
        this.$nextTick(() => {
            this.listinsurers = Object.assign({}, this.listinsurers_temp);
        });
    },
*/
    updatecountry(){
     
      this.listinsurers = Object.assign({}, this.insurerspercountry(this.country)); 
      this.activate;
      
     },

     updateuserratings(){
      // this.update=false;
           this.Get_insurers({Country:this.country, ClientID:this.user.Email});
           this.listinsurers = Object.assign({}, this.insurerspercountry(this.country)); 
           this.componentKey+=1;
     // this.update=true;
    },

   

     
    },
  

  



  

  
   
created(){
    // this.updatecountry;
    // this.update=false;

     // this.Get_insurers({Country:this.country, ClientID:this.user.Email});
     
  //    this.updateuserratings;

     //   this.listinsurers = this.insurerspercountry(this.country); 
//      this.listinsurers = Object.assign({}, this.insurerspercountry(this.country));

    
   //  this.unactivate;
   

      console.log(this.country);
      console.log(" listinsurers on created  :");
       console.log(this.listinsurers);

      
      this.updatelist; 
   //   this.activate;
      this.componentKey+=1;

//      this.refreshColumns ;
    
   
//   this.Get_insurers({Country:this.country, ClientID:"c@y.com"});

 // this.country="FR";
  
     
    
   //  this.updatecountry;
   /* 
   this.$nextTick(function(){
       this.componentKey++;
     });
    */

    
  //  this.listinsurers = Object.assign({}, this.insurerspercountry(this.country));
  // this.updatecountry;
   
   // this.listinsurers = Object.values(Object.assign({}, this.insurerspercountry(this.country)));
   //console.log(this.listinsurers[0]);
   },


   
  };
  </script>
  
  <style scoped>
  
  
  .mainframe{
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  
  justify-content: center;
  justify-items: center;
  padding-top: 40px;
  
  }
  

  .listinsurerscontent{
  
  }



  .insurancelistsUL{
    display: flex;
  flex-direction: column;

  }

  .lifeex-form-form{
    display:flex;
  }
  
  .title{
     margin-left: 2px;
     font-size: 18px;
     font-weight: bold;
     color: #285fb3;         
}


.listofassets{
    display: flex;
    padding: 0 10px;
}

ul{
    list-style-type: none;
    padding:0px;
    margin: 10px 0px;
  
}
  
li{

    margin: 5px 0px;
}
    

.insurancetemplatetitles{

}

.insurancetemplate{
  display:flex;
flex-wrap: wrap;
}
 
  
  </style>