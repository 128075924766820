//store/modules/auth.js

import axios from 'axios';

import { getCurrentScope } from "vue";

import parameters from './parameters'

import insurers from './insurers'



 function defaultstate ()  { return  {

//export  const state = {
    user: {Email:'Email'},
    posts: null,
    regionalpreferences : {country:"US", currency:"USD", language:"en"},
    localtionregionalpreferences : {country:"US", currency:"USD", language:"en"},
    session:false, 
    resultproj: {Lifeexpectancy:10, DurationRet:10,Projasset:{NW:0,A:0,L:0}, AnnuityRet:0},  
//    cors_host:8081,
     

  

//     webaddress1: 'http://localhost:'+8092+'/one/',
//     webaddress2: 'http://localhost:'+8092+'/two/',
     webaddress1: 'https://www.riskmet.com/',  
     webaddress2: 'http://ip-api.com/json',
}
};

const state =  defaultstate();

const API_URL = 'http://www.riskmet.com/';

//const state =  defaultstate();


const getters = {
    isAuthenticated: state => !!state.user,    
    StatePosts: state => state.posts,
   // StateUser: state => state.user,
    StateUser : state=>  state.user ,
    Getresultproj : state=>  state.resultproj,

    currencyformat(state,getters, rootState, rootGetters, dispatch){
     
         rootGetters.currformat;
        rootGetters['parameters'] ;
      //   rootGetters.someGetter 
        return  rootGetters['parameters/currformat'](state.regionalpreferences.currency);
  
    
     // return state.regionalpreferences.currency;
     //return  dispatch('parameters/currformat', state.regionalpreferences.currency, { root:true })
   // return dispatch.currformat( "EUR") ;
   //     return dispatch.currformat(state.regionalpreferences.currency);
   // return currformat(state.regionalpreferences.currency);
    
     },

     getAge(state) { // birthday is a date
      //  var datenew = Date.now() ;

        const birthday= state.user.Birthdate;

        console.log (birthday);
        var datenew = new Date();
       
       //    1991-06-04
     
         const ynew = datenew.getFullYear();
         const mnew = datenew.getMonth();
         const dnew = datenew.getDate();
         const yold = Number(birthday.substr(0, 4));
         const mold = Number(birthday.substr(5, 2));
         const dold = Number(birthday.substr(8, 2));
         
         const diffnumberdays= ynew*365+mnew*30+dnew- (yold*365+mold*30+dold);
         const age = Math.floor(diffnumberdays/365);
     
        return age;
      }
};
const actions = {
    async Register({dispatch}, form) {
        await axios.post(API_URL +'register', form)
        let UserForm = new FormData()
        UserForm.append('username', form.username)
        UserForm.append('password', form.password)
        await dispatch('LogIn', UserForm)
      },

    async LogIn({commit,state,getters,dispatch}, User) {
       // console.log(User)
      //  await axios.post(API_URL +'newlogin.php', User)
        // await  axios.post('http://localhost:8081/newlogin.php', User)
   //     console.log('state.cors_host');
     //   console.log(state.cors_host);

     //  await  axios.post('http://localhost:'+state.cors_host+'/one/newlogin.php', User).then((res) => {
        await  axios.post(state.webaddress1+'newlogin.php', User).then((res) => {
        
        const newuser=  { ...res.data.user};
      //  const str = JSON.stringify(res.data.user);
      //  const newuser= JSON.parse(str);
     
      
          console.log("res.data: " + res.data);
          console.log("res.data.user: " + res.data.user);
          console.log("newuser :  "); 
          console.log(newuser);
         
          commit('setSession',true);
          commit('setUser',newuser); 
          commit('UPDATE_REGIONDATA_STORE',{country: newuser.Country, currency:newuser.Currency, language:newuser.Language});
  
        //  dispatch('insurers/add', {}, { root: true })
  
         
      //     commit('assets/LOAD_ASSETS',newuser.Email, {root: true} );
              dispatch('assets/loadassets', newuser.Email, {root:true});
    
        //   rootActions['assets/loadassets'](newuser.Email);
       // rootGetters['parameters/currformat'](state.regionalpreferences.currency);
  
   
        })

        console.log("state.user  :   ");
                
        console.log(state.user);

        console.log("getters.StateUSer  :   ");
                
        console.log(getters.StateUser);
      //  console.log(User)
        //await commit('setUser', User.get('uid'))
      },
      
      /*
      async LogOut({commit}){
        let user = null
        commit('logout', user)
      }
      */

      async Updateuser({commit}, Vuser) {
        
     //   await  axios.post('http://localhost:'+state.cors_host+'/one/updateuser.php', Vuser).then((res) => {
      await  axios.post(state.webaddress1+'updateuser.php', Vuser).then((res) => {
     
        console.log(res);   
           const newuser=  { ...res.data.user};
           commit('setUser',Vuser.user); 
         //  console.log(newuser);   
         })
       //  console.log(User)
         //await commit('setUser', User.get('uid'))
       },
       


       async UPDATE_LOCALREGION ({commit,state}, newregion) {
        commit('UPDATE_LOCAL_REGIONDATA_STORE',newregion);
         },
     
       async UPDATE_REGION ({commit,state}, newregion) {
            commit('UPDATE_REGIONDATA_STORE',newregion);
          //  console.log(state.user); 
            const Userdata = {submit: true , user: state.user};
          if (state.session) {
           // await  axios.post('http://localhost:'+state.cors_host+'/one/updateuser.php', Userdata).then((res) => {
           await  axios.post(state.webaddress1+'updateuser.php', Userdata).then((res) => {
         
           // console.log(res);   
          //    const newuser=  { ...res.data.user};
              // commit('setUser',newuser); 
            //  console.log(newuser);   
            })
          }
        },


    
        resetstate: function ({commit, dispatch})  {
          commit('RESET_STATE');
         },


         logout({commit,dispatch}){
         
          commit('logOut');    
        //  dispatch('assets/emptystate');
          dispatch('assets/emptystate', null, {root:true})
         // rootActions['assets/emptystate'];
    
         },





};
const mutations = {
    setUser(state, usernew){
      
      console.log(usernew);
      //state.user =  {...usernew}; 
      
      //  state.user=new Object(usernew);

      // state.user= {a:'alfa'};
      state.user= Object.assign({}, usernew);
      // state.user =  Object.assign({}, usernew);
      console.log("state.user.Email");
        console.log(state.user.Email);
     //   
     //  Object.assign(state.user,usernew)

    },

    setresultproj(state, result){
      state.resultproj = result;
    },
    

    setSession(state, result){
      state.session = result
    },

    setPosts(state, posts){
        state.posts = posts
    },
    logOut(state){
        state.user = null;
        state.session = false;
    },

    UPDATE_REGIONDATA_STORE (state, newregion) {
       //  Object.assign(state.regionalpreferences,newregion);

         state.regionalpreferences= Object.assign({}, newregion);
        console.log ("regionalprefernce after login - newregion " + newregion + " - regionalpreferneces : " + state.regionalpreferences  ) 
       
        if (Object.keys(newregion.country).length !== 0) {
          state.user.Country=newregion.country;    
          state.regionalpreferences.country=newregion.country;    
           }
          else { state.user.Country=state.localtionregionalpreferences.country;
            state.regionalpreferences.country=newregion.country;    };

          if (Object.keys(newregion.language).length !== 0) {
            state.user.Language=newregion.language;
            state.regionalpreferences.language=newregion.language;}
           else { state.user.Language=state.localtionregionalpreferences.language;
            state.regionalpreferences.language=state.localtionregionalpreferences.language;};

           if (Object.keys(newregion.currency).length !== 0) {
            state.user.Currency=newregion.currency;
            state.regionalpreferences.currency=newregion.currency;}
             else { state.user.Currency=state.localtionregionalpreferences.currency;
              state.regionalpreferences.currency=state.localtionregionalpreferences.currency;};
       
         },
  
         UPDATE_LOCAL_REGIONDATA_STORE (state, newregion) {
        //  Object.assign(state.regionalpreferences,newregion);
           if (Object.keys(newregion.country).length !== 0) {
               state.localtionregionalpreferences.country=newregion.country;}
               else { state.localtionregionalpreferences.country="US";};
               if (Object.keys(newregion.language).length !== 0) {
                state.localtionregionalpreferences.language=newregion.language;}
                else { state.localtionregionalpreferences.language="en";};

                if (Object.keys(newregion.currency).length !== 0) {
                  state.localtionregionalpreferences.currency=newregion.currency;}
                  else { state.localtionregionalpreferences.currency="USD";};
            
      
      },

      RESET_STATE (state) {
        Object.assign(state, defaultstate())
        },


};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
