<template>

  <div class="annuity">
  

  
  <title>Annuity premium</title>

 



<div class="annuitycontent">
     

<div class="lifeex-form-form">    

  <div class="divtitle">
        <p class="title">Calculate annuity premium </p>
      </div>

  <div class="lifeex-form-form1">   

  <hr class="new1"> 


         <div class="newclass"> 
                        <label class="form-label2" for="customRange3">Amount of annuity desired <br> (paid monthly) : </label>  
                                 <div class="child-params1"> 

                                  <input class="form-control form-input3" type="text"  v-model="currencyformat.left" disabled > 
                                  <input class="form-control form-input2" type="text"  v-model="amount" @blur="inputblurr"  @focus="inputfocus" v-on:keyup.enter="inputblurr, $event.target.blur()" >   
                                   <input class="form-control form-input3" type="text"  v-model="currencyformat.right" disabled > 
                   

                                    </div>
                       
                    
            </div> 


 <hr class="new1"> 
        

<div class="radiobutton2">
      <label for="m">I want my annuity to increase with inflation ?  :</label>
       <div class="radiobuttondiv">  
         <input type="checkbox"  v-model="inflationlink">
       </div>
      </div>



  <hr class="new1"> 
   
        <div class="newclass"> 
       <label class="form-label2" for="customRange3">Retirement age : </label>  
          <div class="container-params">
                            <div class="child-params1"> 
                                     <div class="child-params1"> 
                                           <input class="form-control form-input2" type="number"   v-model="ageretire" min="20" max="80">   
                                         <input class="form-control form-input3" type="text"  placeholder="years" disabled > 
                                    </div>                            
                            </div>
                                       <div class="child-params2">    
                                        <input type="range" class="customrange" min="20" max="80" step="1" v-model="ageretire" > 
                                </div>       
           </div> 
      <hr class="new1"> 
      </div> 

     <div class="newclass"> 
                <label class="form-label2" for="customRange3">Country : </label>  
                <select class="lecountryselect" name="country" v-model="country"  id="l_e_country">
                    <option selected="" disabled="" value="">--Select Country--</option>
                    <option value="US">USA</option>
                    <option value="GB">UK</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>   
                </select>
                
                <hr class="new1"> 
       </div>


    <div class="radiobutton">
      <div class="radiobutton2">
      <label for="m">Male</label>
       <div class="radiobuttondiv">  
         <input type="radio" name="gender" value="M" checked="" class="radiogender"  v-model="gender" >   
       </div>
      </div>
      <div class="radiobutton2">
      <label for="f">Female</label>
      <div class="radiobuttondiv"> 
      <input type="radio" name="gender" value="F" class="radiogender"  v-model="gender"  >  
      </div>
      </div>
   <hr class="new1"> 
    </div>



   <div class="radiobutton">
      <div class="radiobutton2">
      <label for="s">Non-Smoker</label>
       <div class="radiobuttondiv">  
         <input type="radio" name="smoker" value="non-smoker" checked="" class="radiogender"  v-model="smoker" >   
       </div>
      </div>
      <div class="radiobutton2">
      <label for="ns">Smoker</label>
      <div class="radiobuttondiv"> 
      <input type="radio" name="smoker" value="smoker" class="radiogender"  v-model="smoker"  >  
      </div>
      </div>
   <hr class="new1"> 
    </div>




     <div class="newclass"> 
       <label class="form-label2" for="customRange3">Age : </label>  
          <div class="container-params">
                            <div class="child-params1"> 
                                     <div class="child-params1"> 
                                           <input class="form-control form-input2" type="number"   v-model="age" min="1" max="80">   
                                         <input class="form-control form-input3" type="text"  placeholder="years" disabled > 
                                    </div>                            
                            </div>
                                       <div class="child-params2">    
                                        <input type="range" class="customrange" min="1" max="80" step="1" v-model="age" > 
                                </div>       
           </div> 
      <hr class="new1"> 
      </div> 



       <div class="newclass"> 
               <label class="form-label2" for="customRange3">Premium payment frequency : : </label>  
                <select class="lecountryselect" name="country" v-model="premiumfreq"  >
                    <option selected="" disabled="" value="Monthly">--Select premium frequency--</option>
                    <option value="Single">Single</option>
                    <option value="Annual">Annual</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Monthly">Monthly</option>   
                </select>
                
                <hr class="new1"> 
       </div>



<!--
     <input type="text" name="age"  value="20" placeholder="20...", id='l_e_age'>


<button  type="submit" name="submit" onclick="Lifeinsurance();"> calculate </button>
-->
    
    </div>   


    
 

  
  
  <div class="container-params">
      <button class="button" name="submit2" @click="submit"> Calculate</button>    
  </div>

  <div class="contentresult">
                 <hr class="new1"> 
               <p style=" text-align:left; margin:0px; padding:0px;"> 
                 
                   The {{premiumfreq.toLowerCase() }} cost of your annuity is :
              </p>
                 <div class="divresultyears">
                      <b> {{currencyformat.left + ' '+ annuityprice + ' ' +currencyformat.right}} </b> 
                 </div>
         

                
            
            
          

          

         
    </div>

</div>



 

</div>





  </div>
</template>

<script>


import {createNamespacedHelpers} from 'vuex'


import axios from "axios";


const auth= createNamespacedHelpers('auth');
const parameters = createNamespacedHelpers('parameters');



export default {
  name: "Annuity",
  components: {},
  data() {
    return {
      age:20,
      country:"US",
      gender:"M",
      ageretire:65,
      premiumfreq:"Quarterly",
      amount: "1,000",
      inflationlink:false,
      currency:'EUR',
      smoker:'non-smoker',
      currparam:  {code:'USD', logo: '$' , designation:'United States Dollar', position: 0  }, 

      form: {
        username: "",
        password: "",
      },
      showError: false,
      annuityprice:0,

   

    };
  },



 computed: {
   ...auth.mapState(['regionalpreferences','cors_host','webaddress1']),
   ...auth.mapGetters(['currencyformat']),
         ...parameters.mapState(['listdefaultcountryparameters']),
       ...parameters.mapGetters(['filtercurr']),

   
   },


  methods: {

async submit() {
       
         const mdata = {"country": this.country, "gender": this.gender, "age": this.age,
       "smoker": this.smoker, "premfreq": this.premiumfreq, "currency": this.currency,
       "ageretire": this.ageretire,"amount": this.Commastonumber(this.amount)};
      

  
       console.log(mdata);

      try {
         // await   axios.post('http://localhost:'+this.cors_host+'/one/include/annuity.inc.php', mdata).then((res) => {
            await   axios.post(this.webaddress1+'include/annuity.inc.php', mdata).then((res) => {
         
            console.log(res.data);
              this.annuityprice=this.numberWithCommas((res.data).toFixed(2));
           
          })
                 
          this.showError = false;
          
      } catch (error) {
        this.showError = true
      }
      
   },


        numberWithCommas(x) {
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        },

        Commastonumber(x) {
           const parts = x.replace(/,/g, '');         
          return parseFloat(parts).toFixed(2);
        },
       

       inputblurr(){
            this.amount=this.numberWithCommas(this.amount);
       },

         inputfocus(){
            this.amount=this.Commastonumber(this.amount);
       },





    },

    created() {  
      this.country= this.regionalpreferences.country;
      this.currency= this.regionalpreferences.currency;
       
  },
 
};
</script>

<style scoped>


.annuity{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;

justify-content: center;
justify-items: center;
padding-top: 40px;

}

.divtitle{
     margin: 0px;
    padding:0px;
     
}

.title{
     margin-left: 2px;
     text-align: left;
     font-size: 18px;
     font-weight: bold;
     color: #285fb3;         
}




.annuitycontent{
display: flex;
flex-direction: column;
align-self: center;
justify-self: center;
width:250px;
justify-content: center;
justify-items: center;
}

.lifeex-form-form{
display:flex;
flex-direction: column;
width: 100%;
margin:0px;
padding: 0px;;
}

.lifeex-form-form1{
    display: flex;
    flex-direction: column;
}



.lecountryselect{
 margin:10px 0 10px 0;
 
}


.radiobutton {
    display: flex ;
    flex-direction: column;
}

.radiobutton2{
    display: flex; 
     justify-items: flex-start;
  flex-direction: row;
  min-height: 100%;
  justify-content: space-between;
  
}

 .radiobutton2 label {
      display:flex;
      flex:5;
      align-items: flex-end;
      justify-content: flex-start;
      align-content: flex-start;
      align-self: flex-start;
      text-align: start;
   }

  .radiobuttondiv {
   display: flex;
   flex: 1;

   margin: 0px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 100%;


   } 

  .radiogender {
    
   margin:10px;
   
   }

.newclass{
    display: flex;
    flex-direction: column;
}




.form-label2{
    align-self: flex-start;
     text-align: left;
}

.form-control.form-input2{
    flex:3;
    text-align: center;
}
.form-control.form-input3{
    flex:1;
    text-align: center;
    border:none;
}


.form-control.form-input3:disabled{
    background-color: inherit;
}

 .child-params1{
   display: flex;
 }

button  {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 20px 00px;
  width: 100%;
}
button:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}


.contentresult{
    display: flex;
    flex-direction: column;

}

.tablelifeex {
  width:100%;
  margin-bottom: 50px;
}

 .tablelifeex   tbody {
      width:100%;
         box-shadow: 5px 5px 5px rgb(178, 179, 180);
    }




  .tablelifeex td{
  padding: 5px 10px 5px 10px; 
  font-size:15px;
  border: solid 1px rgb(194, 192, 192);
 }
 
 .tablelifeex th{
  padding: 10px; 
  font-size:15px;
  border: solid 2px rgb(194, 192, 192);
 }

 .divresultyears{
     border: solid 1px rgb(194, 192, 192);
     margin: 10px 0  20px 0 ;
     padding: 5px 0 5px 0;
    box-shadow: 5px 5px 5px rgb(178, 179, 180);


 }
 

#error {
  color: red;
}



</style>