<template>
 <div class="assets">
     <ul id="example-1">
      <li v-for="asset in assets" :key="asset.id">
        <div>
          <p> This is my Asset component {{asset.id}}</p>

      

        </div>
        
      <AssetComponent :allparams=asset :assetid=asset.id  />  
         <!--    <input type="text" v-model="newinfo"  >
      <button @click="addTodo(newtodo)">  Add</button>  
         <p>
           <label >   Value : {{newinfo}} </label>   
         </p>-->
        
       </li>
     </ul>
 </div>

</template>

<script>
import { ref } from 'vue'
import AssetComponent from './AssetComponent.vue'
import {createNamespacedHelpers} from 'vuex'
// import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets');


export default {
 name: 'Assets',
 components: {AssetComponent},
   data() { 
        //newinfo:'Hello'
     // const newtodo=''
    return {newinfo:'hello'    
          } },
   setup(){
        const items = ref([ 
           {note: 'alfa'}, {note:'bete'}, {note:'zeta'}, {note:'gogo'}]);
        return {
            items
        };
        },
    computed : {
      //  ...mapGetters(['info']),
   //   ...mapState('info'])
        
   // mapState({     info: state => state.info  })
  //   ...mapState({    info: state => state.todos.info , todos : state =>state.todos.todos  }),
     ...mapState({   assets: state=>state.assets  }),
     
  //   ...mapState({info:"todos/info" , todos : "todos/todos" }),
       
  //   newtodo: () => { id: 4, title; newinfo; },
 
 


   //  onExecute:() => {this.addtodo(this.newtodo)}
      // newtodo() {  return{id: (length(this.todos)+1), title : this.newinfo}} 
     },
     methods : {
      //   ...mapActions( "todos", ["addTodoAction"]),

//         ...mapActions(  ["todos/addTodoAction"]),
  /*
  ...mapActions(  ["addTodoAction"]),
         addTodo: function(todo) {
          this.addTodoAction(todo);
 
      // this.$store.dispatch("addTodoAction", todo)
          //  this.$store.commit('addTodo', todo)
        

        //this.newinfo=todo.title;
          this.newinfo="tt";
       } 
       */
         }
     
     
}

 
</script>

<style>
div .asset {
max-width: 420px;
margin:30px auto;
background: white;
text-align: left;
padding: 10px;
border-radius: 3px;
}
label{
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
input {
display: block;
padding: 10px 6px;
width: 100%;
box-sizing: border-box;
border: none;
border-bottom: 1px solid #ddd;
color: #5555;
}
</style>