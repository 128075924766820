<template>

 <div class="assetitem">

     
       

   <div class="div1">
      <router-link  :to="{name:  'Assetparameters', params:{ ID:assetID, curr_left:curr_left, curr_right:curr_right, BSCat:BSCat }  }"    style="text-decoration: none; color: inherit; display:flex; width:100%">
    
         <p  class="p_assettype"> {{designation}} : </p>   
            <div class="divlabelammount"> 
             <p class="p_amount"> {{formatPrice(assetval)}} </p>   
          </div>
        </router-link> 
    </div>

 

   <!--      
   <div class="div2">
     -->
    <!--     <p style="font-size: 1em; colour: green; font-weight: bold; flex:1; margin:10px 0 2px 10px;" >  {{assetval}}  </p>      
-->
<!-- 

          <Inputnumber   :value=assetval  symbol_left="€" symbol_right=""    />  
                   
    </div> 
-->
     




   <div class="div3">
           <div class="dropdownB"  @mouseover="hoverOverI" @mouseleave="hoverOutI" >
        
         <fa class="ellipsis-v" icon="ellipsis-v" />

        <transition name="tdropdown">    
          <div class="dropdownB-content" v-if="show">
          <!--      <a href="#">Modify</a>    
                 <router-link :to="{ name: 'Catdetails' }">Show details</router-link>


   
-->
      <router-link  :to="{name: link, params:{ ID:assetID, curr_left:curr_left, curr_right:curr_right, BSCat:BSCat }  }" > Show details </router-link> 
    
          

      <!--  
         <router-link  :to="{name: 'Catdetails', params: { assetcat:assettype } }" > Show details </router-link> 


           <router-link  :to="{name: 'Catdetails', params: { assetcat:assettype } }" > Show details </router-link> 

    <router-link   :to="{name: 'Assetparameters', params: { ID: asset.ID} } "  style="text-decoration: none; color: inherit;">
    
                            <a href="#"  @click="console.log(assetID)">Delete</a>

                                 <a href="#"  @click="deleteasset(assetID)">Delete</a> 
    -->
 

           

                <a href="#"  @click="deleteassetfunction()">Delete</a>
               
            </div>
         </transition>
            </div>
    </div> 
   
  

 </div>

</template>

<script>
import { ref } from 'vue'

import {createNamespacedHelpers} from 'vuex'
import Inputnumber from './components/Inputnumber.vue'

const assets = createNamespacedHelpers('assets');

export default {
 name: 'AssetItem',
  components: { Inputnumber },
 props:{assettype:{type:String, default: "cash"}, designation:{type:String, default: "cash"},  assetval:{type: Number, default: 0.00},currencyformat:{type: Object,  default: () => ({}),}, link:{type:String, default: "Assetparaneters"}, assetID:{type:Number, default: ""},
 BSCat:{type:String, default: "Assets"},
 curr_left:{type: String, default: '$'}, curr_right:{type: String, default: ''} ,
 //ckey:{type: [Number,String], default: 1}
},

   data() { 
     return { show: false,
      currparam:  {code:'USD', logo: '$' , designation:'United States Dollar', position: 0  }, 
           }
       },
     methods : {

      ...assets.mapActions(['deleteasset']),


      
      hoverOverI: function() {     
        this.show = true
       
        },

      hoverOutI: function() {
        this.show = false
         },

          formatPrice(value) {
                 
          //  var   val= this.currencyformat.left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currencyformat.right;              
            var   val= this.curr_left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.curr_right;              
         
            
            return val;
                    },


            async deleteassetfunction(event){

                const result =  await this.deleteasset(this.assetID); 

             //   const newkey= Number(this.ckey)+1;

                this.$emit('delete');
              

            //    this.$router.push({ name: 'Catdetails', params: { ID: this.assettype, BSCat: this.BSCat, ckey: newkey} })


                },


      

         },

     

     
}

 
</script>

<style scoped>

div .assetitem {
display:flex;
justify-content: space-between;
align-items: flex-start;


margin:2px 5px 2px 5px ;
background: white;
padding: 0px;
border-radius: 3px;
border-bottom: 1px solid rgb(204, 204, 211);
}



div .div1 {
display:flex;
flex: 3;
}

.div2 p{
text-align:right;
margin-right: 10px;
}

div .div2 {
display:flex;
flex: 2;
align-content: flex-end;
justify-content: right;
}

div .div3 {
display:flex;
flex: 1;
align-content: flex-end;
justify-content: right;
max-width: 40px;

}

.dropdownB {
  position: relative;
  display: inline-block;
  margin-right: 3px;
}




.ellipsis-v{
     margin-left: auto;
     font-size: 1.03em !important; 
     color:rgb(153, 153, 163) ; 
     margin : 10px 10px 5px 5px ;
   float:right;
}




.dropdownB-content {
  display:block;  
  position: absolute;
  background-color: rgb(253, 250, 250);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  right:0; 
  transform-origin: top ;
  overflow: hidden;
  z-index: 10;

}

.dropdownB-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;

}

.dropdownB-content a:hover {background-color: #ddd;}

.dropdownB:hover .dropdownB-content {display: block;}

.dropdownB:hover .dropbtn {background-color: #3e8e41;}

.tdropdown-enter-from,
.tdropdown-leave-to 
{
    transform: scaleY(0);
    opacity: 0;
}



.tdropdown-enter-active, .tdropdown-leave-active {
transition: all .4s ease-in-out;
}



.divlabelammount {
  display:flex;
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
  background: white;
  height: 45px;
  max-width: 300px;
  border: none;
  align-self: right;
  flex:3;

}

.p_amount{ 
  font-size: 0.9em; colour: rgb(73, 78, 73); font-weight: 500; flex:1; margin:10px 10px 2px 10px; text-align: right;
 }


.p_assettype{
font-size: 1em; colour: rgb(180, 15, 15); font-weight:400; flex:3; margin:10px 0 2px 10px; 
}




</style>