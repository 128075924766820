<template>
    <div class="mainframe" >
    
    
    <div class="textsummary">
    
       <!--
        <h3  style="text-align: center; ; font-weight: bold;  "> My Wealth  </h3>
    
         -->
         <p class="title"> Summary Report  </p>
    
      <div >
         
         <!--
    
    
          <button class="buttonmodal" id="show-modal" @click="showModal = true">Show Modal</button>  
         -->
         <!-- use the modal component, pass in the prop -->
          

       
       </div>
    
    <!--
      <fade-transition>
        <div v-if="show" class="box"></div>
      </fade-transition>
    -->
    
      </div>
    
    
       <div class="secondsection">
     
    <!--
    -->
   
    <Summaryuserinfo    :Age="getAge" :RetireAge="parseFloat(user.RetirementAge)" :Lifeexpect="lifeexpectance" :DurationRetire="tempresults.DurationRet"  style="flex:1"  />  
   
    <Summaryprojection  style="flex:1" :CurrentWealth="totalAL.Assets-totalAL.Liabilities"  :WealthRet="TotalBSProj.W[Object.keys(TotalBSProj.W).length-1]"   :IncomeRet="Number(differredincome)"   />  
   
    
    
    
    </div>
    <!--
         <Addassetcategory  style="flex:1"  />
    <BSSummary  assetval="100" , liabval="90" , netassets="10" />
    
        
         <LiabilitiesList />
     <NetAssets />
    -->
    
    
    </div>
    
    </template>
    
    <script>
    import AssetsList from './AssetsList.vue'
    import BSSummary from './BSSummary.vue'
    import Summaryuserinfo from './components/Summaryuserinfo.vue'
    import Summaryprojection from './components/Summaryprojection.vue'
    import Addassetcategory from './AddAssetCategory.vue'
    // import transitionassetclass from './transitionassetclass.vue'
    import Modal from "../comps/Modal.vue";
    import {createNamespacedHelpers} from 'vuex'
    
   
     import axios from "axios";
    
    
    // import LiabilitiesList from './LiabilitiesList.vue'
    // import NetAssets from './NetAssets.vue'
    const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets');
    const auth= createNamespacedHelpers('auth');
  

    const parameters = createNamespacedHelpers('parameters');
    
    export default {
         name: 'Summaryreport',
    
        components: {Summaryuserinfo, Summaryprojection}, 
       
       data() {
        return {

            tempresults:{ Lifeexpectancy: 0, DurationRet:0, Lifeexpectancy:0, Projasset:0, AnnuityRet:0},
            lifeexpectance:30,

            pyear:10, projections:[], assetparameters:{}, defaultassetparameters:{}, dateconv : new Date().toISOString().substr(0, 10),
           YC:[0.011,0.011, 0.011, 0.011, 0.011,0.011,0.011,0.011,0.011,0.011],
            assetbond:  {
            ID:2,
            BS:'A', 
            Type:"Bond",
            Designation: "Bond company A",
            Value:0,
                            IR:0.1,
                            CG:0.1,
                            Duration:10,
                            Nominal:1,
            Issue_date:"2010-09-09",
           },
           TotalBSProj:{A:[0],L:[0], W:[0]},
           chartvisible:false,
           differredincome:0,
          //  resultproj:''
        

        }
       },
    
    
    computed : {
      
                  ...mapGetters(['totalAL', 'getuserid']),
                  ...auth.mapState(['regionalpreferences', 'session','user','webaddress1']),
                  ...auth.mapGetters(['currencyformat', 'getAge','Getresultproj']),
                  ...auth.mapMutations(['setresultproj']),
      //           ...parameters.mapState(['listdefaultcountryparameters']),
      //           ...parameters.mapGetters(['filtercurr']),
                 ...mapMutations(['LOAD_ASSETS']),

                 ...mapState({  assetslist: state=>state.assetslist1}),
    
   
          },
    
       methods: {
     /*
            currformat(){
             const cfmt= this.filtercurr(this.currency);
             const currlogs={};
             if (cfmt.position==0) {currlogs.left=cfmt.logo; currlogs.right="" } else {currlogs.left=""; currlogs.right=cfmt.logo }
           return currlogs;
           },
   */


   
        
   async lifeexpectancy() {
            console.log("lifeexepectancy function have been launched"); 
            
            const mdata = {"country": this.user.Country, "gender": this.user.Gender, "age": this.getAge};
            console.log('mdata');   
            console.log(mdata);
            

            try {
                 await   axios.post(this.webaddress1+'include/lifeexpectancy.inc.php', mdata).then((res) => {
               
               // this.lifeexpectance= parseFloat(res.data.Ex);
                  this.lifeexpectance= parseFloat(parseFloat(res.data.Ex).toFixed(2));
                // this.Proba=res.data.Surv;
                console.log('this.lifeexpectance');
                console.log(this.lifeexpectance);
                console.log("res.data.Ex");

                console.log(res.data.Ex);

                })
                      
                this.showError = false;
              
                
            } catch (error) {
              this.showError = true
              console.log("error");
            }

            return "completed";
            console.log("completed");
            
        },


       async updateprojections(){
            const result =   await this.lifeexpectancy();
            const resultproj= {Lifeexpectancy:this.tempresults.Lifeexpectancy, DurationRet:this.tempresults.DurationRet,Projasset:this.tempresults.Projasset, AnnuityRet:this.tempresultsAnnuityRet};  
          
           
            resultproj.Lifeexpectancy = this.lifeexpectance;
            console.log('this.getAge');
            console.log(this.getAge);

            console.log('this.lifeexpectance ');
            console.log(this.lifeexpectance);

            resultproj.DurationRet = parseFloat((this.getAge + this.lifeexpectance - parseFloat(this.user.RetirementAge)).toFixed(2));

          // resultproj: {Lifeexpectancy:10, DurationRet:10,Projasset:{NW:0,A:0,L:0}, AnnuityRet:0},  
            this.tempresults.Lifeexpectancy= resultproj.Lifeexpectancy;
            this.tempresults.DurationRet= resultproj.DurationRet;
            this.tempresults.Lifeexpectancy= resultproj.Lifeexpectancy;
          
          
          console.log('Get Resultprojs :' );
            console.log( this.tempresults);
         //   this.setresultproj(this.tempresults);
          
         } ,




         formatPrice(value) {
                 
                 //  var   val= this.currencyformat.left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currencyformat.right;              
                   var   val= this.currencyformat.left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currencyformat.right;              
     
                   return val;
                           },
       

        updateformat (value, format) {
        //    if (format!=="T" && value!=null){this.$emit('newfooevent', parseFloat(value).toFixed(this.paramformat))}
        // return "AA";
        var tvalue;
        if (format!=="T" && value!=null) {tvalue= parseFloat(parseFloat(value).toFixed(format))} else if (value!=null) {tvalue = value} ;
        return tvalue;
        },
     
         projectasset(Nominal,ir, N) {
            
            var tvalue={value:10, Cashg:5, Capg:0};
            var nproj = [];
           for (let i = 0; i < 10; i++) {
                tvalue={value:10, Cashg:ir*Nominal, Capg:0};
                if (i==Mat){tvalue.Cashg+=Nominal; Nominal=0 }
                nproj.push({year:i+1, proj:tvalue});
                };
           this.projections = Object.assign({}, nproj); 
              
            },

        //  CREATION OF FUNCTIONS TO PROJECT ASSET CLASSES
        // THIS SECTION WILL HAVE TO BE MOVED INTO A SEPARATE FILE

        Project_Bond(asset, yc, N){
 
       //   var asset=this.assetbond;
       //   var yc=this.YC;
       //   var N=6;

           var projasset= {
            ID:asset.ID,
            BS: asset.BS, 
            Type: asset.Type,
            Designation: asset.Designation,
            Value:parseFloat(asset.Value),
                            IR:parseFloat(asset.IR),
                            CG:parseFloat(asset.CG0),
                            Duration:parseFloat(asset.Duration),
                            Nominal:parseFloat(asset.Nominal),
            Issue_date:asset.Issue_date,
            ValueP:[],
            CapGrowth: [],
            Cash_outflow:[]
            };


          // I need to perform projection till maturity of asset in order to be able to calculate prices
          
          // define an intermediate variable for bond price calculations calculations
         

          var bond= {value : [], cash:[], capg:[]};
          var M=asset.Duration;  
            bond.cash[0]=0;
            for (let i = 1; i <= M; i++) {
                bond.cash[i]=parseFloat(asset.Nominal)*parseFloat(asset.IR);             
                };
           bond.cash[M]+=parseFloat(asset.Nominal);

           bond.value[M]=0;
            for (let i = M-1; i >=0; i--) {
                bond.value[i]= (bond.value[i+1]+bond.cash[i+1])/(1+yc[i]);
                };
            
            // Perfrorm adjustment

            
            if (bond.value!=0 ){
             for (let i = M-1; i >= 0; i--) {
                bond.value[i]=bond.value[i]*parseFloat(asset.Value)/bond.value[0];             
                }};

          

            if (M<=N){

              for (let i = 0; i <=M ; i++) {
               projasset.ValueP[i] = bond.value [i];
               projasset.Cash_outflow[i] = bond.cash[i];
                      
                };  
              
             
             for (let i = M+1; i <=N ; i++) {
                projasset.ValueP[i] = 0 ;
                projasset.Cash_outflow[i] = 0;
                      
                };  
            };

             if (M>N){
           
             for (let i = 0; i <=N ; i++) {
                projasset.ValueP[i] = bond.value[i] ;
                projasset.Cash_outflow[i] = bond.cash[i];           
                };  
            };

             projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0)); ;

         // this.projections = Object.assign({}, projasset); 
         
         return projasset;

        },

        
  
       

        Project_Cash(asset, yc, N){

  console.log("asset :  in cahs projection");
  console.log(asset );



                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value: parseFloat(asset.Value),
                      IR: parseFloat(asset.IR),
                      CG: parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };
                    
                      console.log(asset.IR);
                      console.log("cash projection  : "); 
                      console.log(projasset);

                    var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=parseFloat(asset.Value);
                    projasset.Cash_outflow[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1]  ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR)); 
                          };  
                     

                      projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0));
               
             // this.projections = Object.assign({}, projasset); 
         
         return projasset;
                
        },
        



        Project_Equity(asset, yc, N){
                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value:parseFloat(asset.Value),
                      IR:parseFloat(asset.IR),
                      CG:parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };


                 //   var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=asset.Value;
                    projasset.Cash_outflow[0]=0;
                    projasset.CapGrowth[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1] * (1+parseFloat(asset.CG)) ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR));
                          projasset.CapGrowth[i] = projasset.ValueP[i-1] * parseFloat((asset.CG));                         
                          };  
                     
               
                   // this.projections = Object.assign({}, projasset); 
                       
         return projasset;
                
        },
        
        Project_Property(asset, yc, N){
                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value:parseFloat(asset.Value),
                      IR:parseFloat(asset.IR),
                      CG:parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };


                    var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=parseFloat(asset.Value);
                    projasset.Cash_outflow[0]=0;
                    projasset.CapGrowth[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1] * (1+parseFloat(asset.CG)) ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR));
                          projasset.CapGrowth[i] = projasset.ValueP[i-1] * parseFloat(asset.CG);                         
                          };  
                     
               
                  // this.projections = Object.assign({}, projasset); 


                  
         return projasset;
                
        },





        Project_Fund(asset, yc, N){
                var projasset= {
                      ID:asset.ID,
                      BS: asset.BS, 
                      Type: asset.Type,
                      Designation: asset.Designation,
                      Value:parseFloat(asset.Value),
                      IR:parseFloat(asset.IR),
                      CG:parseFloat(asset.CG),
                      Duration:parseFloat(asset.Duration),
                      Nominal:parseFloat(asset.Nominal),
                      Issue_date:asset.Issue_date,
                      ValueP:[],
                      CapGrowth: [],
                      Cash_outflow:[]
                      };


                 //   var bond= {value : [], cash:[], capg:[]};
                    
                    projasset.ValueP[0]=asset.Value;
                    projasset.Cash_outflow[0]=0;
                    projasset.CapGrowth[0]=0;
                
                      for (let i = 1; i <=N ; i++) {
                          projasset.ValueP[i] = projasset.ValueP[i-1] * (1+parseFloat(asset.CG)) ;
                          projasset.Cash_outflow[i] = projasset.ValueP[i-1] * (parseFloat(asset.IR));
                          projasset.CapGrowth[i] = projasset.ValueP[i-1] * parseFloat((asset.CG));                         
                          };  
                     
               
                   // this.projections = Object.assign({}, projasset); 
                       
         return projasset;
                
        },

        /*
        ***  LOAN AND MORTAGE

        */
        
        Project_Debt(asset, yc, N){
 
                          var projasset= {
                            ID:asset.ID,
                            BS: asset.BS, 
                            Type: asset.Type,
                            Designation: asset.Designation,
                            Value:parseFloat(asset.Value),
                            IR:parseFloat(asset.IR),
                            CG:parseFloat(asset.CG0),
                            Duration:parseFloat(asset.Duration),
                            Nominal:parseFloat(asset.Nominal),
                            Issue_date:asset.Issue_date,
                            ValueP:[],
                            CapGrowth: [],
                            Cash_outflow:[]
                            };


                     // calculate the monthly paymenbt
                     var M=asset.Duration;  

                     var ia = asset.IR;
                     var im = (1+ia)**(1/12)-1;
                     var am = 1/(1+im);
                     var D = (1-am**(asset.Duration*12+1))/(1-am);
                     var MP=asset.Value/ (D-1);  // mmontly payment


                      var bond= {value : [], cash:[], capg:[]};
                      bond.value[0]= parseFloat(asset.Value);
                      bond.cash[0]=0;
                      bond.capg[0]=0;
                     
                      for (let i = 1; i <= M; i++) {
                                bond.value[i]=bond.value[i-1]+bond.value[i-1]*parseFloat(asset.IR)-MP*12; // this is a simplified calculation as I am displaying annual values, later should perform accurate calcualtions based on monthly payments
                                bond.cash[i]=-MP*12; 
                                };
                       

                     

                            if (M<=N){

                              for (let i = 0; i <=M ; i++) {
                              projasset.ValueP[i] = bond.value [i];
                              projasset.Cash_outflow[i] = bond.cash[i];
                                      
                                };  
                              
                            
                            for (let i = M+1; i <=N ; i++) {
                                projasset.ValueP[i] = 0 ;
                                projasset.Cash_outflow[i] = 0;
                                      
                                };  
                            };

                            if (M>N){
                          
                            for (let i = 0; i <=N ; i++) {
                                projasset.ValueP[i] = bond.value[i] ;
                                projasset.Cash_outflow[i] = bond.cash[i];           
                                };  
                            };

                            projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0)); 

                         // this.projections = Object.assign({}, projasset); 

                   //      console.log(bond);

                     //    console.log(projasset);
                  return projasset;
        },

        
        
        Project_Mortgage(asset, yc, N){
                          
                          var projasset= {
                            ID:asset.ID,
                            BS: asset.BS, 
                            Type: asset.Type,
                            Designation: asset.Designation,
                            Value:parseFloat(asset.Value),
                            IR:parseFloat(asset.IR),
                            CG:parseFloat(asset.CG0),
                            Duration:parseFloat(asset.Duration),
                            Nominal:parseFloat(asset.Nominal),
                            Issue_date:asset.Issue_date,
                            ValueP:[],
                            CapGrowth: [],
                            Cash_outflow:[]
                            };


                     // calculate the monthly paymenbt
                     var M=asset.Duration;  

                     var ia = asset.IR;
                     var im = (1+ia)**(1/12)-1;
                     var am = 1/(1+im);
                     var D = (1-am**(asset.Duration*12+1))/(1-am);
                     var MP=asset.Value/ (D-1);  // mmontly payment


                      var bond= {value : [], cash:[], capg:[]};
                      bond.value[0]= parseFloat(asset.Value);
                      bond.cash[0]=0;
                      bond.capg[0]=0;
                     
                      for (let i = 1; i <= M; i++) {
                                bond.value[i]=bond.value[i-1]+bond.value[i-1]*parseFloat(asset.IR)-MP*12; // this is a simplified calculation as I am displaying annual values, later should perform accurate calcualtions based on monthly payments
                                bond.cash[i]=-MP*12; 
                                };
                       

                     

                            if (M<=N){

                              for (let i = 0; i <=M ; i++) {
                              projasset.ValueP[i] = bond.value [i];
                              projasset.Cash_outflow[i] = bond.cash[i];
                                      
                                };  
                              
                            
                            for (let i = M+1; i <=N ; i++) {
                                projasset.ValueP[i] = 0 ;
                                projasset.Cash_outflow[i] = 0;
                                      
                                };  
                            };

                            if (M>N){
                          
                            for (let i = 0; i <=N ; i++) {
                                projasset.ValueP[i] = bond.value[i] ;
                                projasset.Cash_outflow[i] = bond.cash[i];           
                                };  
                            };

                            projasset.CapGrowth=Object.assign({}, Array(N+1).fill(0)); 

                         // this.projections = Object.assign({}, projasset); 

                     
                  return projasset;
        },




       //  ***************
       

         Project_TotalBS(yc, N){ 
          // Project_TotalBS(ALlist, yc, N){ 
         

       
         var CBproj =  Object.assign({}, Array(N+1).fill(0)); 
         var Aproj=Object.assign({}, Array(N+1).fill(0)); 
         var LiabProj= Object.assign({}, Array(N+1).fill(0)); 
         var WealthProj= Object.assign({}, Array(N+1).fill(0)); 

         var dcashi=0;  // this is the default chase increase
         
         // filter through all cash types of assets and check wich one is the default cash.
         //dcashi=asset.IR;
         
         var assetpj = [];
         var nbitem=0;
       
         // FILTER FOR CASH

         
         var newarray= this.assetslist.filter(pilot => pilot.Type === "Cash")  ; 
       
        // console.log(newarray) ;
        // console.log(this.assetslist) ;


         dcashi=newarray[0].IR;

       
        

      //   nbitem= newarray.length;
          nbitem= Object.keys(newarray).length;
          console.log("nbitem :  "  + nbitem);
       console.log("dcashi");
       console.log(dcashi);


   //    console.log("START ZERO  :");
     // console.log( CBproj);


         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                         //     console.log("ia  "  ) ;
                          //      console.log(ia    ) ;
                             assetpj =  Object.assign({}, this.Project_Cash(newarray[ia], yc, N)); 
                             console.log("newarray[ia]");
                             console.log(newarray[ia]) ;
                             //     console.log(assetpj.Cash_outflow) ;
                         //   console.log(assetpj.Cash_outflow[5]) ;
                          //   console.log(assetpj.ValueP) ;

                               for (let y = 0; y <=N ; y++) {
                                  
                                CBproj[y] += assetpj.Cash_outflow[y];
                              //  console.log("Cashoutflo : " + assetpj.Cash_outflow[y]) ;
                              
                                Aproj[y] += assetpj.ValueP[y];                    
                                };  

                                 console.log("Cash :");
                                  console.log( CBproj);

                             //   console.log(CBproj) ;
         } }
   
          

      
          // FILTER FOR PROPERTY


          Object.keys(newarray).forEach(key => newarray[key] = null);
   
         newarray = Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Property") ) ;  
      
         
        // var newarray= this.assetslist.filter(pilot => pilot.Type === "Cash")  ; 


        nbitem= Object.keys(newarray).length;
           


        


         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Property(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                               CBproj[y] += assetpj.Cash_outflow[y];
                               Aproj[y] += assetpj.ValueP[y];                    
                                };  
                        //   console.log("Property :" );
                       //     console.log(CBproj);
         } }
        

           // FILTER FOR Bond
        
         newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Bond") ) ;  
      
         nbitem= Object.keys(newarray).length;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Bond(newarray[ia], yc, N)); 


                                console.log("Bond Cash:" );
                                 console.log(assetpj.Cash_outflow);
                                console.log("Bond Value:" );
                                 console.log(assetpj.ValueP);

                               for (let y = 0; y <=N ; y++) {
                               CBproj[y] += assetpj.Cash_outflow[y];
                               Aproj[y] += assetpj.ValueP[y];                    
                                };  
                                console.log("Bond :" );
                                 console.log(CBproj);
         } }
         
          // FILTER FOR EQUITY
     
    
        newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Equity") ) ;  
      
         nbitem= Object.keys(newarray).length;

        // console.log(newarray) ;
          //console.log(nbitem) ;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Equity(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                                CBproj[y] += assetpj.Cash_outflow[y];
                                Aproj[y] += assetpj.ValueP[y];                    
                                };  
                      //          console.log("Equity :" );
                      //           console.log(CBproj);
         } }
         

            // FILTER FOR FUND
                
                
            newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Fund") ) ;  
                  
                  nbitem= Object.keys(newarray).length;

                // console.log(newarray) ;
                  //console.log(nbitem) ;

                  if (nbitem) {
                      for (let ia = 0; ia <nbitem ; ia++) {
                                      
                                      assetpj =  Object.assign({}, this.Project_Equity(newarray[ia], yc, N)); 

                                        for (let y = 0; y <=N ; y++) {
                                        CBproj[y] += assetpj.Cash_outflow[y];
                                        Aproj[y] += assetpj.ValueP[y];                    
                                        };  
                              //          console.log("Equity :" );
                              //           console.log(CBproj);
                  } }
                  



         // FILTER FOR MORTGAGE
         
        newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Mortgage") ) ;  
      
     
          nbitem= Object.keys(newarray).length;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Mortgage(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                                CBproj[y] += assetpj.Cash_outflow[y];
                                LiabProj[y] += assetpj.ValueP[y];                    
                                };  
                         //       console.log("Mortgage :" );
                         //        console.log(CBproj);
         } }


         // FILTER FOR DEBT
         
        newarray= Object.assign({}, this.assetslist.filter(pilot => pilot.Type === "Debt") ) ;  
      
          nbitem= Object.keys(newarray).length;

         if (nbitem) {
              for (let ia = 0; ia <nbitem ; ia++) {
                              
                             assetpj =  Object.assign({}, this.Project_Debt(newarray[ia], yc, N)); 

                               for (let y = 0; y <=N ; y++) {
                                CBproj[y] += assetpj.Cash_outflow[y];
                                LiabProj[y] += assetpj.ValueP[y];                    
                                };  
                          //      console.log("Debt :" );
                          //       console.log(CBproj);
         } }

    

          for (let y = 1; y <=N ; y++) {                     
                               CBproj[y]  += CBproj[y-1]*(1+dcashi);                     
                                };  


          for (let y = 0; y <=N ; y++) {                     
                              Aproj[y]  += CBproj[y]; 
                              WealthProj[y]= Aproj[y]-LiabProj[y];                   
                                };  




         // go throught eac asset in assetlist
         // for BS type and each asset type apply filter and check if the list is not emply
         // apply the right projection function
         // var proj = asetprojec(asset,yc,N)
         // for i=1 to N CBproj[i]+=proj.Cash_outflow[i]
         //
         // for Assets:  Aproj[i]=proj.value[i]
         // for Liabilities : Liabproj[i]=proj.value[i]

         // when all calclations have been completed. calculated interest increased value of Cash
                 
         // for i=1 to N
         //CBproj[i]+=CBproj[i-1]*dcashi;

         // finally for i=0 to N
         //Aproj[i]+=CBproj[i];
        /* 
          */

         this.TotalBSProj = Object.assign({}, {A:Aproj, L:LiabProj, W: WealthProj}   ); 
        // LineChart.update();
         this.chartvisible=true;
       //  this.Linechart2.updateChart();  

         } ,
         
         

       //  ***************

   // Calcaulate the monthly retirement income 

  async getretincome() {
    
       // const user = {"country": this.country, "gender": this.gender, "age": 20, "smoker": "S" };
      
      const amount = (this.TotalBSProj.W[Object.keys(this.TotalBSProj.W).length-1]).toFixed(2);
       const mdata = {"submit": true , "country":  this.user.Country, "gender": this.user.Gender, "age": this.getAge,
     "smoker": this.user.Smoker,  "currency": this.user.Currency,
     "projy": Math.round(parseFloat(this.user.RetirementAge)-this.getAge),"amount": Number(amount)};
    
  
     console.log("  mdata from differred annuity income :  ");
     console.log(mdata);
     console.log(this.cors_host);

    try {

     // await axios.post('http://localhost:'+this.cors_host+'/one/lifemodels/differredannuityincome.php', mdata).then((res) => {     
        await axios.post(this.webaddress1+'lifemodels/differredannuityincome.php', mdata).then((res) => {     
         
    //    await   axios.post('http://localhost:'+this.cors_host+'/one/lifemodels/differredannuityincome.php', mdata).then((res) => {
          console.log("  differred annuity income :  ");
          console.log(res.data);

          console.log("  different annuity income :  ");
            this.differredincome=(Number(res.data)).toFixed(2);

           

            console.log(this.differredincome);
         
        })
               
        this.showError = false;
        
    } catch (error) {
      this.showError = true
    }
    
 },




///  **********  Calculate age

 calculateAge(birthday) { // birthday is a date
 //  var datenew = Date.now() ;
   var datenew = new Date()
  
  //    1991-06-04

    const ynew = datenew.getFullYear();
    const mnew = datenew.getMonth();
    const dnew = datenew.getDate();
    const yold = Number(birthday.substr(0, 4));
    const mold = Number(birthday.substr(5, 2));
    const dold = Number(birthday.substr(8, 2));
    
    const diffnumberdays= ynew*365+mnew*30+dnew- (yold*365+mold*30+dold);
    const age = Math.floor(diffnumberdays/365);

   return age;
 },


async getyc(){

  const mdata = {"submit": true , "ClientID":  this.user.Email,  "Currency": this.user.Currency };
    
  
     console.log("  downloading yc :  ");
     console.log(mdata);
     console.log(this.cors_host);

    try {

     // await axios.post('http://localhost:'+this.cors_host+'/one/assets/loadyc.php', mdata).then((res) => {     
        await axios.post(this.webaddress1+'assets/loadyc.php', mdata).then((res) => {     
         
    //    await   axios.post('http://localhost:'+this.cors_host+'/one/lifemodels/differredannuityincome.php', mdata).then((res) => {
          console.log(" yieldcurve ");
          console.log(res.data);

          console.log('myData');
      //   this.YC=res.data[Spot];
      var myData = Object.keys(res.data).map(key => {
                return res.data[key]['Spot'];
            });
     

         console.log(myData);
        console.log(this.YC);

      

          //  console.log(this.differredincome);
         
        })
               
        this.showError = false;
        return "finished";
        
    } catch (error) {
      this.showError = true
    }
   
},

async updateassetprojections(){
  const result =   await this.getyc();;

  this.Project_TotalBS(this.YC,Math.round(parseFloat(this.user.RetirementAge)-this.getAge));
  this.getretincome();

} 

       
        },
    
    
      created() {  
            
                    this.tempresults= this.Getresultproj;
                    this.updateprojections();
                    this.updateassetprojections();
      
             }
    
             
    
    }
    
    </script>
    
    <style>
    
    .mainframe{
    display:flex;
    flex-direction: column;
    align-items: center;
    
    }
    
    .framecentral{
    display:flex;
    flex-direction: column;
    justify-self:auto;
    width: 420px;
    justify-items: center;
    
    }
    
    form {
    max-width: 420px;
    margin:30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
    }
    label{
        color: #aaa;
        display: inline-block;
        margin: 25px 0 15px;
        font-size: 0.6em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
    }
    input {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #5555;
    }
    
    div .textsummary{
    display:flex;
    
    width: 420px;
    
    margin:auto;
    margin-top:30px;
    background: white;
    
    padding: 1px 0 1px 5px;
    
    /*
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 211);
    */
    justify-content: space-between;
    align-content: center;
    
    }
    
    .box {
      width: 200px;
      height: 200px;
      margin-top: 20px;
      margin-left: -150px;
      position:absolute;
      background-color: rgb(171, 187, 219);
      box-shadow: rgba(108, 141, 213, 0.5) 0px 6px 20px;
      border-radius: 10px;
       z-index: 11;
    }
    
    div .secondsection{
    display:flex;
    flex-direction: column;
    width: 420px;
    /* z-index: -1;  */
    }
    
    
    .divportfolioprojection{
      display: flex;
      width:420px;
      margin:7px 0 15px 0;
      justify-content: flex-end;
    
    }
    
    .fademodal-enter-active, .fademodal-leave-active {
      transition: all  0.5s ease;
    }
    .fademodal-enter-from, .fademodal-leave-to /* .fade-leave-active below version 2.1.8 */ {
     opacity: 0 ;
    }
    
    
    .buttonmodal {
    align-self: right;
    height: 60px;
    margin: 5px 10px 0 0 ;
    color:grey;
    
    }
    
    .title{
         padding-left: 7px;
         font-size: 22px;
         font-weight: bold;
         color: #285fb3;
    }
    
    </style>
    