<template>
  <div class="div_lineChart" >





      <!--
    <vue3-chart-js
        :id="LineChart.id"
        :type="LineChart.type"
        :data="LineChart.data"
        @before-render="beforeRenderLogic"
        :options="LineChart.options"
    ></vue3-chart-js>

-->
   <vue3-chart-js v-bind="{ ...lineChart }" />

  </div>
</template>

<script>
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import 'chart.js-plugin-labels-dv'
//import { computed } from '@vue/reactivity'

//import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import zoomPlugin from "chartjs-plugin-zoom";
//import dataLabels from "chartjs-plugin-datalabels";


//import mixins from '@j-t-mcc/vue3-chartjs'

// import { Line, mixins } from '@j-t-mcc/vue3-chartjs'
// const { reactiveProp } = mixins
Vue3ChartJs.registerGlobalPlugins([zoomPlugin]);

export default {
  name: 'LineChart',
  components: {
    Vue3ChartJs,
  },
  props:{assetval:{type: Array,  default: () => []}, liabval:{type: Array,  default: () => []}, wealth:{type: Array,  default: () => []}, datalabels:{type: Array,  default: () => []} },
   watch: {
    assetval (to, from) {
      this.setup()
    }
  }, 
  setup(props) {
    const lineChart = {
      type: "line",
      // locally registered and available for this chart
      plugins: ["dataLabels"],
      data: {
       // labels: [0,1,2,3,4,5,6,7,8,9,10],
        
        datasets: [
          {
            label: "Assets",
            data: props.assetval,
            fill: false,
            borderColor: "#41B883",
            //backgroundColor: "black",
          },
          {
            label: "Liabilities",
            data: props.liabval,
            fill: false,
            borderColor: "#00D8FF",
           tension: 0.5,
          //  backgroundColor: "blue",
          },
           {
            label: "wealth",
            data: props.wealth,
            fill: false,
            borderColor: "black",
            tension: 0.5,
          //  backgroundColor: "green",
          },
        ],
      },
      options: {
        plugins: {
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "y",
            },
          },
          
          datalabels: {
          //  backgroundColor: function (context) {
          //    return context.dataset.backgroundColor;
          //  },
            borderRadius: 4,
            color: "white",
            font: {
              weight: "bold",
            },
            formatter: Math.round,
            padding: 6,
          },
        },
      },
    };
    
    return {
      lineChart,
    };
  },
};
</script>


<style>
div .assetslist {

}

.div_lineChart{
  

}
</style>


