<template>
  <div class="login">
    <div class="logincontent">
      <span class="title"> Forgot login ?</span>

      <form @submit.prevent="submit" class="formlogin1">
        <div class="rowline">
          <label for="username" class="rowlinelabel">Username:</label>
          <input type="text" name="username" v-model="form.username" placeholder="Email" required/>
        </div>
       
        <button type="submit">Reset password</button>
      </form>
     
     
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Forgotlogin",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      try {
          await this.LogIn(User);
          this.$router.push("/posts");
          this.showError = false
      } catch (error) {
        this.showError = true
      }
    },
  },
};
</script>

<style>


.login{
  display: flex;
  justify-content: center;
  padding: 60px 0;



}

.logincontent {
        display: flex;  
        width: 300px;
        padding: 40px;
        justify-content:center;
        flex-direction: column;
         border: 1px solid rgb(216, 216, 216);
         border-radius: 5px;
       
            
      }

.title{
            font-size: calc(1.2rem + 1.1vw);
            font-family: sans-serif;
            margin: 10px 0 20px 0;
            
}

.formlogin1{
margin:0px;
padding: 0px;;

}

.rowlinelabel {
  padding: 0;
  margin:0;
  
}

button[type="submit"] {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 10px 00px;
  width: 100%;
}
button[type="submit"]:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}
.rowline input {
  margin: 0 0 20px 0;
 /* box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06); */
  padding: 5px 5px;
 
   border: 1px solid rgb(216, 216, 216);
         border-radius: 0.5px;
 
}
#error {
  color: red;
}
</style>