<template>

   <!--     
   <div class="div2input">


  

    <input class="inputparam" type="text" id="fname" name="fname"  :value=value  @change="updateValue" >

  <p> {{symbol_left}}</p>
  <input class="inputparam" type="text" id="fname" name="fname"  :value=value   >
   <p> {{symbol_right}}</p>
 --> 


<div class="flexinput">
      <span class="input_left">{{symbol_left}}</span>
     <!--   
      <input class="inputcurrency" id="amount" name="amount" v-model=displayvalue  @blur="handleBlur"  @keyup="handlekeyup" maxlength="15" @change="updateValue" >
  --> 
      <input class="inputcurrency" id="amount" name="amount" v-model=displayvalue  maxlength="15" @change="updateValue" >
 
      <span class="input_right">{{symbol_right}}</span>
    </div>



  


</template>

<script>
import { ref } from 'vue'
export default {
 name: 'Inputpercentage',
//  props:{paramname:{type:String, default: "cash"}, paramvalue:{type: String, default: "0.00"} },


     props: {
       value: {type:String, default: "0.00"}, 
       symbol_left: {type:String, default: ""}, 
       symbol_right: {type:String, default: "%"}, 
        },

/*
       setup(props) {
       const displayvalue = props.value;
         return displayvalue ;
        },
      */

     data() {
       return {calculatedvalue:"", displayvalue:""}
     },
       
      computed : {
     //  const displayvalue : function() {return this.symbol_left+this.value+ this.symbol_right},      
        
      //displayvalue() {return this.value },      
       


    handleBlur () {
               this.displayvalue=this.commaSeparateNumber(this.displayvalue);
                ///   this.displayvalue="xxx"
  //  this.displayvalue=this.commaSeparateNumber("23423452453");
              
            },

            handlekeyup () {
                this.displayvalue=this.commaSeparateNumber(this.displayvalue);

            //       this.displayvalue=this.commaSeparateNumber("23423452453");
            }


       },
       
   
     methods : {
      updateValue (event) {
        this.displayvalue=parseFloat(this.displayvalue).toFixed(2);
        this.$emit('returnevent', this.displayvalue/100 ) ;
    },

  
     commaSeparateNumber(val){
         
         
      //   const valueString = val.replace(/\D/g, "");
        var amount=parseFloat(val).toFixed(2);
       // var amount=1087800.22;
        // return valueString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      //    var formattedString= amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
         // return amount.toString();
         return amount;
     //return formattedString;

      },
  
     
   


         },
           created() {
    
      this.displayvalue = this.value
     },

     
}

 
</script>

<style scoped>

.flexinput {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ccc;
  border-right: 0;
  line-height: 2.5;
 
 
}

.flexinput .inputcurrency {
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
  background: none;
  height: 45px;
   max-width: 300px;
  flex: 1 1 300px;
  border: none;
}


.flexinput .inputcurrency :focus {
  outline: 0;
}
.flexinput .inputcurrency .error {
  border-color: #ff0000;  
}



.flexinput .input_left {
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
   background: none;
}

.flexinput .input_right {
  font-size: 17px;
  padding: 0 20px 0 10px;
  color: #999;
  background: none;
}


</style>