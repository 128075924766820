<template>
 <div class="assetslist">
   <div class="topsection">

       <div class="div1">
         <p class="p_labelcat" > {{balancesheetsection}} : </p>
       </div>
          <div class="divlabelammount"> 
               <p style="font-size: 1em; colour: green; font-weight: bold; flex:1; margin:10px 10px 2px 10px; text-align: right;" > {{formatPrice(totalAL[balancesheetsection])}} </p>
         </div> 
      <div class="div3">    
         <div class="dropdownA"  @mouseover="hoverOverI" @mouseleave="hoverOutI" >

    


           <router-link :to="{name: 'AddNewAsset', params: { Type:defaultasset, BSCat:balancesheetsection}}">
           <i class="fas fa-plus"  ></i>
          </router-link>
         
     <!--       <Dropdown2/>    -->

       <!--  
           <transition name="tdropdown">  
             <div class="dropdownA-content" v-if="show" >
                 
                     <a href="javascript:showmod = true;" @click="showmod = true">Add new category</a>
                     <transition name="fademodal">  
                     <modal v-if="showmod" @close="showmod = false; show = false">

                        <template v-slot:header> 
                            <h3 > Custom header </h3>
                          </template>

                          <template v-slot:body> 
                            <h3 > Custom header </h3>
                              <Addassetcategory  style="flex:1"  />
                          </template>
                      
               

                    </modal>
               
                      </transition>
              <a href="#">Show details</a>    
               
                <a href="#">Clear all</a>
            </div>
             </transition>

            -->
           </div>
         </div>
   </div>
   <div class="bottomsection">
     <ul id="example-1">
      <!--   <li v-for="(asset,name) in assetcatz" :key="name">  -->

        <li v-for="asset in assetcatsum" :key="asset.Type">
            <!-- 
            <router-link   :to="{name: 'Catdetails', params: { props: {ID:asset.Type, Currencyformat:currency}} } "  style="text-decoration: none; color: inherit;">
          </router-link> 
        
            <router-link   :to="{name: 'Catdetails', props:{ props: {ID:'Cash', Currencyformat: {left:'L', right:'R'}  } }}"  style="text-decoration: none; color: inherit">
            <div class="listofassets" >

                <div class="listofassets" @click="clicklink()">
       
          <router-link   :to="{name: 'Catdetails', props:{ props: {ID:'Cash', Currencyformat: {left:'L', right:'R'}  } }}"  style="text-decoration: none; color: inherit">
          
          
          
            <router-link   :to="{name: 'Catdetails', params:{ ID:asset.Type, curr_left:currency.left, curr_right:currency.right} }"  style="text-decoration: none; color: inherit">
          
             
              <router-link   :to="{name: 'Catdetails', params:{ ID:asset.Type} }"  style="text-decoration: none; color: inherit">
             -->  
           
              <div class="listofassets" >
                        <!--   <p> This is my Asset category {{asset.id}}</p> 
                        :ID="asset.Type"   -->              
                <CatItem   :assettype="asset.Type" :BSCat=balancesheetsection link="Catdetails" :assetval="asset.Value" :curr_left="currency.left"  :curr_right="currency.right" style="flex:1" />  

              </div> 
              
              <!--    
            </router-link> 

                  
              <AssetComponent :allparams=asset :assetid=asset.id  />  
                <input type="text" v-model="newinfo"  >
              <button @click="addTodo(newtodo)">  Add</button>  
                <p>
                  <label >   Value : {{newinfo}} </label>   
                </p>-->
        
       </li>
     </ul>
     </div>
 </div>

</template>

<script>
import { ref } from 'vue'
import CatItem from './CatItem.vue'
import {createNamespacedHelpers} from 'vuex'
import Dropdown2 from './Dropdown2.vue'
import Addassetcategory from './AddAssetCategory.vue'
import Modal from "../comps/Modal.vue"
// import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets');


export default {
 name: 'AssetsList',
 props:{balancesheetsection:{type: String, default: 'Assets'}, currency:{type: Object,  default: () => ({}),} },
 components: {CatItem,Dropdown2, Addassetcategory, Modal },
   data() { 

    return {newinfo:'hello', show: false, showmod: false, assetcatz:{},assetcatsum:{}, dafultasset:"Cash"
           };

           
          } ,

   setup(){
        const items = ref([ 
           {note: 'alfa'}, {note:'bete'}, {note:'zeta'}, {note:'gogo'}]);
        return {
            items
      };
        
        },
   



    computed : {
      //  ...mapGetters(['info']),
   //   ...mapState('info'])
     //    ...mapState({   assets: state=>state.assets , assetcats: state=>state.assetcat,liabcats: state=>state.liabcat,assetslist: state=>state.assetslist1,liablist: state=>state.liablist}),
     
      ...mapState({   assets: 'assets' , assetcats: 'assetcat', liabcats: 'liabcat', assetslist: 'assetslist1',liablist: 'liablist'}),
     
     ...mapGetters(['totalAL','totalassetcats'])
   // mapState({     info: state => state.info  })
  //   ...mapState({    info: state => state.todos.info , todos : state =>state.todos.todos  }),
      
  

  //   ...mapState({info:"todos/info" , todos : "todos/todos" }),
       
  //   newtodo: () => { id: 4, title; newinfo; },
 
 


   //  onExecute:() => {this.addtodo(this.newtodo)}
      // newtodo() {  return{id: (length(this.todos)+1), title : this.newinfo}} 
     },


     created() {
     if (this.balancesheetsection=='Assets') {
     this.assetcatz=this.assetcats;
      this.assetcatsum = Object.assign({}, this.totalassetcats("A"));
      this.defaultasset="Cash"
   } else {
     this.assetcatz=this.liabcats;
      this.assetcatsum = Object.assign({}, this.totalassetcats("L"));
      this.defaultasset="Debt"
   };
      
    //  this.assetcatsum = [{Type:'Cash', Value:100}]
      
    },

     methods : {

       
      //   ...mapActions( "todos", ["addTodoAction"]),

//         ...mapActions(  ["todos/addTodoAction"]),
  /*
  ...mapActions(  ["addTodoAction"]),
         addTodo: function(todo) {
          this.addTodoAction(todo);
 
      // this.$store.dispatch("addTodoAction", todo)
          //  this.$store.commit('addTodo', todo)
        

        //this.newinfo=todo.title;
          this.newinfo="tt";
       } 
       */
      hoverOverI: function() {     
        this.show = true
       
        },

      hoverOutI: function() {
        this.show = false
         },

        formatPrice(value) {
                var   val= this.currency.left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currency.right;              
              return val;
          },


         clicklink(){
          this.$router.push({
                        name: 'Catdetails',
                        props: {
                      //    propsi: {ID:"Cash" , Currencyformat:{left:"L", right : "R"}}
                      propsi: {ID:"Cash"}
                        },
                        });
         }

 
         }
     
    
}

 
</script>

<style scoped>
div .assetslist {
display:flex;
flex-direction: column;
width: 420px;
margin:10px auto;
background: white;
text-align: left;
padding: 0px;
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
}

div .listofassets {
display:flex;
flex-direction: column;
width:100%;

}




label{
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
input {
display: block;
padding: 10px 6px;
width: 100%;
box-sizing: border-box;
border: none;
border-bottom: 1px solid #ddd;
color: #5555;
}

div .topsection {
display:flex;
/* justify-content: flex-end; 
*/
height: 45px;

border-bottom: 1px solid rgb(148, 144, 144);
padding: 1px 0px 2px 0px;
background-color: rgba(198, 194, 239, 0.111);
}







div .div1 {
display:flex;
flex: 3;
align-items: center; 
justify-content: flex-start;

}


.p_labelcat{
text-align: left;
font-size: 1em; colour: rgb(180, 15, 15); font-weight:400; flex:1; 
margin: 0px 0 2px 10px;

}



.divlabelammount p{
text-align:right;
margin-right: 10px;
}



div .div2 {
display:flex;
flex: 3;
align-content: flex-end;
justify-content: right;
}

div .div3 {
display:flex;
flex: 1;
align-content: flex-end;
justify-content: right;


}


div .bottomsection {
display:flex;
align-items: flex-start;
border: 1px solid rgb(243, 237, 237);
}

div .component {
display:flex;
flex-direction:row;
right: 1px;
top: 10px;
width: 40px;
height: 100px;

cursor: pointer;
}

.fas.fa-plus{
  
     margin : 10px 10px 10px 10px !important ;
    font-size: 1.30em !important ;
    color: #0d6efd;;
}


.dropdownA {
  position: relative;
/*  display: inline-block;  */
 display: inline-block;
  margin-right: 3px;
}

.dropdownA-content {
 display:block;  
  position: absolute;
  background-color: rgb(253, 250, 250);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  right:0; 
  transform-origin: top ;
  overflow: hidden;
  z-index: 10;
}
/* 
  transform-origin: translateX(-80%);
 transform: translateX(-80%);
  position: translate(-80%);  
 
  transform-origin:top ;
*/
 /* transform: translateX(-80%);
    transition: transform .4s ease-in-out;
    overflow: hidden;
 /* transform: scaleY(0);*/
   

  /*  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
*/
/*
 transition-timing-function: linear;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  */

/*  

  transform-origin: top;
margin-block: 0 100px 0 0  ; */


.dropdownA-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownA-content a:hover {background-color: #ddd;}

.dropdownA:hover .dropdownA-content {display: block;}

.dropdownA:hover .dropbtn {background-color: #3e8e41;}


ul{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  width:100%;
}


.tdropdown-enter-from,
.tdropdown-leave-to 
{
    transform: scaleY(0);
    opacity: 0;
}



.tdropdown-enter-active, .tdropdown-leave-active {
transition: all .4s ease-in-out;
}




/*


transition: transform all .4s ease-in-out;

.tdropdown-enter,
.tdropdown-leave-to {
  
    opacity: 0;
   
}
 
.tdropdown-enter-to,
.tdropdown-leave {
 
   opacity: 1; 

}
 
.tdropdown-enter-active,
.tdropdown-leave-active {
  transition: all .1s ease-out;
  
  
  

 
*/
 
  /*  cubic-bezier(0, 1, 0.5, 1);
   transition: all 0.5s  ease-out;
   transition: opacity 0.5s transform ease-out;
  transform-origin: top center;  
}*/

div .divlabelammount {
  display:flex;
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
 
  height: 40px;
  max-width: 300px;
  border: none;
  align-self: right;
  flex: 3;
  align-content: flex-end;
  justify-content: right;

}

</style>