<template>
 <div class="inputline">

   <div class="div1label">
         <p style="font-size: 1em; colour: green; font-weight: bold; margin:10px 0 2px 10px;" > {{paramname}} : </p>   
   </div>  
        
   <div class="div2input">
    <input class="inputparam" type="text" id="fname" name="fname"  :value="paramvalue"  @change="updateValue" >
     
 
<!--  
      @input="$emit('newfooevent', 'redd')"
   @input="$emit('newfooevent', $event.target.value)" -->

    <br>      
    </div> 
  

 </div>

</template>

<script>
import { ref } from 'vue'
export default {
 name: 'Inputparams',
//  props:{paramname:{type:String, default: "cash"}, paramvalue:{type: String, default: "0.00"} },

   data() { 
     return { 
           }
       },
     props: {
       paramname: "", 
       paramvalue: "",
       paramformat:""

        },
     methods : {
      updateValue (event) {
      //  if (this.paramformat!=="T" && value!=null){this.$emit('newfooevent', parseFloat(value).toFixed(this.paramformat))}
    // return "AA";
    // if (this.paramformat!=="T" && this.paramvalue!=null) {tvalue= parseFloat(this.paramvalue).toFixed(this.paramformat)} else if (this.paramvalue!=null) {tvalue = this.paramvalue} ;
     this.$emit('newfooevent', event.target.value ) ;
    }
         }
     
}

 
</script>

<style>
div .inputline{
display:flex;
justify-content: space-between;
align-items: flex-start;
width:100%;

margin:10px 0 10px 0 ;
background: white;
padding: 0px;
border-radius: 3px;
border: 1px solid blue;
}

div .div1label {
display:flex;
flex:1;
}

div .div2input {
display:flex;
width: 200px;

}

input.inputparam {
font-size: 1em; 
colour: green; 
max-width:100% ; 
font-weight: bold;  
margin:10px 0 2px 10px;
}






</style>