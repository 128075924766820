//import Vue from "vue";

import { createWebHistory, createRouter } from "vue-router";

//import VueRouter from "vue-router";
import store from "../store";
import Home from "../views/Home";
import Register from "../views/Register";
import Login from "../views/Login";
import Forgotlogin from "../views/Forgotlogin";
import Userdetails from "../views/Userdetails";

import Posts from "../views/Posts";
import Portfolio from "../components/Portfolio";
import Portfolioview from "../components/Portfolio/Portfolioview";
import Catdetails from "../components/Portfolio/Catdetails";
import Assetparams from "../components/Portfolio/Assetparams";
import Assetparameters from "../components/Portfolio/views/Assetparameters";
import AddNewAsset from "../components/Portfolio/views/AddNewAsset";
import Regionalprefs from "../components/Portfolio/views/Regionalprefs";
import BSprojection from "../components/Portfolio/views/BSprojection";
import Summaryreport from "../components/Portfolio/Summaryreport";
import Lifeexpectancy from "../views/Lifeexpectancy";
import Lifeinsurance from "../views/Lifeinsurance";
import Insurers from "../views/Insurers";
import Annuity from "../views/Annuity";
import Loan from "../views/Loan";

//Vue.use(VueRouter);

const routes = [

  {
    path: "/",
    redirect: "/Home",
    name: "Home",
    component: Home,
    meta: {
        title:'Home'
    }
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
    meta: {
        title:'Home'
    }
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { guest: true },
  },

  {
    path: "/forgotlogin",
    name: "Forgotlogin",
    component: Forgotlogin,
    meta: { guest: true },
  },
  {
    path: "/userdetails",
    name: "Userdetails",
    component: Userdetails,
    meta: { guest: true },
  },



  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/posts",
    name: "Posts",
    component: Posts,
    meta: { requiresAuth: true },
  },


  {
    path: "/Lifeexpectancy",
    name: "Lifeexpectancy",
    component: Lifeexpectancy,
    meta: { guest: true },
  },

  {
    path: "/Lifeinsurance",
    name: "Lifeinsurance",
    component: Lifeinsurance,
    meta: { guest: true },
  },
  {
    path: "/Annuity",
    name: "Annuity",
    component: Annuity,
    meta: { guest: true },
  },
  {
    path: "/Loan",
    name: "Loan",
    component: Loan,
    meta: { guest: true },
  },

  {
    path: "/Insurers",
    name: "Insurers",
    component: Insurers,
    meta: { guest: true },
  },

  {path: "/Portfolio/views/Regionalprefs",
name: "Regionalprefs",
component: Regionalprefs,
props: true,
meta: { title:'Regional preferences' }
},


  {path: "/Portfolio",
  name: "Portfolio",
  component: Portfolio,
  meta: { title:'Portfolio' }
},

{path: "/Portfolio/Portfolioview",
name: "Portfolioview",
component: Portfolioview,
meta: { title:'Portfolio View' }
},


{path: "/Portfolio/Summaryreport",
name: "Summaryreport",
component: Summaryreport,
meta: { title:'Summary report' }
},
/*
{path: "/Portfolio/Catdetails/:props",
name: "Catdetails",
component: Catdetails,
props: (route) => ({
  props: {ID:"Equity" , Currencyformat:{left:"L", right : "right"}},
  ...route.params
}),
meta: { title:'Cat details' }
},

*/
{path: "/Portfolio/Catdetails/:ID/:BSCat",
name: "Catdetails",
component: Catdetails,
params: {
  // props: {ID:"Equity" , Currencyformat:{left:"L", right : "right"}}
   
// props: alfa => ({ props: alfa })
},
props: true,

meta: { title:'Cat details' }
},


{path: "/Portfolio/views/Assetparameters/:ID/:BSCat",
name: "Assetparameters",
component: Assetparameters,
props: true,
params:{
  
},
props: true,
meta: { title:'Asset parameters' }
},

{path: "/Portfolio/Assetparams/:ID",
name: "Assetparams",
component: Assetparams,
props: true,
/*
props: (route) => {
  const ID = Number.parseInt(route.params.ID, 10)
  if (Number.isNaN(userId)) {
    return 0
  }
  return { ID }
},
*/
meta: { title:'Asset parameters' }
},

{path: "/Portfolio/views/AddNewAsset/:Type/:BSCat",
name: "AddNewAsset",
component: AddNewAsset,
props: true,
meta: { title:'Add New Asset' }
},


{path: "/Portfolio/views/BSprojection",
name: "BSprojection",
component: BSprojection,
meta: { title:'Projection of Wealth' }
},


];


/*
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
*/

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/posts");
      return;
    }
    next();
  } else {
    next();
  }
});



//  createApp(SimpleRouter).mount('#app')

export default router;