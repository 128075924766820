<template>
 <div class="assetslist">
    <p> cat details </p> 
    <p> {{assetparameters.Type }}</p> 
     <router-link :to="{ name: 'Portfolioview' }">Portfolio</router-link> |
   
    <div class="bottomsection">
     <ul id="example-1">
         <li v-for="(value,name) in assetparameters" :key="name" > 
       

          <div v-if="defaultassetparameters[name]" class="listofassets">
          <Inputparams  :paramname="name"  :paramvalue="assetparameters[name]" :paramformat="paramformat[name]"  style="flex:1"  @newfooevent="assetparameters[name] = updateformat($event,paramformat[name])" />  
          </div>
          
       </li>
     </ul>

     </div>
     <div class="bottom2">
         <div class="bottom2left">
           
           <button @click="$router.go(-1)">Cancel</button>

         </div>

         <div class="bottom2center">
           
           <button @click="$router.go(-1)">Delete Asset</button>

         </div>

         <div class="bottom2right">
             <button class="modal-default-button" @click="UPDATE_ASSET(assetparameters); $router.go(-1)">
               Save
                  </button>
         </div>
        
     </div>



 </div>

</template>

<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'
import Dropdown2 from './Dropdown2.vue'
import Modal from "../comps/Modal.vue"
import Inputparams from './Inputparams.vue'

const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets');


export default {
 name: 'Assetparams',
 props:{ID:{type: Number}},
 components: { Inputparams },
   data() { 
    return {assetparameters:{}, defaultassetparameters:{} 
           } 
          } ,

 
    computed : {
         //  ...mapState({paramformats: state=>state.paramformat}),
         ...mapState(['paramformat']),

          ...mapGetters(['assetparams','defaultassetparams'])
  
       },
     methods : {
      ...mapMutations(['UPDATE_ASSET']),

      updateformat (value, format) {
    //    if (format!=="T" && value!=null){this.$emit('newfooevent', parseFloat(value).toFixed(this.paramformat))}
    // return "AA";
     var tvalue;
     if (format!=="T" && value!=null) {tvalue= parseFloat(parseFloat(value).toFixed(format))} else if (value!=null) {tvalue = value} ;
     return tvalue;
    }

     },

     created() {
    
      this.assetparameters = Object.assign({}, this.assetparams(parseInt(this.ID))),
      this.defaultassetparameters = Object.assign({}, this.defaultassetparams(this.assetparameters.Type))

      
     },

     
    
}

 
</script>

<style scoped>
div .assetslist {
display:flex;
flex-direction: column;
max-width: 420px;
margin:10px auto;
background: white;
text-align: left;
padding: 0px;
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
}

div .bottom2 {
display :flex;
justify-content:space-between;

 align-items: center;
}

div .bottom2left {
display :flex;
width: 100px;
align-self:flex-start;
 margin: 5px 3px 5px 10px;
 justify-content:flex-start;

}
div .bottom2right {
display :flex;
width: 100px;
align-self: flex-end;
margin: 5px 10px 5px 3px;
justify-content: flex-end;

}

div .bottom2center {
display :flex;
width: 100px;
align-self: center;
 margin: 5px 3px 5px 10px;
 justify-content: center;

}

</style>