<template>
  <div class="login">
    <div class="logincontent">
      <span class="titlesetting"> Preferences </span>

      <form @submit.prevent="" class="formlogin1">
     
         <div class="rowline">
          <label for="Forename" class="rowlinelabel">Forename:</label>
          <input type="text" name="forename" v-model="currentuser.Firstname" placeholder="Forename" required/>
        </div>
         <div class="rowline">
          <label for="surname" class="rowlinelabel">Surname:</label>
          <input type="text" name="surname" v-model="currentuser.Surname" placeholder="Surname" required/>
        </div>
              <div class="rowline">
          <label for="country" class="rowlinelabel">Country:</label>
           <select class="lecountryselect" name="country" v-model="currentuser.Country" placeholder="Country" id="l_e_country"   >
                    <option selected="" disabled="" value="">--Select Country--</option>


           <!--   
              <input type="text" name="country" v-model="currentuser.Country" placeholder="Country" required/>
       
            <div  v-for="acountry in countries" :key="acountry.code">  
          </div> 
           -->
                    <option  v-for="acountry in countries" :key=acountry.code :value=acountry.code> {{acountry.name}}</option>
             
                </select>
     
        </div>
              <div class="rowline">
          <label for="city" class="rowlinelabel">City:</label>
          <input type="text" name="city" v-model="currentuser.City" placeholder="City" required/>
        </div>
              <div class="rowline">
          <label for="gender" class="rowlinelabel">Gender:</label>
              <select class="selectuserp" v-model="currentuser.Gender">
              <option value="M">Male</option>
              <option value="F">Female</option>        
              </select>
           </div>
             <div class="rowline">
          <label for="smoker" class="rowlinelabel">Smoker status:</label>
              <select id="smoker" name="smoker" class="selectuserp" v-model="currentuser.Smoker">
              <option value="NS">Non Smoker</option>   
              <option value="S">Smoker</option>     
              </select>

          </div>
         <div class="rowline">
          <label for="birtdate" class="rowlinelabel">Date of birth:</label>
           <input type="date"  format="DD/MM/YYYY"  name="birthdate" v-model="currentuser.Birthdate"      />
        </div>

        <div class="rowline">
          <label for="retirementage" class="rowlinelabel">Planned Retirement Age</label>
           <input type="number"  name="retirementage" v-model="currentuser.RetirementAge"      />
        </div>




         <button type="submit" @click="updateuserinfo">Validate</button>
        </form>


        <form @submit.prevent="submit" class="formlogin1">
        <br>
        <br>
        <br>
         <h5>Change password</h5>
         <div class="rowline">
          <label for="password" class="rowlinelabel">Current password:</label>
          <input type="password" name="password" v-model="form.password" placeholder="Password" required />
        </div>
        
         <div class="rowline">
          <label for="password" class="rowlinelabel">New password:</label>
          <input type="password" name="password" v-model="form.password" placeholder="Password" required />
        </div>
           <div class="rowline">
          <label for="password" class="rowlinelabel">Repeat new password:</label>
          <input type="password" name="conrfirmpassword" v-model="form.confirmpassword" placeholder="Confirm Password" required />
        </div>
        <button type="submit">Change Password</button>
      </form>
      <p v-if="showError" id="error">Username or Password is incorrect</p>
    </div>
  </div>
</template>

<script>


import {createNamespacedHelpers} from 'vuex';


const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('auth');
const parameters = createNamespacedHelpers('parameters');

export default {
  name: "Userdetails",
  components: {},
  data() {
    return {
      currentuser:"",
      form: {
        username: "",
        password: "", country:"", gender:"M", smoker:"NS",
        surname:"",forename:"",city:"",
        birthdate:"2000-01-01", retirementAge:"65"
      },
      showError: false,
  }},



 computed: {
   ...mapState(['user']),
   ...parameters.mapState(['countries']),
   },

  methods: {    
    ...mapMutations(["LogIn"]),
    ...mapActions(["Updateuser"]),
    
    async submit() {
      const User = new FormData();
      User.append("username", this.form.username);
      User.append("password", this.form.password);
      try {
          await this.LogIn(User);
          this.$router.push("/posts");
          this.showError = false
      } catch (error) {
        this.showError = true
      }
    },

    updateuserinfo() {
     //  console.log(this.user);
     // console.log(this.currentuser);
    
     const User2 = {submit: true , user: this.currentuser};
     //  console.log(User2.user.Email);
     this.Updateuser(User2);
     //  console.log(this.currentuser);
     
    },

  },
  created() {
    
      this.currentuser = Object.assign({}, this.user)
  },
};
</script>

<style scoped >


.login{
  display: flex;
  justify-content: center;
  padding: 60px 0;



}

.logincontent {
        display: flex;  
        width: 300px;
        padding: 40px;
        justify-content:center;
        flex-direction: column;
         border: 1px solid rgb(216, 216, 216);
         border-radius: 5px;
       
            
      }

.titlesetting{
             font-size: calc(1.075rem + 1.3vw);
            font-family: sans-serif;
            margin: 0px 0 20px 0;
            
}

.formlogin1{
margin:0px;
padding: 0px;;

}

.rowlinelabel {
  padding: 0;
  margin:0;
  
}

.lecountryselect{
width: 100%;
height:36px;
margin: 0 0 20px 0;
}


button[type="submit"] {
  background-color: #0d6efd;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 20px 00px 0px 00px;
  width: 100%;
}
button[type="submit"]:hover {
  background-color: #285fb3;
   transition: 0.5s ease all;
}


div.rowline input, select, textarea, .selectuserp {
      color: black;
      }
div.rowline input, .selectuserp { 
  
 
  width: 100%;
 /* box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06); */
  padding: 5px 5px;
 
   border: 1px solid rgb(216, 216, 216);
         border-radius: 0.5px;
         color:black;
}

div.rowline input::placeholder{
          color: rgb(165, 163, 163);
      } 

#error {
  color: red;
}
</style>