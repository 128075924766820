<template>

    <div class="mainframecomponent">
  
      <div class="divi1and2"> 
                     <div class="divi1" >
                          <img :src="Logo" alt="Logo" style="max-width:70px;height:30px;">
                        
                      </div>

                      <div class="divi2" >
                          <p class="p_name">{{Name}}</p>
                      </div>

      </div>

      
        
      
        
            <div class="divi3">
     
          <star-rating v-model:rating="Averagerating" :key="componentKey" :round-start-rating="false" :star-size="20" :read-only="true"></star-rating>
      
     <!--
             star-rating v-model:rating="submitrating" active-color="rgb(98, 201, 98)" :key="componentKey" :round-start-rating="false" :star-size="20" @update:rating="setRating(), resetAverage()" ></star-rating>
   -->
            </div>

               <div class="divi4">
     
                  <star-rating v-model:rating="submitrating" active-color="rgb(98, 201, 98)" :key="componentKey" :round-start-rating="false" :star-size="20" @update:rating="submitRating" ></star-rating>
 
              </div>

        <!-- 
           <div class="divi1">
                <input class="inputcurrency" id="rating" name="rating" v-model=submitrating  maxlength="45"  >
            </div>

        -->
          
        

        </div>
    
    
    </template>
    
    <script>
    import { ref } from 'vue'


    import {createNamespacedHelpers} from 'vuex'
    import Rating from './rating.vue'

    import StarRating from 'vue-star-rating'

    const insurers = createNamespacedHelpers('insurers');


    export default {
     name: 'Insurancetemplate',
  
     
         props: {
           InsuranceID: {type:String, default: "I1"}, 
           Name: {type:String, default: "Alfa"}, 
           ParentName: {type:String, default: "AlfaP"}, 
           Satisfaction: {type:Number, default: 3}, 
           Averagerating: {type:Number, default: 3}, 
           Clientrating: {type:Number, default: 3}, 
           Logo: {type:String, default: "logo"}, 
        //   Editable: {type:Boolean, default: false}, 

           //RatingAverage=insurer.Averagerating :Clientrating=insurer.Clientrating
            },
            components: { Rating, StarRating},

      data() {
      return {
       temprating: 0,
       submitrating:0,
       rating:0,
       componentKey: 0,
        
      };
      },
      

    methods: {
       ...insurers.mapActions(['submitratingf']), 

       setRating(rating){
      //  console.log("rating :  " );

        //  console.log(rating);

          this.submitrating=rating;
       
         //   console.log(this.submitrating);
     //   console.log(this.Averagerating);
       // this.temprating=this.Averagerating;
        //this.rating=this.Averagerating;
     //   console.log(this.temprating);
      //  this.resetAverage();
        //rating=this.Averagerating;
      //   rating=this.Averagerating;
       // console.log(this.temprating);
         },
        
         resetAverage(){      
            this.temprating=this.Averagerating;        
            // Note : componentKey has been added here to update render the component with changed values. Otherwise the component shows values changed only 1st time.
            this.componentKey += 1;
            },
  
         // Submitrating will send the information to to the network
         // the information needed for that insurer =ID , rating + userdetail : from store + dateime in stor    
        submitRating(rating){      
            //   storesbumitrating(rating, InsuranceID) 
            console.log(rating);
            console.log ("Insurnace ID");
            console.log(this.InsuranceID);

               this.submitratingf ({Rating:rating, ID:this.InsuranceID})  ;

           
            }
  


            },

   created(){
         this.temprating=this.Averagerating;
         this.submitrating=this.Clientrating;
        
         
       }

           
        
    }
    
     
    </script>
    
    <style scoped>
    

    .mainframecomponent{
     width: 100%;
     display:flex;
     border: 1px solid #dee2e6!important;

  
     /* 
      flex-wrap: wrap;
     justify-content: space-evenly;
     */
  
    
     
    }

    .divi1and2{
      display:flex;
      flex:5;
      flex-wrap: wrap;

    }

    .divi3{
            display: flex;
            flex:2; 
           
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 10px;
            
    }

    .divi4{
            display: flex;
            flex:2; 
           
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 2px;
            

    }
    .divi2{
            display: flex;
            
            
            margin: 0 5px;
            
           /* 
           flex:4;
           width:200px;  */

    }
    .divi1{
            display: flex;
      
            
            margin: 0 5px;
            width:60px;
           /* width:200px;  */

    }

    .p_name {
        text-align: left;
        align-self: flex-end;
        font-size: 17px;
         margin:0 0 0 0;
        padding: 0 10px;

    }

    label {
       margin:0;
        height:30px;
        text-align: left;
    }


    .flexinput {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border-bottom: 1px solid #ccc;
      
      border-right: 0;
      line-height: 2.5;
    
    }
    
    
    
    
    
    
    </style>