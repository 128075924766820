<template>
 <div class="assetcat">
   <div class="topsection">
         <p style="font-size: 1em; colour: green; font-weight: bold; flex:1; margin-left:10px;" > Add Asset categories </p>
       
  
   </div>
   <div class="bottomsection">
     <!--   <div class="checkboxline">
                       <label for="john">John</label>
                       <input type="checkbox" id="john" value="John" v-model="checkedNames" />
         </div>  

        <div class="checkboxline">
                       <label for="john">Paul</label>
                       <input type="checkbox" id="john" value="John" v-model="checkedNames" />
         </div> 
         -->

       <div>
             <p>This is the section on asset categories list</p>
              <ul id="example-1">
              <!-- 
              <li v-for="asset in assets" :key="asset.id">
                <div class="listofassets">
                <p> This is my Asset category {{asset.id}}</p>  -->
               <li v-for="(asset,name) in assetcats" :key="asset" >
                <div class="listofassets" v-if="asset==false">
                <p>  {{name}} : {{asset}}</p>

                 <input :id="name" :value="asset" name="product" type="checkbox"  v-model="assetcat2[name]"/>
               <!--       <input type="checkbox" id="asset" value="John" v-model={{asset}} />

                 <AssetItem  assetval="100"  liabval="90"  netassets="10" style="flex:1" />  
                  -->
                 
                

                </div>  
              </li>
            </ul>
             <!-- <button class="modal-default-button"  @click="message('H ')">>
               -->

              <button class="modal-default-button"  @click="updateassetcat()">
                OK
                  </button>
         </div> 
   
     </div>

       <div class="lowsection">
        
        

          <i class="fas fa-reply"  style=" justify-self: flex-start"></i>
           <i class="fas fa-check-square" style="justify-self: flex-end; margin-left: auto;"></i>
 <!--         <Dropdown2/> 
 -->
       </div>
    
 </div>

</template>

<script>
import { ref } from 'vue'
import {createNamespacedHelpers} from 'vuex'

 import Dropdown2 from './Dropdown2.vue'


// import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
const { mapGetters, mapState, mapMutations, mapActions } = createNamespacedHelpers('assets','assetcat','liabcat');



export default {
 name: 'Addassetcategory',
 // props:{balancesheetsection:{type: String, default: 'bonjour'}},


components: {Dropdown2},


props: {},
 

 
 data() { 

    return {newinfo:'hello',
     assetcat2 : {Cash:false, Equity:false, Bond:false,Property:false,Fund:false}
     // assetcat2 : {}
          } 
 },


mounted() {
//   this.assetcat2=this.assetcats;
  },

   setup(){
        const items = ref([ 
           {note: 'alfa'}, {note:'bete'}, {note:'zeta'}, {note:'gogo'}]);

        return {
            items
        };
        
        },
    computed : {
        ...mapState({   assets: state=>state.assetslist, assetcats: state=>state.assetcat,liabcats: state=>state.liabcat }),
           ...mapGetters(['totalassets','totaliabilities','totalliabcats','totalassetats'])
     },

        created() {
      this.assetcat2= Object.assign({}, this.assetcats);
    },
     methods : {
      //  ...mapMutations(  ["assets/changeAssetCat"]),
       //    ...mapMutations([ 'changeAssetCat',  this.assetcat2 ]),
 
     
     

        message: function(text) {
   
          //this['assets/changeAssetCat'](assetcat2);
          console.log(text + JSON.stringify(this.assetcat2));
 /*
          this.$store.commit('assets/changeAssetCat',
   this.assetcat2)
*/
          // commit.assets.changeAssetCat(this.assetcat2);

         },

        updateassetcat: function() { 
        //  this.assetcat2[tname]=!this.assetcat2[tname];
          this.$store.commit('assets/changeAssetCat',
   this.assetcat2)
         }, 
         

        adjustassetcat: function(tname) { 
          this.assetcat2[tname]=!this.assetcat2[tname];
         } 
/*
          addassetcat: function(text) { 
         
          this.newassetcat{text}
          this.$store.commit('assets/changeAssetCat',
   this.newassetcat)

         } 
*/
         }
     
     
}

 
</script>

<style scoped>
div .assetcat {
display:flex;
flex-direction: column;
/* max-width: 420px; */
width: auto;
margin:10px auto;
background: white;
text-align: left;
padding: 0px;
border-radius: 3px;
border: 1px solid rgb(204, 204, 211);
}

div .listofassets {
display:flex;
flex-direction: column;
width:100%;


}


label{
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
}
input {
display: block;
padding: 10px 6px;
width: 100%;
box-sizing: border-box;
border: none;
border-bottom: 1px solid #ddd;
color: #5555;
}

div .topsection {
display:flex;
/* justify-content: flex-end; 
*/
justify-content: space-between;
height: 40px;

border: 1px solid rgb(204, 204, 211);

}

div .bottomsection {
display:flex;
flex-direction: column;
align-items: flex-start;
border: 1px solid rgb(204, 204, 211);
}

div .component {
display:flex;
flex-direction:row;
right: 1px;
top: 10px;
width: 40px;
height: 100px;

cursor: pointer;

}

.fas.fa-plus{
   
     margin : 10px 10px 10px 10px !important ;
    font-size: 1.30em !important ;
}

div .lowsection {
display:flex;
flex-direction: column;
justify-content: space-between;
height: 40px;

border: 1px solid rgb(204, 204, 211);

}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}


ul{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  width:100%;
}

.checkboxline {
    display: flex ;
   justify-content: flex-end;  
    align-items: baseline;
    width: 100%;
}
</style>