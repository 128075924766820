import { createApp } from 'vue'
import { createStore } from 'vuex'

import App from './App.vue'
import store from './store/'

import axios from 'axios'
import router from './router'

// import geoip-country from 'geoip-country'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'


import vClickOutside from "click-outside-vue3"




/* add icons to the library */
library.add(fas)


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

// the following is to use asyn function with wait
import "regenerator-runtime";
//import "vue-axios-cors";



import AOS from 'aos'
import 'aos/dist/aos.css'



axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://www.riskmet.com/'

 



//import "bootstrap"

//import 'bootstrap/dist/css/bootstrap.css'









//import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'


//import BootstrapVue from "bootstrap-vue"


axios.defaults.withCredentials = true


// the following might point to the right folder which contains login and register files




// The folllowing is for : Our API is set to expire tokens after 30 minutes, now if we try accessing the posts page after 30 minutes, we get a 401 error, which means we have to log in again, so we will set an interceptor that reads if we get a 401 error then it redirects us back to the login page.

/*
axios.interceptors.response.use(undefined, function (error) {
    if (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
    
          originalRequest._retry = true;
          store.dispatch('LogOut')
          return router.push('/login')
      }
    }
  })
*/

const app = createApp(App)


app.AOS = new AOS.init({duration: 1200,once: false, mirror: true});

app.use(store); 
app.use(router);

app.use(AOS);


app.use(vClickOutside);


//app.use(AxiosPlugin);



 //app.use(BootstrapVue);
// app.use(BootstrapVueIcons);






app.component('fa', FontAwesomeIcon);


app.mount('#app');



