<template>

<div class="flexinput">
     
      <input class="inputcurrency" id="amount" name="amount" v-model=value  maxlength="45" @change="updateValue" >
  
    </div>


</template>

<script>
import { ref } from 'vue'
export default {
 name: 'Inputtext',
//  props:{paramname:{type:String, default: "cash"}, paramvalue:{type: String, default: "0.00"} },


     props: {
       value: {type:String, default: "0.00"}, 
        },
       
    
     methods : {
      updateValue (event) {
       this.$emit('returnevent', event.target.value ) ;
    },

  
         },
       
     
}

 
</script>

<style scoped>

.flexinput {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ccc;
  
  border-right: 0;
  line-height: 2.5;

}

.flexinput .inputcurrency {
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;
  background: none;
  height: 45px;
   max-width: 300px;
  flex: 1 1 300px;
  border: none;
  
}


.flexinput .inputcurrency :focus {
  outline: 0;
}
.flexinput .inputcurrency .error {
  border-color: #ff0000;  
}






</style>