<template>
 <div class="assetslist">
   <div class="BSSummary_topsection">
   
    <div class="summaryline">
          <div class="divsummaryleft"> 
                 <p class="p_sumleft" > Wealth : </p>   
          </div>
          <div class="divlabelammount"> 
              <p class="p_amount" > {{formatPrice(netassets)}} </p>   
          </div>
    </div>
      <div class="chartdiv">
        <Piechart :assetval="Number(assetval.toFixed(2))"  :liabval="Number(liabval.toFixed(2))" />
      </div>
      <div class="summaryline">
          <div class="divsummaryleft"> 
                <p class="p_sumleft" > Assets :   </p>
         </div>
          <div class="divlabelammount"> 
              <p class="p_amount" > {{formatPrice(assetval)}} </p>   
          </div>
    </div>

      <div class="summaryline">
          <div class="divsummaryleft"> 
                 <p class="p_sumleft" > Liabilities :   </p>
          </div>
          <div class="divlabelammount"> 
              <p class="p_amount" > {{formatPrice(liabval)}} </p>   
          </div>
    </div>

         
   </div>

 </div>

</template>

<script>
import { ref } from 'vue'
import Piechart from './components/charts/Piechart.vue'


export default {
 name: 'BSSummary',
  components: {Piechart},
 props:{assetval:{type:Number, default: 0.00}, liabval:{type: Number, default: 0.00}, netassets: {type: Number, default: 0.00}, currency:{type: Object,  default: () => ({}),} },

   data() { 
      return {
           }
   },
     methods: {
       formatPrice(value) {
     //  let val = (parseFloat(value)/1).toFixed(2).replace('.', ',');
       // return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

         var   val= this.currency.left+" " + parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")+ " " + this.currency.right;
           
       //    var val= 9999;
           return val;
    
    }
     }

     
     
}

 
</script>

<style scoped>
div .assetslist {
display:flex;
flex-direction: column;
width: 420px;
margin:5px auto;
background: white;
text-align: left;
padding: 1px;
border-radius: 3px;
border: 1px solid rgb(188, 188, 192);
}


div .BSSummary_topsection {
display:flex;
/* justify-content: flex-end; 
*/
flex-direction: column;
padding: 10px;;
border: 1px solid rgb(243, 237, 237);
}

div .summaryline {
display:flex;
justify-content: space-between;
align-items: flex-start;
width:100%;

margin:2px 0 2px 0 ;
    background-color: rgba(198, 194, 239, 0.111);
padding: 0px;
border-radius: 3px;
border-bottom: 1px solid rgb(214, 214, 219);
}



div .chartdiv {
display:flex;
justify-content: center;
align-items:center;


margin:10px ;


}

div .divsummaryleft {
display:flex;
}



.p_sumleft{
font-size: 1em; colour: green; font-weight: 400; flex:1; margin:10px 0 2px 10px;
}

.p_amount{ 
  font-size: 0.9em; colour: rgb(73, 78, 73); font-weight: 500; flex:1; margin:10px 10px 2px 10px; text-align: right;
 }



div .divlabelammount {
  display:flex;
  font-size: 17px;
  padding: 0 10px 0 20px;
  color: #999;

  height: 45px;
  max-width: 300px;
  border: none;
  align-self: right;

}





p{
  margin: 10px;
}





</style>