import axios from 'axios';

    function defaultstate ()  { return  {
    insurerlist: [
        {
            ID:'1',
            Name:'AXA', 
            Parentname:"Cash",
            Country:"US",
            Averagerating:4,
            Clientrating:3.5
        }, 
        {
            ID:'2',
            Name:'Prudential', 
            Parentname:"Cash",
            Country:"US",
            Averagerating:4,
            Clientrating:4.3
     },
     {
        ID:'3',
        Name:'AVIVA', 
        Parentname:"Cash",
        Country:"GB",
        Averagerating:4,
        Clientrating:1.8
 },
 {
    ID:'4',
    Name:'CNP', 
    Parentname:"CNP",
    Country:"FR",
    Averagerating:4.1,
    Clientrating:1.8
},
{
    ID:'5',
    Name:'AXA', 
    Parentname:"Cash",
    Country:"FR",
    Averagerating:3.2,
    Clientrating:4.6
},
{
    ID:'6',
    Name:'Predica', 
    Parentname:"CA",
    Country:"FR",
    Averagerating:3.4,
    Clientrating:3.8
},
{
    ID:'7',
    Name:'ACM', 
    Parentname:"ACM",
    Country:"FR",
    Averagerating:2.5,
    Clientrating:2.6
},
      
    ],

    


  
 }
};




import auth from './auth'

const state =  defaultstate();

const getters = {

     allinsurers:  (state) => { return state.insurerlist }, 

     //corsthost : (state,getters, rootState,rootGetters) =>  rootState.auth.cors_host,
     webaddress1 : (state,getters, rootState,rootGetters) =>  rootState.auth.webaddress1,
        
     insurerspercountry: (state) => (ccountry) => {
        console.log(" state.insurerlist");
        console.log( state.insurerlist);
        console.log( ccountry);


      var newarray = state.insurerlist.filter(pilot => pilot.Country === ccountry)  ;  
      return newarray;
    },
    Userid : (state,getters, rootState,rootGetters) =>  rootState.auth.user,
    getuserid( rootGetters){
       return  rootGetters['auth/StateUser'];
    },

   

 };


 const mutations = {

    RESET_Defaultinsurers (state) {
     //   Object.assign(state.insurerlist, defaultstate().insurerlist)
    // state.insurerlist= defaultstate().insurerlist;
       state.insurerlist=Object.values(Object.assign({}, defaultstate().insurerlist));
      // state.insurerlist=Object.assign({}, defaultstate().insurerlist);

        },

    SET_listinsurers (state, insurers) {
           // Object.assign(state.insurerlist, insurers)
            state.insurerlist= Object.values(Object.assign({}, insurers));
           // state.insurerlist=Object.values(Object.assign({}, Object.values(insurers)));
            //Object.assign(state.insurerlist, Object.values(insurers))
    
            },
    

     UPDATE_insurer  (state,editedInsurer) {
                //   Object.assign(state.insurerlist, defaultstate().insurerlist)
               // state.insurerlist= defaultstate().insurerlist;

               const oldInsurer = state.insurerlist.find( insurer => insurer.ID === editedInsurer.ID );
               if (oldInsurer) {
                  // not creating a new object but modifying old object here
                  oldInsurer.Clientrating= editedInsurer.Rating;
                //  Object.assign(oldInsurer.Clientrating, editedInsurer.Rating);
               }
            //      state.insurerlist=Object.values(Object.assign({}, defaultstate().insurerlist));
                 // state.insurerlist=Object.assign({}, defaultstate().insurerlist);
           
                   },

       
};
  
  const actions = {
    resetinsurers: function ({commit, dispatch})  {
        commit('RESET_Defaultinsurers');
       },

       async Get_insurers ({commit,state, getters}, input) {
        //  state.assetslist1.push(newasset);
        // in the following line replace hard corde by informatino input - id and country
       //   const insurancedata = {submit: true , ClientID: "c@y.com", Country:input.Country };
          const insurancedata = {submit: true , ClientID: input.ClientID};
       //  await axios.post(API_URL +'assets/addasset', assetdata)

      // const hostid= getters.corsthost;    
      //   await axios.post('http://localhost:8080/one/insurers/loadinsurers.php', insurancedata).then((res) => {
      //    await axios.post('http://localhost:'+hostid+'/one/insurers/loadinsurers.php', insurancedata).then((res) => {
        await axios.post(getters.webaddress1+'insurers/loadinsurers.php', insurancedata).then((res) => {
         
         //   commit('SET_listinsurers', Object.values(res.data));
         
         commit('SET_listinsurers', res.data);

        /*
            console.log ("before loading insuerlist : "  ); 
            console.log ( state.insurerlist ); 
            console.log("SET_listinsuers: res.data: " ) ; 
            console.log(res.data);
            console.log(state.insurerlist);
         */
           

          //  return new Promise(resolve => "finished"   );



          })

          return "finished";
  
      },
    


      async submitratingf ({commit, getters, dispatch}, input) {
   
        
         const insurancedata = {submit: true , User: getters.Userid.Email, Rating:input.Rating, InsuranceID:input.ID}; 
         commit('UPDATE_insurer', {ID:input.ID, Rating:input.Rating});
       //  const hostid= getters.corsthost;    
     //    await axios.post('http://localhost:8082/one/insurers/updaterating.php', insurancedata).then((res) => {     
     //     await axios.post('http://localhost:'+hostid+'/one/insurers/updaterating.php', insurancedata).then((res) => {     
         await axios.post(getters.webaddress1+'insurers/updaterating.php', insurancedata).then((res) => {     
       
         //         commit('SET_listinsurers', res.data);
              console.log(input.ID);  
              console.log(res.data); 

          });


        dispatch('Get_insurers',{ClientID:getters.Userid.Email}); 
       

      },
    

   

   };
   
 
 
 export default {
     namespaced: true,
     state,
     getters,actions, mutations

     
 }
 